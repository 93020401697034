import React, { useState } from 'react'
import { FaArrowDown } from 'react-icons/fa'

import { Button } from '~/components/Form'

import {
    Features, Feature, Extra, DemoContainer, ImageContainer, TextContainer
} from './styles'

import divulgagacao from '~/assets/img/products/mensageria/divulgacao.png'
import busca from '~/assets/img/products/mensageria/busca.png'
import compartilhamento from '~/assets/img/products/mensageria/compartilhamento.png'
import confirmacao from '~/assets/img/products/mensageria/confirmacao.png'
import opiniao from '~/assets/img/products/mensageria/opiniao.png'
import smartphone from '~/assets/img/products/mensageria/celular3d.png'
import estatisticas from '~/assets/img/products/chatbot/estatisticas.png'

const categoryData = {
    features: [
        {
            image: divulgagacao,
            title: 'Divulgue rapidamente',
            description: 'Mantenha seus clientes sempre informados. Avisos ou pesquisas rápidas, promoções relâmpago, etc.'
        },
        {
            image: compartilhamento,
            title: 'Compartilhe informações e links',
            description: 'Compartilhe informações e links importantes com todos os seus clientes de forma rápida.'
        },
        {
            image: opiniao,
            title: 'Colete opiniões',
            description: 'Conheça melhor seus clientes, faça pesquisas de satisfação ou preferências, obtenha feedback sobre seus produtos ou serviços.'
        },
        {
            image: confirmacao,
            title: 'Confirme compromissos',
            description: 'Organize melhor o seu trabalho, confirmando informações importantes com a sua equipe ou clientes. Com base nisso, poupe recursos ou remaneje seu pessoal da melhor forma possível.'
        },
        {
            image: busca,
            title: 'Alcance apenas quem precisa',
            description: 'Tenha precisão na hora de se comunicar. Envie informações direcionadas a quem precisa recebê-las.'
        },
        {
            image: estatisticas,
            title: 'Gere dados',
            description: 'Acompanhe os interesses dos seus clientes, ranqueie suas vendas, melhore seu atendimento. Com nossa ferramenta você enxergará onde seu atendimento precisa melhorar.'
        }
    ]
}

export default function MensageriaProfile({ category }) {
    const [showMessage, setShowMessage] = useState(false)

    return (
        <>
            <Features>
                {categoryData?.features?.map(feature => (
                    <Feature key={feature.title}>
                        <img src={feature.image} alt="" />
                        <div>
                            <h2>{feature.title}</h2>
                            <p>{feature.description}</p>
                        </div>
                    </Feature>
                ))}
            </Features>

            <Extra>
                <div className="vector-cut" />

                <DemoContainer>
                    <ImageContainer>
                        <img src={smartphone} alt="" className="animated fadeInLeft slow" />
                    </ImageContainer>

                    <TextContainer showMessage={showMessage}>
                        <p>
							Serviço de SMS interativo direto no celular, excelente para companhas de marketing, pesquisa de satisfação, pós venda, ideal para empresas que buscam atingir com diversos tipos de púbicos sejam eles internautas ou não. O SMS chega em qualquer tipo de aparelho de celular GSM, esse serviço é indicado para aquele público que não usa o serviço de internet com frequência, facilitando ainda mais a comunicação desejada. Alguns dos recursos que podemos usar nessa plataforma é o envio em massa de informações com textos e a interatividade de perguntas e respostas, envio de links para redirecionamento em sites e outros.
                        </p>
                    
                        <div className="button-container">
                            {showMessage ? (
                                <FaArrowDown size={30} className="animated fadeInDown" />
                            ) : (
                                <Button onClick={() => setShowMessage(true)} className="green">{category?.button_text}</Button>
                            )}

                            <p className="tip animated bounceIn" style={{ display: showMessage ? 'block' : 'none' }}>
                                Para contratar, envie um e-mail para nós: 
                                {' '}
                                <a href="mailto:contato@robot.rio.br">contato@robot.rio.br</a>
                                . Teremos prazer em atendê-lo.
                            </p>
                        </div>

                    </TextContainer>
                </DemoContainer>
            </Extra>
        </>
    )
}
