import styled from 'styled-components'
import { FaChevronDown } from 'react-icons/fa'

export const Chevron = styled(FaChevronDown)`
	margin: 0 12px;
	color: #fff;

	&:hover {
		opacity: 0.7;	
	}
`
