import React, { useState, useEffect, useCallback } from 'react'
import {
    MdReply, MdCheck, MdClose, MdDeleteForever, MdUndo, MdRemoveCircle
} from 'react-icons/md'

import api from '~/services/api'

import authHeaders from '~/util/authHeaders'
import arrayUtils from '~/util/array'

import NavOptions from '~/components/NavOptions'
import Table from '~/components/Table'
import Spinner from '~/components/Spinner'

import { Container } from './styles'

export default function ({ contract }) {
    const contractId = contract?.id || 4

    const [questions, setQuestions] = useState(null)

    useEffect(() => {
        async function loadQuestions() {
            const response = await api.get(`unanswered/${4 || contract.id}`, authHeaders)

            const unanswereds = response.map(i => ({
                id: i.id,
                question: i.question,
                answer: i.answer,
                statusId: i.status.id,
                status: i.status.name
            }))

            setQuestions(unanswereds.sort(arrayUtils.sort.comparisonFunction('id')))
        }

        loadQuestions()
    }, [])
    
    const handleAction = useCallback(async (questionId, action) => {
        console.log('questionId: ', questionId)
        console.log('contractId: ', contractId)

        const currentQuestion = await api.get(`unanswered/${contractId}/${questionId}`, authHeaders)
        let updateData

        switch(action) {
            case 'Responder':
                updateData = { status: 2, answer: '...' }
                break
            case 'Aprovar':
                updateData = { status: 4 }
                break
            case 'Desaprovar':
                updateData = { status: 2 }
                break
            case 'Recuperar':
                updateData = { status: currentQuestion.answer ? 2 : 1 }
                break
            case 'Descartar':
                updateData = { status: 3 }
                break
            case 'Solicitar remoção':
                updateData = { status: 6 }
                throw new Error('Não implementado.')
            default:
                break
        }

        const updated = await api.put(`unanswered/${contractId}/${questionId}`, updateData, authHeaders)

        const {
            question, answer, status 
        } = updated

        setQuestions(prev => [
            {
                id: questionId,
                question,
                answer,
                status: status.name,
                statusId: status.id,
                highlight: true
            },
            ...prev.filter(i => i.id !== updated.id)
        ])
    }, [])

    return (
        <Container>
            <NavOptions type="chatbot" />

            <section className="animated fadeIn">
                <h1>Perguntas do seu chatbot</h1>

                {!questions ? (
                    <Spinner />
                ) : (
                    <Table 
                        headers={[
                            { name: 'question', value: 'Pergunta' },
                            { name: 'answer', value: 'Resposta' },
                            { name: 'status', value: 'Status', style: { width: '130px' } }
                        ]}
                        showId
                        filterable
                        limit={15}
                        hideOffset={600}
                        data={questions}
                        actions={[
                            {
                                name: 'Responder',
                                icon: MdReply,
                                iconSize: 14,
                                action: handleAction,
                                checkDisabled: (question) => question.statusId > 2
                            },
                            {
                                name: 'Aprovar',
                                cellClasses: 'success-icon',
                                icon: MdCheck,
                                action: handleAction,
                                checkDisabled: (question) => ![2, 4].includes(question.statusId)
                            },
                            {
                                name: 'Desaprovar',
                                cellClasses: 'delete-icon',
                                icon: MdClose,
                                action: handleAction,
                                checkDisabled: (question) => ![2, 4].includes(question.statusId)
                            },
                            {
                                name: 'Recuperar',
                                icon: MdUndo,
                                action: handleAction,
                                checkDisabled: (question) => question.statusId !== 3
                            },
                            {
                                name: 'Descartar',
                                cellClasses: 'delete-icon',
                                icon: MdDeleteForever,
                                action: handleAction,
                                checkDisabled: (question) => question.statusId > 2
                            },
                            {
                                name: 'Solicitar remoção',
                                cellClasses: 'delete-icon',
                                icon: MdRemoveCircle,
                                action: handleAction,
                                checkDisabled: (question) => question.statusId !== 5
                            }
                        ]}
                    />
                )}
            </section>
            
        </Container>
    )
}
