import React, {
    useEffect, useRef, useState 
} from 'react'
import { Link } from 'react-router-dom'

import Customer from '~/components/Customer'

import {
    Container, Section1, Section2, Section3, Section4, SliderContent
} from './styles'

import Carousel from '~/components/Carousel'

import logoRobot from '~/assets/img/main-page/logo.png'
import logoRobotExtended from '~/assets/img/main-page/logo-extend-dark.png'
import backgroundChatbot from '~/assets/img/main-page/background.png'
import backgroundMensageria from '~/assets/img/main-page/background4.png'

import sindmepa from '~/assets/img/main-page/customer-logos/sindmepa.png'
import fmb from '~/assets/img/main-page/customer-logos/fmb.png'
import jamil from '~/assets/img/main-page/customer-logos/jamil.png'
import solucao from '~/assets/img/main-page/customer-logos/solucao.png'

import imagem1 from '~/assets/img/galery/1.jpg'
import imagem2 from '~/assets/img/galery/2.jpg'
import imagem3 from '~/assets/img/galery/3.JPG'
import imagem4 from '~/assets/img/galery/4.jpg'
import imagem5 from '~/assets/img/galery/5.jpg'
import imagem6 from '~/assets/img/galery/6.jpeg'
import imagem7 from '~/assets/img/galery/7.jpg'

import video1 from '~/assets/video/Entrevista Programa Ciência em Movimento - 03-03-2020.mp4'

let slideIndex = 0

const slides = [
    {
        title: 'Chatbot de Atendimento',
        extraText: 'Diálogos fluidos com Inteligência Artificial, atendimento agilizado e tarefas automatizadas.',
        background: backgroundChatbot,
        link: '/product/1'
    },
    {
        title: 'Mensageria',
        extraText: 'Serviço de SMS interativo, dispensa instalação de aplicativos ou internet, conteúdo direto no celular.',
        background: backgroundMensageria,
        link: '/product/2'
    }
]

const customers = [
    {
        id: 1, title: 'Sindicato dos Médicos do Pará', image: sindmepa, system: 'Sistema de Gerenciamento de Sindicatos' 
    },
    {
        id: 2, title: 'Federação Médica Brasileira', image: fmb, system: 'Sistema de Transparência' 
    },
    {
        id: 3, title: 'Instituto Jamil Sales', image: jamil, system: 'Assistente Virtual Informativo' 
    },
    {
        id: 4, title: 'Solução Jundiaí Informática', image: solucao, system: 'Assistente Virtual Informativo' 
    }
]

const medias = [
    {
        type: 'image', title: 'Respondendo a questionamentos sobre a I.A.', subtitle: 'Xodó Armazém Contemporâneo - Petrópolis - RJ', url: imagem1 
    },
    {
        type: 'image', title: 'Palestrando sobre I.A. no Pará Negócios 2019', subtitle: 'Hangar - Centro de Convenções da Amazônia', url: imagem2 
    },
    {
        type: 'image', title: 'Participação no Encontro Empresarial sobre I.A. para Negócios', subtitle: 'Auditório do Museu Imperial - Petrópolis - RJ', url: imagem3 
    },
    {
        type: 'image', title: 'Papo sobre o avanço da I.A. com Sérgio Taldo e Alexandre Vieira', subtitle: 'Xodó Armazém Contamporâneo - Petrópolis - RJ', url: imagem7
    },
    {
        type: 'image', title: 'Parte da equipe reunida no escritório da Robot Genesis', subtitle: 'Petrópolis - RJ', url: imagem4 
    },
    {
        type: 'image', title: 'Com Marcos Falconi no Programa Abrindo o Jogo', subtitle: 'TVC16 - 03/10/2019', url: imagem5 
    },
    {
        type: 'video', title: 'Entrevista no Programa Ciência em Movimento, com Márcio Campos', subtitle: 'TVC16 - 03/03/2020', url: video1 
    },
    {
        type: 'video', title: 'Entrevista no Programa Abrindo o Jogo, com Marcos Falconi', subtitle: 'TVC16 - 03/10/2019', url: 'https://www.youtube.com/embed/P_9Ln8cUWqc?start=40' 
    },
    {
        type: 'video', title: 'Entrevista no Programa Original, com Beth Oliveira', subtitle: 'TVC16 - 12/09/2019', url: 'https://www.youtube.com/embed/yXvTtgomIJo?start=1900' 
    },
    {
        type: 'image', title: 'Equipe Robot e parceiros em palestra sobre I.A. aplicada a negócios', subtitle: 'Auditório do Museu Imperial - Petrópolis - RJ', url: imagem6 
    }
]

export default function Main() {
    const [slide, setSlide] = useState(slides[slideIndex])

    const title = useRef()
    const extraText = useRef()
    const button = useRef()
    const backgroundRef = useRef()

    useEffect(() => {
        // Initial slider animation
        setTimeout(() => {
            if(button.current) {
                button.current.classList.remove('fadeIn')
                button.current.classList.remove('slower')
                // setTimeout(() => button.current.classList.add('pulse'), 200)
            }
        }, 2500)

        setInterval(() => {
            if(title.current && extraText.current) {
                extraText.current.classList.remove('fadeInUp')
                extraText.current.classList.remove('delay-600ms')
                extraText.current.classList.remove('fadeInLeft')
                extraText.current.classList.add('fadeOutRight')

                title.current.classList.remove('fadeInUp')
                title.current.classList.remove('delay-100ms')
                title.current.classList.remove('fadeInLeft')
                title.current.classList.add('fadeOutRight')

                backgroundRef.current.classList.add('fadeOut')

                setTimeout(() => {
                    if(title.current && extraText.current) {
                        slideIndex = slideIndex === slides.length - 1 ? 0 : slideIndex + 1
                        setSlide(slides[slideIndex])

                        // Handle title
                        title.current.innerHTML = slides[slideIndex].title
                        title.current.classList.remove('fadeOutRight')
                        title.current.classList.add('fadeInLeft')

                        // Handle extra text
                        extraText.current.innerHTML = slides[slideIndex].extraText
                        extraText.current.classList.remove('fadeOutRight')
                        extraText.current.classList.add('fadeInLeft')

                        // Handle background
                        backgroundRef.current.classList.remove('fadeOut')
                        backgroundRef.current.classList.add('fadeIn')
                    }
                }, 800)
            }
        }, 6000)
    }, [])

    return (
        <Container>
            <Section1 className="animated faster" background={slide.background} ref={backgroundRef}>
                <div>
                    <SliderContent>
                        <img src={logoRobot} alt="" className="animated zoomIn slow" />
                        <h1 className="animated fadeInUp delay-100ms" ref={title}>{slide.title}</h1>
                        <p className="animated fadeInUp delay-600ms" ref={extraText}>{slide.extraText}</p>
                        <Link to={slide.link} className="animated fadeIn delay-900ms slower" ref={button}>CONHEÇA</Link>
                    </SliderContent>
                </div>
            </Section1>

            <Section2>
                <div>
                    <img src={logoRobotExtended} alt="" />
                </div>

                <div>
                    <h1>Quem somos</h1>
                    <p>A Robot Genesis Ltda. foi criada em 2018, na cidade de Petrópolis/RJ, com o objetivo de desenvolver ferramentas para a implementação rápida de soluções com IA (Inteligência Artificial).</p>
                </div>
            </Section2>

            <Section3>
                <h1>Nossos clientes</h1>
                
                <div>
                    {customers && customers.map(customer => (
                        <Customer key={customer.id} data={customer} />
                    ))}
                </div>
            </Section3>
           
            <Section4>
                <h1>Entrevistas e Palestras</h1>

                <Carousel items={medias} />
            </Section4>

        </Container>
    )
}
