export default {
    fillMoney: e => {
        if(!e) {
            throw new Error('The fillMoney function requires the event as a parameter.')
        }

        if(!e.target.value) {
            return
        }

        if(e.target.value.indexOf(',') === -1) {
            return `${e.target.value},00`
        } 
        switch(e.target.value.split(',')[1].length) {
            case 0:
                return `${e.target.value}00`
            case 1: 
                return `${e.target.value}0`
            default: 
                return e.target.value
        }
    }
}
