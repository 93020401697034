import React, { useEffect, useRef } from 'react'
import { useField } from 'formik'

import {
    InputContainer, Textbox, MaskedTextbox
} from './styles'

export default function ({
    label, mask, icon, withoutForm, containerClass, style, ...props 
}) {
    let field
    let meta

    const Icon = icon?.Icon

    const input = useRef()

    if(!withoutForm) {
        [field, meta] = useField(props)
    }

    useEffect(() => {
        if(props.focused) {
            input.current.focus()
        }
    })
    
    if(withoutForm) {
        return (
            <InputContainer className={containerClass}>
                <Textbox 
                    placeholder={label} 
                    {...field} 
                    {...props} 
                    ref={input} 
                    style={{ ...style, paddingRight: icon ? 42 : 0 }} 
                />
                {icon && <Icon {...icon.props} size={icon.props?.size || 14} />}
            </InputContainer>
        )
    }
    
    return (
        <>
            <InputContainer className={containerClass}>
                {!mask ? (
                    <>
                        <Textbox 
                            placeholder={label} 
                            {...field} 
                            {...props} 
                            ref={input} 
                            style={{ ...style, paddingRight: icon ? 42 : 0 }} 
                        />
                        {icon && <Icon {...icon.props} size={icon.props?.size || 14} />}
                    </>
                ) : (
                    <>
                        <MaskedTextbox 
                            placeholder={label} 
                            {...field} 
                            {...props} 
                            ref={input} 
                            style={{ ...style, paddingRight: icon ? 42 : 0 }} 
                            mask={mask} 
                        />
                        {icon && <Icon {...icon.props} size={icon.props?.size || 14} />}
                    </>
                )}

                {meta.touched && meta.error 
                    ? <span className="error">{meta.error}</span>
                    : null}
                    
            </InputContainer>
        </>
    )
}
