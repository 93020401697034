import styled from 'styled-components'
import { darken } from 'polished'

export const Button = styled.button`
	&:not(.Toastify__close-button) {
		cursor: pointer;
		font-size: 14px;
		font-weight: 300;
		background: #0d47a1;
		border: none;
		border-radius: 4px;
		transition: all 0.3s;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		margin: 5px 0 5px 8px;
		height: 40px;
		padding: 0 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s;

		&:hover {
			box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 5px 8px 0 rgba(0,0,0,0.2);
			background: ${darken(0.03, '#0d47a1')};
		}

		&:disabled {
			opacity: 0.3;
		}

		> svg {
			margin-left: 10px;
		}
	
		/* Colors */

		&.transparent {
			cursor: pointer;
			background: transparent;
			border: solid 1px #FFF;
			box-shadow: none;
			color: #fff;

			&:hover {
				color: #2c2c2c;
				background: #FFF;
				font-weight: 500;

				svg {
					color: #2c2c2c !important;
				}
			}

			&:disabled {
				cursor: default;
				color: #eee;
				font-weight: 300;
				background: transparent;
				border: dashed 1px #ccc;

				&:hover svg {
					color: #eee !important;
				}
			}
		}

		&.green {
			background: #4caf50;

			&:hover {
				background: ${darken(0.03, '#4caf50')};
			}
		}

		&.red {
			background: #f44336;

			&:hover {
				background: ${darken(0.03, '#f44336')};
			}
		}

		&.yellow {
			background: #ffeb3b;
			color: #2c2c2c;

			&:hover {
				background: ${darken(0.03, '#ffeb3b')};
			}
		}
	}
`
