import { useEffect } from 'react'

import stringUtils from '~/util/string'

export default function useScript(url, async = true, defer = false) {
    useEffect(() => {
        const currentScripts = Array.prototype.slice.call(document.querySelectorAll('script'))

        const sameDomainScripts = currentScripts.filter(s => s.src.includes(stringUtils.extractDomain(url)))

        const script = document.createElement('script')

        if(!sameDomainScripts.length) {
            if(!currentScripts.includes(url)) {
                script.src = url
                script.async = async
                script.defer = defer
            
                document.body.appendChild(script)
            }
        }
    }, [url])
}
