import styled from 'styled-components'
import { lighten } from 'polished'

export const TableContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	div {
		width: 100%;
		max-width: 244px;
		transition: width 0.3s;
		margin: 30px 0 0;
	}

	.tooltip-default {
		width: fit-content;
	}
`

export const Table = styled.table`
	width: 100%;
	margin: 16px 0;

	thead {
		background: #2c2c2c;

		th {
			padding: 8px 10px;
			font-weight: 100;
			height: 34px;

			&:hover {
				border: solid 1px #444;
				padding: 8px 10px;
				cursor: pointer;
				font-weight: 500;

				svg {
					display: inline;
				}
			}

			svg {
				display: none;
				float: right;
			}

			@media (max-width: 500px) {
				font-size: 13px;
				font-weight: 400;
			}

			&.action-icon {
				width: 10px;
			}
		}

		th:first-child {
			border-top-left-radius: 4px;
		}

		th:last-child {
			border-top-right-radius: 4px;
		}
	}

	tbody {
		tr {
			background: #3a3a3a;
			color: #FFF;
			font-weight: 400;
			font-size: 13px;

			td {
				padding: 4px 8px;
				border: solid 0.5px #333;
				text-align: center;
				height: 36px;
				min-width: 52px;

				&:hover {
					background: ${lighten(0.03, '#3a3a3a')};
				}

				svg:not(.disabled) {
					cursor: pointer;
					margin: 0 10px;
					transition: all 0.3s;
					
					&:hover {
						background: #FFF;
						border-radius: 50%;
						width: 24px;
						height: 24px;
						padding: 4px;
						margin: 0;
					}
				}

				&.action-icon svg:hover {
					color: #2196f3;
				}

				&.delete-icon svg:hover {
					color: #f44336;
				}

				&.edit-icon svg:hover {
					color: #2196f3;
				}

				&.success-icon svg:hover {
					color: #4caf50;
				}

				& svg.disabled:hover {
					color: #FFF;
				}
			}

			@media (max-width: 500px) {
				font-size: 11px;
			}
		}

		tr:nth-child(even) {
			background: #333;
		}

		tr:last-child {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;

			td {
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}

		tr.read-more-line {
			td {
				height: 30px;
				background-image: linear-gradient(180deg, #3a3a3a, #1D3B3B);
				text-align: center;
				cursor: pointer;
				transition: font-weight 0.3s;
				color: #FFF;
				
				svg {
					opacity: 0.3;
					transition: opacity 0.3s;
				}

				&:hover {
					svg {
						opacity: 0.6;
					}
				}
			}
		}
	}

	.hide-on-small {
		@media (max-width: ${props => props.hideOffset || 500}px) {
			display: none;
		}
	}
`

export const EmptyContainer = styled.div`
	background: #3a3a3a;
	color: #FFF;
	border-radius: 30px;
	width: 100%;
	max-width: unset !important;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 20px 0;

	svg {
		opacity: 0.5;
		margin-bottom: 15px;
	}

	span {
		opacity: 0.5;
		font-style: italic;
	}
`
