import styled, { css } from 'styled-components'

const innerShadow = css`
	box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.9);
`

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;

	.file-select-area {
		width: ${props => `${props.areaSize}px`};
		height: ${props => `${props.areaSize}px`};
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;
		background-color: #ccc;
		border-radius: ${props => props.format === 'circle' ? '50%' : '10px'};
		color: #424242;
		background-size: cover;
		background-position: 50% 50%;
		transition: all 0.3s;

		p {
			font-size: 14px;
			text-align: center;	
			opacity: 0.8;
		}

		p.with-preview {
			opacity: 0;
			background-color: transparent;
			border-radius: 20px;
			padding: 5px;
			font-weight: 700;
			transition: all 0.5s;
		}

		&:hover {
			border: dashed 1px #fff;
			transform: scale(1.04);
			${innerShadow}


			p.with-preview {
				opacity: 0.85;
				background: rgba(255, 215, 0, 0.85);
			}
		}
	}
`
