export default {
    mobile: /^$|^\(\d{2}\) \d{5}-\d{4}$/,
    phone: /^$|^\(\d{2}\) \d{4}-\d{4}$/,
    mobileOrPhone: /^$|^\(\d{2}\) \d{4,5}-\d{4}$/,
    shortDate: /^\d{2}\/\d{2}$/,
    date: /^\d{2}\/\d{2}\/\d{4}$/,
    cvv: /^\d{3}$/,
    url: /^$|^(www\.)?\w+\.\w+(\.\w+)?(\/|\/\w+)?$/,
    money: /^R\$ [0-9.]+,\d{2}$/,
    cep: /^\d{5}-\d{3}$/,
    uf: /^[A-Z]{2}$/
}
