import styled from 'styled-components'

import { Textarea } from '~/components/Form'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 35px;

	.card {
		max-width: 800px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		h1 {
			width: 100%;
			font-size: 22px;
			margin-bottom: 18px;
		}

		.result-label {
			align-self: flex-start;
			font-size: 16px;
			margin: 10px 0;
		}

		input, textarea {
			width: 100%;
		}

		button {
			width: fit-content;
		}
	}
`

export const Result = styled(Textarea).attrs({
    readOnly: true,
    withoutForm: true,
    name: 'result',
    label: 'Resultado'
})`
	font-size: 14px;
`
