import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { Button, Textarea } from '~/components/Form'
import Card from '~/components/Card'

import { Container, Result } from './styles'

const messageGreat = `Olá, #nome#,

O SINDMEPA divulga as notícias da semana:

`
const messageFooter = `
Atenciosamente,
Assessoria de Comunicação do Sindicato dos Médicos do Estado do Pará (Sindmepa)`

export default function () {
    const [result, setResult] = useState('')

    function handleGenerate() {
        const message = document.querySelector('textarea[name=message]').value

        if(!message) {
            toast.warn('Nenhuma mensagem definida.')
            return
        }

        const news = message.split('\n').filter(n => n.length > 25)
		
        const generated = news.reduce((final, current) => {
            const breakPoint = current.indexOf(':')

            const title = current.substr(0, breakPoint).trim()
            const link = current.substr(breakPoint + 1).trim()
			
            return `${final}<p style="display: flex; justify-content: center"><a href="${link}" style="background-color:#388E3C; padding:12px; border-radius:10px; color:#FFF; font-family:Arial; font-size:14px; text-decoration:none">${title}</a></p>
`
        }, messageGreat).concat(messageFooter)
		
        setResult(generated)
    }

    return (
        <Container>
            <Card>
                <h1>Gerar HTML: Notícias da semana</h1>
		
                <Textarea label="Mensagem" name="message" withoutForm />

                {result && (
                    <>
                        <span className="result-label">Resultado</span>
                        <Result>{result}</Result>
                    </>
                )}

                <Button onClick={handleGenerate} className="transparent">
                    Gerar HTML
                </Button>
            </Card>
        </Container>
    )
}
