import styled from 'styled-components'

export const Container = styled.div`
	padding: 50px 60px;
`

export const Header = styled.header`
	display: flex;

	div {
		width: 100%;

		h1 {
			padding: 15px 0;
			border-bottom: solid 1px #fff;
			font-weight: 400;
		}

		h2 {
			font-size: 14px;
			padding: 24px 0;
		}
	}
`
