import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { Container, UploadMessage } from './styles'

export default function ({ handleUpload }) {
    const {
        getRootProps, getInputProps, isDragReject, isDragActive
    } = useDropzone({
        onDrop: acceptedFiles => handleUpload(acceptedFiles)
    })

    const renderUploadMessage = useCallback((dragActive, dragReject) => {
        if(!dragActive) {
            return (
                <UploadMessage>
                    <p>Arraste arquivos aqui</p>
                    <p>ou</p>
                    <p>clique para carregar</p>
                </UploadMessage>
            )
        }

        if(dragReject) {
            return <UploadMessage type="error">Erro</UploadMessage>
        }
		
        return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>
    }, [isDragReject, isDragActive])
  
    return (
        <Container {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />

            {renderUploadMessage(isDragActive, isDragReject)}
        </Container>
    )
}
