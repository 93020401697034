import { createGlobalStyle } from 'styled-components'
import { darken } from 'polished'

export default createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Ubuntu:100,300,400,700&display=swap');

	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

	*:focus {
		outline: 0;
	}

	html, body, #root {
		min-height: 100%;
	}

	body {
		background-image: linear-gradient(180deg, #427C56, #2B3E4D);
		background-attachment: fixed;
		-webkit-font-smoothing: antialiased !important;
		overflow-x: hidden;
		scroll-behavior: smooth;
	}

	body, button, input, textarea, h1, h2, h3, h4, h5, h6 {
		color: #FFF;
		font-size: 14px;
		font-family: Ubuntu, Roboto, sans-serif;
		font-weight: 100;
	}

	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 26px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 16px;
	}

	h6 {
		font-size: 12px;
	}

	a {
		text-decoration: none;
	}

	ul {
		list-style: none;
	}

	span.error {
		color: #222;
		font-size: 12px;
		background-color: #ffeb3b;
		border-radius: 4px;
		margin-bottom: 10px;
		padding: 5px;
		display: block;
		width: 100%;
	}

	a.button {
		cursor: pointer;
		font-size: 14px;
		font-weight: 300;
		background: #0d47a1;
		border: none;
		border-radius: 4px;
		transition: all 0.3s;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		margin: 5px 0 5px 8px;
		height: 40px;
		padding: 0 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.2s;

		&:hover {
			box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 5px 8px 0 rgba(0,0,0,0.2);
			background: ${darken(0.03, '#0d47a1')};
		}

		&:disabled {
			opacity: 0.3;
			cursor: wait;
		}

		> svg {
			margin-left: 10px;
		}

		/* Colors */

		&.transparent {
			cursor: pointer;
			background: transparent;
			border: solid 1px #FFF;
			box-shadow: none;
			color: #fff;

			&:hover {
				color: #2c2c2c;
				background: #FFF;
				font-weight: 500;
			}

			&:disabled {
				cursor: wait;
				color: #eee;
				font-weight: 300;
				background: transparent;
				border: dashed 1px #ccc;
			}
		}

		&.green {
			background: #4caf50;
			color: #fff;

			&:hover {
				background: ${darken(0.03, '#4caf50')};
			}
		}

		&.red {
			background: #f44336;

			&:hover {
				background: ${darken(0.03, '#f44336')};
			}
		}

		&.yellow {
			background: #ffeb3b;
			color: #2c2c2c;

			&:hover {
				background: ${darken(0.03, '#ffeb3b')};
			}
		}
	}
`
