import styled from 'styled-components'

import { InputContainer } from '~/components/Form/Switch/styles'

export const Container = styled.article`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px 0;

	> ${InputContainer} {
		margin-bottom: 16px;
		padding-left: 0;
		width: 300px;
	}
`

export const FormContainer = styled.section`
	max-width: 300px;
	padding-bottom: 30px;

	input {
		width: 100%;
	}

	button {
		margin: 0 !important;
		width: 100%;
	}

	button:last-child {
		margin-top: 14px !important;
		height: 24px;
	}
`

export const SpinnerContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`
