import * as yup from 'yup'

import regex from '~/util/regex'

const { Iugu } = window

yup.addMethod(yup.string, 'creditCard', function () {
    return this.test('card-number-test', 'O número do cartão não é válido.', value => value ? Iugu.utils.validateCreditCardNumber(value) : true)
})

yup.addMethod(yup.string, 'money', function (message) {
    return this.matches(regex.money, message)
})

yup.addMethod(yup.string, 'cep', function (message) {
    return this.matches(regex.cep, message)
})

yup.addMethod(yup.string, 'site', function (message) {
    return this.matches(regex.url, message)
})

yup.addMethod(yup.string, 'shortDate', function (message) {
    return this.matches(regex.shortDate, message)
})

yup.addMethod(yup.string, 'date', function (message) {
    return this.matches(regex.date, message)
})

yup.addMethod(yup.string, 'future', function () {
    return this.test('date-future-test', 'A data deve estar no futuro.', value => Iugu.utils.validateExpirationString(value))
})

yup.addMethod(yup.string, 'mobile', function () {
    return this.matches(regex.mobile, 'Este número de celular é inválido.')
})

yup.addMethod(yup.string, 'phone', function () {
    return this.matches(regex.phone, 'Este número de telefone fixo é inválido.')
})

yup.addMethod(yup.string, 'mobileOrPhone', function () {
    return this.matches(regex.mobileOrPhone, 'Este número de telefone é inválido.')
})

yup.addMethod(yup.string, 'uf', function (message) {
    return this.matches(regex.uf, message)
})

export default yup
