import styled from 'styled-components'

export const Footer = styled.footer`
	display: flex;
	flex-direction: column;
`

export const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background: #1F3A2A;
	padding: 30px 0 0;

	img {
		height: 50px;
	}

	div {
		min-height: 10vh;
	}

	@media (max-width: 700px) {
		flex-direction: column;

		div {
			width: 100%;
			min-height: 10vh;
		}
	}
`

export const Logo = styled.div`
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px 100px;

	img {
		height: 70px;
		margin-bottom: 20px;
	}

	@media (max-width: 700px) {
		min-height: 1vh;
		padding: 24px;
	}
`

export const List = styled.div`
	width: 30%;
	padding: 10px;

	h2 {
		font-size: 18px;
		font-weight: 400;
		display: flex;
		align-items: center;
		margin-left: 16px;

		svg {
			margin-right: 10px;
		}
	}

	ul {
		margin-bottom: 24px;

		li {
			margin: 4px 30px;
			display: flex;
			align-items: center;

			svg {
				margin-left: 8px;
			}
		}
	}

	a {
		font-size: 13px;
		color: #fff;

		&:hover {
			opacity: 0.6;
			color: #fff;
		}
	}

	> a {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 50px;

		img {
			width: 37px;
			height: 30px;
			margin: 0 8px 0 30px;
		}
	}
`

export const FootBar = styled.div`
	background: #162b1f;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		font-size: 11px;
		color: #8cbaa0;
	}
`
