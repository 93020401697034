import styled from 'styled-components'

export const PeopleFormActions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`

export const PeopleManager = styled.section`
	> button {
		margin: 0 !important;

		@media (max-width: 500px) {
			& {
				width: 100%;
			}
		}
	}

	h1 {
		margin-bottom: 18px;
	}

	form {
		display: ${props => props.formVisible ? 'grid' : 'none'};
		grid-template-columns: auto auto auto;
		grid-template-rows: auto auto auto;
		grid-column-gap: 10px;

		div:first-child {
			grid-column: 1/4;
			grid-row: 1;
		}

		div:nth-child(2) {
			grid-column: 1/2;
			grid-row: 2;
		}

		div:nth-child(3) {
			grid-column: 2/4;
			grid-row: 2;
		}

		div:nth-child(4) {
			grid-column: 1/2;
			grid-row: 3;
		}

		div:nth-child(5) {
			grid-column: 2/4;
			grid-row: 3;
		}

		${PeopleFormActions} {
			grid-column: 1/4;
			grid-row: 4;
		}

		input, select {
			width: 100%;
		}

		@media(max-width: 800px) {
			display: ${props => props.formVisible ? 'block' : 'none'};
		}
	}

	@media (max-width: 800px) {
		padding: 20px 22px !important;
	}
`

export const TablePlaceholder = styled.div`
	width: 100%;
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70px;
	width: 100%;
`
