import styled from 'styled-components'

export const AvatarContainer = styled.div.attrs({
    className: 'animated zoomIn faster'
})`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 24px;
`

export const Container = styled.article`
	min-height: 80vh;
	padding: 34px 50px 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	section {
		width: 80%;
		background: #1D3B3B;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		border-radius: 2px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 35px;
		min-height: 80vh;

		> h1 {
			width: 100%;
			font-size: 22px;
		}

		form {
			padding-top: 30px;
			width: 100%;
			max-width: 480px;
			display: grid;
			grid-gap: 10px;
			grid-template-columns: 1fr 150px;
			grid-auto-rows: auto;
			justify-items: center;

			${AvatarContainer} {
				grid-column: 2;
				grid-row: 1/4;
			}

			button, .spinner {
				grid-row: 4/5;
				grid-column: 1/3;
				margin: 0 !important;
			}

			@media(max-width: 600px) {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	> p {
		font-size: 18px;
		padding: 35px 0;
	}

	@media (max-width: 500px) {
		padding: 20px 0 38px;
	}
`
