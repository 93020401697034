import styled from 'styled-components'

import Card from '~/components/Card'

export const Container = styled(Card).attrs(() => ({
    className: 'bot-type animated fadeIn'
}))`	
	display: flex; 
	flex-direction: column;
	justify-content: space-between;
	
	.card-content {
		display: flex;
		flex-direction: column;
		line-height: 22px;

		.avatar-container {
			height: 230px;
			display: flex;
			align-items: center;
			justify-content: center;

			> img {
				height: 100%;
				transition: all 0.2s linear;
				-webkit-transition: all 0.2s linear;
				-o-transition: all 0.2s linear;
				filter: drop-shadow(0px 0px 0px rgba(255,255,255,0.80));
			}
		}

		h1 {
			font-size: 1.5rem;
			margin: 20px 0 10px;
			font-weight: 100;
		}

		h2 {
			font-size: 0.9rem;
			margin: 8px 0 14px;

			span:not(.price) {
				font-size: 1.5rem;
			}

			.price {
				margin-bottom: 10px;
				display: block;
			}

			.payment-times {
				font-size: 14px !important;
				margin-left: 10px;
			}
		}

		.view-more {
			position: absolute;
			color: #5995e3;
			font-weight: 300;
			padding: 15px 20px;
			cursor: pointer;
			display: none;
		}

		.resource-title {
			font-weight: 500;
			padding: 10px 0;
		}

		ul {
			padding-left: 0px;

			li {
				padding-left: 1.5em;
				position: relative;
				font-size: 0.9rem;

				&:after {
					content: '';
					height: .4em;
					width: .4em;
					background: teal;
					display: block;
					position: absolute;
					transform: rotate(45deg);
					top: .55em;
					left: 0;
				}
			}
		}

		button {
			svg {
				margin-right: 8px;
			}
		}
	}

	.card-action {
		display: flex;
		justify-content: center;
		margin-top: 20px;

		button {
			width: 100%;
			margin: 0;
		}
	}

	&:hover {
		.card-content {
			.avatar-container {
				> img {
					transform: scale(1.02);
					filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
				}
			}
		}
	}
`
