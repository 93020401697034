import React from 'react'

import { Container } from './styles'

export default function ({ data }) {
    return (
        <Container>
            <img src={data.image} alt="" />
            <h2>{data.title}</h2>
            <p>{data.system}</p>
        </Container>
    )
}
