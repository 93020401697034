import React, { useEffect, useRef } from 'react'
import { useField } from 'formik'

import { Textarea, TextareaContainer } from './styles'

export default function ({
    label, icon, withoutForm, containerClass, ...props 
}) {
    const Icon = icon?.Icon

    let field
    let meta

    const input = useRef()

    if(!withoutForm) {
        [field, meta] = useField(props)
    }

    useEffect(() => {
        if(props.focused) {
            input.current.focus()
        }
    })

    if(withoutForm) {
        return (
            <TextareaContainer className={containerClass}>
                <Textarea placeholder={label} {...field} {...props} ref={input} style={{ paddingRight: icon ? 42 : 15 }} />
                {icon && <Icon {...icon.props} size={icon.props?.size || 14} />}
            </TextareaContainer>
        )
    }
	
    return (
        <TextareaContainer className={containerClass}>
            <Textarea placeholder={label} {...field} {...props} ref={input} style={{ paddingRight: icon ? 42 : 15 }} />
            {icon && <Icon {...icon.props} size={icon.props?.size || 14} />}

            {meta.touched && meta.error 
                ? <span className="error">{meta.error}</span>
                : null}
        </TextareaContainer>
    )
}
