import StringMask from 'string-mask'

import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import emailMask from 'text-mask-addons/dist/emailMask'

export default {
    mobile: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],

    phone: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],

    cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],

    cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],

    zipcode: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],

    email: emailMask,

    cardNumber: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    cardNumberAmex: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    cvv: [/\d/, /\d/, /\d/],
    shortDate: [/\d/, /\d/, '/', /\d/, /\d/],
    date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],

    money: createNumberMask({
        prefix: 'R$ ',
        thousandsSeparatorSymbol: '.',
        decimalSymbol: ',',
        decimalLimit: 2,
        allowDecimal: true,
        requireDecimal: false
    }),

    maskApply: {
        mobile: number => `(${number.slice(0, 2)}) ${number.slice(2, 7)}-${number.slice(7)}`,
        currency: value => {
            const formatter = new StringMask('#.##0,00', { reverse: true })
            return `R$ ${formatter.apply(value * 100)}`
        }
    }
}
