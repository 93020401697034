import styled from 'styled-components'

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	
	section {
		display: flex;
		align-items: center;
		flex-direction: column;
		background: #1D3B3B;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		border-radius: 2px;
		padding: 35px;
		margin: 35px 100px;
		width: calc(100% - 200px);
		min-height: 80vh;

		h1 {
			width: 100%;
			font-size: 22px;
			font-weight: 300;
			margin-bottom: 18px;
		}

		form {
			width: 100%;
		}

		@media(max-width: 800px) {
			margin: 35px 0;
			width: 100%;
			padding: 30px 20px;
		}
	}

	.p-chips {
		width: 100%;

		ul {
			width: 100%;
			background: rgba(255,255,255,0.1);
			border: none;
			padding: 0 15px !important;

			&.p-disabled {
				opacity: 1;
			}

			&:hover {
				box-shadow: none !important;
				border: none !important;
			}

			li {
				background: transparent !important;
				padding: 0.2145em 2px !important;

				input {
					height: 44px;
					border-radius: 3px;
					padding: 0 !important;
					outline: 0;
					color: #FFF !important;

					&::placeholder {
						color: rgba(255, 255, 255, 0.7);
					}
				}

				.p-chips-token-icon {
					display: none;
				}

				.p-chips-token-label {
					padding-right: 4px;
					margin: 0;
					border: dashed 1px #FFF !important;
					border-radius: 5px;

					div {
						margin-left: 4px;
					}
				}
			}
		}
	}
`

export const Replacement = styled.div`
	margin-left: 15px;
`

export const FormActions = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	
	span {
		font-size: 11px;
	}

	button {
		margin-left: 24px !important;
	}
`
