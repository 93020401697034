import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FaUser, FaLock } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import api from '~/services/api'

import scroll from '~/util/scroll'

import { Textbox, Button } from '~/components/Form'

import { Container, Content } from './styles'

import logo from '~/assets/img/logo.png'

const validation = Yup.object({
    email: Yup.string()
        .email('E-mail inválido.')
        .required('E-mail obrigatório.'),
    password: Yup.string()
        .required('Senha obrigatória.')
})

const initialValues = { email: '', password: '' }

export default function SignIn({ history }) {
    scroll.toTop()

    const handleSubmit = useCallback(async (values, { setSubmitting }) => {
        try {
            const session = await api.post('sessions', values)

            sessionStorage.setItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN, session.token)
            sessionStorage.setItem(process.env.REACT_APP_SESSION_STORAGE_USER, JSON.stringify(session.user))

            setSubmitting(false)
            
            history.push({
                pathname: '/dashboard',
                state: { reload: true }
            })
        } catch(e) {
            toast.error(e.msg)
        }
    }, [history])

    return (
        <Container>
            <Content className="animated fadeIn slow">
                <img src={logo} alt="Robot Genesis" className="animated fadeIn" />

                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Textbox name="email" label="Seu e-mail" icon={{ Icon: FaUser }} />

                            <Textbox name="password" type="password" label="Senha" icon={{ Icon: FaLock }} />

                            <Button type="submit" disabled={isSubmitting} className="green">Entrar</Button>

                            <p>ou</p>

                            <Link to="/signup">Crie sua conta aqui!</Link>
                        </Form>
                    )}
                </Formik>

            </Content>
        </Container>
    )
}
