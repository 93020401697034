import React, {
    useState, useEffect, useRef, useCallback 
} from 'react'
import { Link } from 'react-router-dom'
import { FaTimes, FaArrowRight } from 'react-icons/fa'

import api from '~/services/api'

import { Button } from '~/components/Form'

import { Container, InterestProduct, ProductLinks } from './styles'

import ContractList from '~/components/ContractList'

export default function ({ user, history }) {
    const interestProductId = sessionStorage.getItem('robot-product-interest-id')

    const interestBoxRef = useRef()

    const [interestProduct, setInterestProduct] = useState(null)
    const [dimiss, setDimiss] = useState(false)

    // Load interest product
    useEffect(() => {
        async function loadCategory() {
            if(interestProductId) {
                const product = await api.get(`products/${interestProductId}`)

                setInterestProduct(product)
            }

            setTimeout(() => sessionStorage.removeItem('robot-product-interest-id'), 500)
        }
        
        loadCategory()
    }, [])

    const handleDimiss = useCallback(() => {
        interestBoxRef.current.classList.remove('zoomIn')
        interestBoxRef.current.classList.add('zoomOut')

        setTimeout(() => {
            setDimiss(true)
            setInterestProduct(null)
        }, 700)
    }, [])

    const handleRedirectToInterestedProduct = useCallback(product => {
        history.push({
            pathname: `${product.category.order_link}/order`,
            state: { productId: product.id }
        })
    }, [])

    return (
        <Container>
            {interestProduct && !dimiss && (
                <InterestProduct ref={interestBoxRef}>
                    <div />
                    <div>
                        <h1>{`Você estava interessado no produto ${interestProduct.name}`}</h1>
                        <p>Quer continuar a contratação?</p>
                        <Button onClick={() => handleRedirectToInterestedProduct(interestProduct)} className="green">Clique aqui</Button>
                    </div>
                    <FaTimes size={18} onClick={handleDimiss} />
                </InterestProduct>
            )}

            <ProductLinks>
                <Link to="/catalog" className="button transparent">
                    <span>Ir para o Catálogo de Produtos</span>
                    <FaArrowRight size={20} />
                </Link>
            </ProductLinks>

            <ContractList history={history} user={user} />
            
        </Container>
    )
}
