import styled from 'styled-components'

export const Container = styled.section`
	background: #1D3B3B;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	border-radius: 2px;
	padding: 35px;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 800px;

	h1 {
		font-weight: 400;
		padding-bottom: 20px;
	}

	p {
		text-align: justify;
		max-width: 300px;
		line-height: 24px;
	}

	img {
		height: 360px;
		width: 360px;
	}

	.p-carousel-item {
		height: 320px;

		.image-container {
			background-size: auto 100%;
			background-position: center;
			height: 100%;
			max-height: 400px;
		}

		@media (max-width: 800px) { 
			& {
				height: 260px;
			}
		}
	}

	@media (max-width: 800px) {
		width: 100%;
		padding: 35px 22px;
		margin: 15px 0;

		h1 {
			font-size: 22px;
		}
	}
`
