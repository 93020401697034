import React from 'react'
import { FaCoins } from 'react-icons/fa'
import {
    MdHome, MdEmail, MdPerson, MdFolder 
} from 'react-icons/md'

export default [
    {
        to: '/dashboard',
        icon: <MdHome size={20} />,
        text: 'Início'
    },
    {
        to: '/query',
        icon: <FaCoins size={20} />,
        text: 'Consultas SQL'
    },
    {
        to: '/files',
        icon: <MdFolder size={20} />,
        text: 'Arquivos'
    },
    {
        to: '/mensageria',
        icon: <MdEmail size={20} />,
        text: 'Mensageria'
    },
    {
        to: '/people',
        icon: <MdPerson size={20} />,
        text: 'Pessoas'
    }
]
