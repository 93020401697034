import React, { useState } from 'react'

import { CarouselStyled } from './styles'

import 'primereact/resources/themes/nova-dark/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

export default function ({
    items, numVisible, timeout, titleStyle 
}) {
    const [data] = useState(items)

    function template(item) {
        return (
            <>
                {item.type === 'image' ? (
                    <div className="image-container" style={{ backgroundImage: `url(${item.url})` }}>
                        <span />
                    </div>
                ) : item.type === 'video' && item.url.includes('youtube') ? (
                    <iframe title={item.title} src={item.url} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                ) : (
                    <video controls>
                        <source src={item.url} type="video/mp4" />
                    </video>
                )}

                <div>
                    <h1>{item.title}</h1>
                    {item.subtitle && (
                        <h2>{item.subtitle}</h2>
                    )}
                </div>
            </>
        )
    }

    return (
        <>
            <CarouselStyled
                value={data}
                itemTemplate={template}
                numVisible={numVisible || 4}
                numScroll={1}
                circular
                titleStyle={titleStyle}
                className="large-carousel"
                autoplayInterval={timeout || 5000}
            />

            <CarouselStyled
                value={data}
                itemTemplate={template}
                numVisible={1}
                numScroll={1}
                circular
                titleStyle={titleStyle}
                className="small-carousel"
                autoplayInterval={timeout || 5000}
            />
        </>
    )
}
