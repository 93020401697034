import styled from 'styled-components'

export const Container = styled.article`
	max-width: 400px;
	width: 100%;
	font-size: 12px;
		
	.progress-label {
		z-index: 2;
	}
`

export const Bar = styled.div`
	position: relative;
	width: 100%;
	height: 40px;
	border: 1px solid #FFF;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-color: ${props => props.borderColor};
	background-color: ${props => props.backgroundColor};
`

export const Progress = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: #388E3C;
	border-radius: 3px;
	z-index: 2;
	background-color: ${props => props.foregroundColor};
`
