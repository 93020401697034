import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	padding: 15px;

	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		padding: 10px;

		div.image-container {
			width: 300px;
			height: 300px;
			background-position: center;
			background-size: cover;
			transition: all 0.2s;
			border-radius: 50%;

			&:hover {
				opacity: 0.8;
				transform: scale(1.03);
			}

			@media(max-width: 600px) {
				width: 200px;
				height: 200px;
			}
		}

		legend {
			font-size: 22px;
			color: #424242;
			padding: 15px;
			font-weight: 600;
			text-align: center;
		}
	}
`
