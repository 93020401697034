import React, { useState, useEffect, useCallback } from 'react'
import { FaEyeSlash, FaCogs } from 'react-icons/fa'

import { Button } from '~/components/Form'
import Spinner from '~/components/Spinner'

import authHeaders from '~/util/authHeaders'

import api from '~/services/api'

import {
    Container, ProductList, Product, EmptyList 
} from './styles'

export default function ({ history, user }) {
    const [contracts, setContracts] = useState([])
    const [loaded, setLoaded] = useState(false)
    
    // Load contract list
    useEffect(() => {
        async function loadContracts() {
            const response = await api.get('contracts', authHeaders)

            setContracts(response)
            setLoaded(true)
        }

        loadContracts()
    }, [])

    const handleClick = useCallback(contract => {
        history.push({
            pathname: contract.product.category.dashboard_link,
            state: { user, contract }
        })
    }, [contracts, loaded])

    return (
        <Container className="animated fadeIn">
            <h1>Soluções Contratadas</h1>
			
            {loaded ? (
                <ProductList className="animated fadeIn">
                    {contracts.length ? contracts.map(contract => (
                        <Product 
                            key={contract.id} 
                            style={{ opacity: contract.status === 2 ? 0.4 : 1 }}
                        >
                            <span>
                                {`${contract.parameters.chatbot_name 
                                    || contract.product.name}`} 
                                <p>{contract.product.name}</p>
                            </span>
                            {contract.status === 1 ? (
                                <Button onClick={() => handleClick(contract)} className="transparent">
                                    Acessar
                                </Button>
                            ) : (
                                <div className="processing-label">
                                    <FaCogs size={28} />
                                    <span>Em análise</span>
                                </div>
                            )}
                            
                        </Product>
                    )) : (
                        <EmptyList>
                            <FaEyeSlash size={50} />
                            <p className="empty-list">Nenhum serviço contratado</p>
                        </EmptyList>
                    )}
                </ProductList>
            ) : (
                <Spinner />
            )}
        </Container>
    )
}
