import React, { useEffect, useState, useCallback } from 'react'
import { Line } from 'react-chartjs-2'
import { FaEyeSlash } from 'react-icons/fa'
import { MdScreenRotation } from 'react-icons/md'

import { Tip, ChartPlaceholder } from './styles'

export default function ({ chartData }) {	
    const [options, setOptions] = useState(null)
    const [data, setData] = useState(null)

    const detectYAxisSteps = useCallback(() => {
        const max = Math.max(...chartData.data.datasets[0].data)

        const div = Math.floor(max / 10) * 10
        const rest = max % 10
        const temp = div + (rest > 0 ? 10 : 0)

        if(temp <= 10) {
            return 1
        }

        if(temp <= 100) {
            return 10
        }

        if(temp <= 1000) {
            return 100
        }

        return 1000
    }, [chartData])

    useEffect(() => {
        if(chartData) {
            const {
                xAxisLabel, 
                yAxisLabel, 
                fontColor 
            } = chartData

            chartData.data.datasets = chartData.data.datasets.map(dataset => ({
                ...dataset,
                borderColor: '#ddd',
                backgroundColor: dataset.backgroundColor || 'rgba(255, 255, 255, 0.1)',
                borderWidth: 1,
                pointBackgroundColor: dataset.pointBackgroundColor || '#fff',
                pointBorderColor: '#fff',
                pointRadius: 7,
                pointHoverRadius: 9
            }))

            setData(chartData.data)

            setOptions({
                title: {
                    display: false
                },
                tooltips: {
                    backgroundColor: '#427C56',
                    bodyFontFamily: 'Ubuntu, Calibri, Arial, sans-serif',
                    bodyFontSize: 15,
                    xPadding: 12,
                    yPadding: 10,
                    displayColors: false,
                    callbacks: {
                        title: () => '',
                        label: (tooltipItem, d) => {
                            const count = d.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                            switch(count) {
                                case 0: 
                                    return d.datasets[tooltipItem.datasetIndex].tooltipLabelZero || `Nenhum(a) ${d.datasets[tooltipItem.datasetIndex].label}`
                                case 1:
                                    return `${count} ${d.datasets[tooltipItem.datasetIndex].tooltipLabelSingular || d.datasets[tooltipItem.datasetIndex].label}`
                                default: 
                                    return `${count} ${d.datasets[tooltipItem.datasetIndex].tooltipLabelPlural || d.datasets[tooltipItem.datasetIndex].label}`
                            }
                        }
                    }
                },
                legend: {
                    display: true,
                    align: 'end',
                    labels: {
                        fontColor: fontColor || '#fff',
                        usePointStyle: true
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontColor: fontColor || '#fff',
                            stepSize: detectYAxisSteps(),
                            beginAtZero: true
                        },
                        scaleLabel: {   
                            display: true,
                            labelString: xAxisLabel || '',
                            fontColor: '#fff',
                            fontSize: 13
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontColor: fontColor || '#fff'
                        },
                        scaleLabel: {
                            display: true,
                            labelString: yAxisLabel || '',
                            fontColor: fontColor || '#fff',
                            fontSize: 13
                        },
                        position: 'bottom'
                    }]
                }
            })
        }
    }, [chartData])

    return (
        <>
            {chartData && data?.datasets[0].data.length && options ? (
                <Line 
                    data={data}
                    options={options}
                />
            ) : (
                <ChartPlaceholder>
                    <FaEyeSlash size={36} />
                    <span>Nenhum dado referente ao mês selecionado</span>
                </ChartPlaceholder>
            )}

            <Tip>
                <MdScreenRotation size={14} />
                <span>Gire o dispositivo para melhor visualização</span>
            </Tip>
        </>
    )
}
