import styled from 'styled-components'

export const Container = styled.article`
	min-height: 80vh;
	padding: 34px 50px 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	> p {
		font-size: 18px;
		padding: 35px 0;
	}

	@media (max-width: 800px) {
		padding: 20px;
	}
`

export const InterestProduct = styled.section.attrs(() => ({
    className: 'animated faster zoomIn'
}))`
	background: #ffeb3b;
	padding: 20px;
	margin-bottom: 10px;
	width: 50%;
	border-radius: 10px;
	display: grid;
	grid-template-columns: 20px auto 20px;

	svg {
		cursor: pointer;
		transition: 0.3s;
		color: #424242;

		&:hover {
			opacity: 0.8;
		}
	}

	div {
		h1{
			font-size: 18px;
			margin-bottom: 15px;
			color: #424242;
			font-weight: 700;
			text-align: center;
		}

		p {
			font-size: 16px;
			color: #424242;
			text-align: center;
			font-weight: 400;
		}

		button {
			margin: 15px 0 0 0 !important;
			width: 100%;
		}
	}

	@media(max-width: 950px) {
		width: 100%;
	}
`

export const ProductLinks = styled.section`
	margin-bottom: 20px;

	a {
		margin: 0 !important;
		height: 60px !important;
	}
`
