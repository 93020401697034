import React, {
    useEffect, useRef, useState, useCallback 
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import Spinner from '~/components/Spinner'

import { Container, FormContainer, SpinnerContainer } from './styles'

import {
    Button, Textbox, Switch, Select 
} from '~/components/Form'
import CreditCard from '~/components/CreditCard'

import api from '~/services/api'
import yup from '~/services/yup'

import useEventListener from '~/hooks/useEventListener'

import masks from '~/util/masks'
import regex from '~/util/regex'
import inputUtils from '~/util/input'

import authHeaders from '~/util/authHeaders'

import mastercard from '~/assets/img/payment/mastercard.png'
import visa from '~/assets/img/payment/visa.png'
import diners from '~/assets/img/payment/diners.png'
import elo from '~/assets/img/payment/elo.png'
import amex from '~/assets/img/payment/amex.png'

const { Iugu } = window

const brandList = {
    mastercard,
    visa,
    diners,
    elo,
    amex
}

const validation = yup.object().shape({
    parcels: yup.object({
        value: yup.number().required(),
        label: yup.string().required()
    }).required('Selecione a quantidade de parcelas.'),
    name: yup.string().required('Campo obrigatório'),
    cardNumber: yup.string().required('Campo obrigatório').creditCard(),
    date: yup.string().required('Campo obrigatório').shortDate('A data deve estar no formato MM/AA.').future(),
    code: yup.string().required('Campo obrigatório').matches(regex.cvv, 'O CVV tem 3 dígitos.'),
    value: yup.string().required('Campo obrigatório').money('Valor inválido'),
    email: yup.string().email('E-mail inválido').required('Campo obrigatório')
})

const initialTestValues = {
    name: 'NOME TITULAR',
    cardNumber: '5555 5555 5555 4444',
    date: '11/22',
    code: '123',
    parcels: { value: '1', label: '1x (à vista)' },
    email: 'felipe@robot.rio.br',
    value: 'R$ 10,00'
}

const initialProdValues = {
    name: '',
    cardNumber: '',
    date: '',
    code: '',
    parcels: { value: '1', label: '1x (à vista)' },
    email: '',
    value: ''
}

export default function Payment() {
    const formRef = useRef()
	
    const [brand, setBrand] = useState(null)
    const [testMode, setTestMode] = useState(true)
    const [initialValues, setInitialValues] = useState(initialTestValues)
	
    const defineBrand = useCallback(e => {
        const cardNumber = e.target?.value?.replace(/ /g, '')

        if(!cardNumber) return

        const brandName = Iugu.utils.getBrandByCreditCardNumber(cardNumber)

        setBrand(brandList[brandName])
    }, [])

    const onChangeParcels = useCallback((selected, setFieldValue) => {
        setFieldValue('parcels', selected)
    }, [])
    
    const handleModeChange = useCallback(e => {
        const inTestMode = e.target.checked

        setTestMode(inTestMode)

        if(inTestMode) {
            setInitialValues(initialTestValues)
            toast.info('Modo de TESTE ativado.')
        } else {
            setInitialValues(initialProdValues)
            toast.error('Modo de PRODUÇÃO ativado.')
        }
    }, [])
	
    const handleSubmit = useCallback((data, { setSubmitting, resetForm }) => {
        const tokenResponseHandler = function (dataIugu) {
            if (dataIugu.errors) {
                for(let i = 0; i < dataIugu.errors.length; i++) {
                    if(dataIugu.errors[i] === 'last_name') { 
                        dataIugu.errors[i] = 'full_name' 
                    }

                    console.log(`Erro no campo ${dataIugu.errors[i]}`)
                }

                setSubmitting(false)
            } else {
                const paymentData = {
                    token: dataIugu.id,
                    parcels: parseInt(data.parcels.value),
                    email: data.email,
                    quantity: 1,
                    product_id: 1,
                    person_id: 1,
                    value: parseFloat(data.value.replace('R$ ', '').replace(',', '.')),
                    test_mode: testMode
                }

                const pay = async function () {
                    const response = await api.post('payments', paymentData, authHeaders)

                    if(response.invoice) {
                        toast.success(response.msg)
                        resetForm(initialProdValues)
                    } else if(response.error) {
                        toast.error(response.msg)
                    }

                    setSubmitting(false)
                }

                pay()
            }
        }
		
        setTimeout(() => {
            try {
                Iugu.createPaymentToken(formRef.current, tokenResponseHandler)
            } catch(err) {
                toast.error('Ocorreu um erro no pagamento.')
            }
        }, 700)
    }, [testMode])

    useEffect(() => {
        Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
        Iugu.setTestMode(testMode)
    }, [testMode])
	
    useEventListener('keyup', defineBrand, document.getElementsByName('cardNumber')[0])
	
    return (
        <Container>
            <Switch 
                withoutForm
                id="test-mode-toggle" 
                label="Modo teste"
                onChange={handleModeChange}
                checked={testMode}
            />
                        
            <Formik 
                onSubmit={handleSubmit} 
                validationSchema={validation} 
                initialValues={initialValues} 
                enableReinitialize
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form ref={formRef}>
                        <CreditCard 
                            name={values.name}
                            number={values.cardNumber}
                            expiration={values.date}
                            cvv={values.code}
                            brand={brand}
                        />

                        <FormContainer>
                            <Textbox 
                                name="name" 
                                label="Nome como consta no cartão*"
                                style={{ textTransform: 'uppercase' }}
                                data-iugu="full_name"
                            />

                            <Textbox 
                                name="cardNumber" 
                                label="Número do cartão*"
                                mask={masks.cardNumber}
                                data-iugu="number"
                            />

                            <Textbox 
                                name="date" 
                                label="Vencimento*"
                                mask={masks.shortDate}
                                data-iugu="expiration"
                            />

                            <Textbox 
                                name="code" 
                                label="CVV*"
                                mask={masks.cvv}
                                data-iugu="verification_value"
                            />

                            <Textbox 
                                name="value" 
                                label="Valor (R$)"
                                mask={masks.money}
                                onBlur={e => setFieldValue(e.target.name, inputUtils.fillMoney(e))}
                            />

                            <Select 
                                name="parcels"
                                onChange={selected => onChangeParcels(selected, setFieldValue)}
                                options={[
                                    { value: 1, label: '1x (à vista)' },
                                    { value: 2, label: '2x' },
                                    { value: 3, label: '3x' }
                                ]}
                            />

                            <Textbox 
                                name="email" 
                                label="E-mail para recebimento da fatura*"
                                style={{ textTransform: 'lowercase' }}
                            />

                            {isSubmitting ? (
                                <SpinnerContainer>
                                    <Spinner />
                                </SpinnerContainer>
                            ) : (
                                <Button type="submit" className="green" disabled={isSubmitting}>
                                    <span>Pagar</span>
                                </Button>
                            )}

                            <Button 
                                type="button" 
                                className="transparent" 
                                onClick={() => window.open('/payment', 'Robot Pagamento', 'width=350,height=630')}
                            >
								Visualizar pop-up
                            </Button>
								
                        </FormContainer>

                    </Form>
                )}
            </Formik>

        </Container>
    )
}
