import React, { useMemo } from 'react'

import NavOptions from '~/components/NavOptions'
import PeopleManager from '~/components/PeopleManager'

import { Container } from './styles'

export default function () {
    const user = useMemo(() => JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER)), [])
	
    return (
        <Container>
			
            <NavOptions type="admin" />

            <main>
                <PeopleManager user={user} limit={30} />
            </main>
			
        </Container>
    )
}
