import React, { useCallback } from 'react'

import { Container } from './styles'

export default function ({ history, toggleModal }) {
    const handleClick = useCallback(url => {
        history.push(url)
        
        toggleModal()
    }, [history])

    return (
        <Container>
            <div role="button" tabIndex={0} onClick={() => handleClick('/product/1')} className="animated bounceIn delay-200ms">
                <div 
                    className="image-container" 
                    style={{ backgroundImage: `url(${`${process.env.REACT_APP_BACKEND_URL}/files/cea75e48a8b551a1614a5a08a7f7a55c.png`})` }} 
                />
                <legend>Chatbot Inteligente</legend>
            </div>

            <div role="button" tabIndex={0} onClick={() => handleClick('/product/2')} className="animated bounceIn delay-300ms">
                <div 
                    className="image-container" 
                    style={{ backgroundImage: `url(${`${process.env.REACT_APP_BACKEND_URL}/files/417ef358862cbce0eb385e609d3e93bc.png`})` }} 
                />
                <legend>Mensageria</legend>
            </div>
        </Container>
    )
}
