import React, {
    useEffect, useState, useCallback 
} from 'react'
import {
    format, getMonth, getDate, setMonth, startOfMonth, endOfMonth, addDays 
} from 'date-fns'

import api from '~/services/api'

import PeopleManager from '~/components/PeopleManager'
import SMSBox from '~/components/SMSBox'
import MessageTemplatesRules from '~/components/MessageTemplatesRules'
import LineChart from '~/components/LineChart'
import { Select } from '~/components/Form'
import Spinner from '~/components/Spinner'

import dateUtils from '~/util/date'
import authHeaders from '~/util/authHeaders'

import { Container, PlaceholderChart } from './styles'

export default function DashboardMensageria({ location }) {
    const { user, contract } = location.state
    const currentMonth = getMonth(new Date())

    const [chartLoaded, setChartLoaded] = useState(false)
    const [chartData, setChartData] = useState(null)
    const [chartStart, setChartStart] = useState(null)
    const [chartEnd, setChartEnd] = useState(null)
    const [statisticsMonth, setStatisticsMonth] = useState({ 
        value: currentMonth, 
        label: dateUtils.months.find(month => parseInt(month.value) === currentMonth + 1).label 
    })

    useEffect(() => {
        const date = new Date()
        const firstDate = startOfMonth(date)
        const lastDate = endOfMonth(date)

        setChartStart(firstDate)
        setChartEnd(lastDate)
    }, [])

    useEffect(() => {
        async function loadChartData() {
            if(!chartStart || !chartEnd) {
                setChartData(null)
                return
            }

            const smsStatistics = await api.get('sms_statistics', {
                ...authHeaders,
                params: { 
                    start: format(chartStart, 'yyyy-MM-dd'),
                    end: format(chartEnd, 'yyyy-MM-dd')
                }
            })

            const emailStatistics = await api.get('email_statistics', {
                ...authHeaders,
                params: { 
                    start: format(chartStart, 'yyyy-MM-dd'),
                    end: format(chartEnd, 'yyyy-MM-dd')
                }
            })

            const smsLabels = Object.keys(smsStatistics).map(resp => {
                const date = new Date(resp)
                return getDate(addDays(date, 1))
            })

            const emailLabels = Object.keys(emailStatistics).map(resp => {
                const date = new Date(resp)
                return getDate(addDays(date, 1))
            })

            const data = {
                xAxisLabel: 'Mensagens enviadas',
                data: {
                    labels: [...new Set([...smsLabels, ...emailLabels])],
                    datasets: [
                        {
                            label: 'SMS',
                            data: Object.values(smsStatistics),
                            backgroundColor: 'rgba(255, 215, 0, 0.5)',
                            tooltipLabelZero: 'Nenhum SMS enviado',
                            tooltipLabelSingular: 'SMS enviado',
                            tooltipLabelPlural: 'SMSs enviados',
                            pointBackgroundColor: 'gold'
                        },
                        {
                            label: 'E-mail',
                            data: Object.values(emailStatistics),
                            backgroundColor: 'rgba(30, 144, 255, 0.3)',
                            tooltipLabelZero: 'Nenhum e-mail enviado',
                            tooltipLabelSingular: 'e-mail enviado',
                            tooltipLabelPlural: 'e-mails enviados',
                            pointBackgroundColor: 'rgb(30, 144, 255)'
                        }
                    ]
                }
            }

            setChartData(data)

            setChartLoaded(true)
        }

        loadChartData()
    }, [chartStart, chartEnd])
    
    const onChangeMonth = useCallback(selected => {
        const month = parseInt(selected.value - 1)

        const date = new Date()
        const firstDate = setMonth(startOfMonth(date), month)
        const lastDate = addDays(setMonth(endOfMonth(date), month), 1)

        setChartLoaded(false)
        setStatisticsMonth(selected)
        setChartStart(firstDate)
        setChartEnd(lastDate)
    }, [])

    return (
        <Container>
            <div className="col-1">
                <PeopleManager user={user} />

                <SMSBox className="animated fadeIn delay-300ms sms-box" /> 

                <section className="animated fadeIn delay-400ms">
                    <h1>Estatísticas de uso</h1>

                    <Select 
                        withoutForm 
                        style={{ maxWidth: 200 }} 
                        onChange={onChangeMonth}
                        value={statisticsMonth}
                        options={dateUtils.months}
                    />

                    {chartLoaded ? (
                        <LineChart chartData={chartData} />
                    ) : (
                        <PlaceholderChart>
                            <Spinner visible />
                        </PlaceholderChart>
                    )}
                </section>
            </div>

            <div className="col-2">
                {user.profile.id === 2 ? ( // Is not admin user
                    <MessageTemplatesRules user={user} contract={contract} />
                ) : (
                    <MessageTemplatesRules user={user} />
                )}
            </div>

        </Container>
    )
}
