import styled from 'styled-components'

export const Container = styled.div`
	margin-top: calc(50vh - 260px);
	margin-bottom: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
`

export const Content = styled.div`
	width: 315px;
	padding: 0 15px;
	
	img {
		width: 100px;
	}

	form {
		display: flex;
		flex-direction: column;
		margin-top: 30px;
		margin-bottom: 20px;

		input {
			width: 100%;
		}

		button {
			margin: 0 !important;
			width: 100%;
		}
	}
`

export const LoginLinkContainer = styled.div`
	display: flex;
	justify-content: center;

	a {
		color: #fff;
		font-size: 14px;
		opacity: 0.7;
		transition: all 0.3s;

		&:hover {
			opacity: 1;
			color: #fff;
		}
	}
`

export const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
`
