import React from 'react'
import { useField } from 'formik'

import Option from './Option'
import Chevron from './Chevron'

import { Select, SelectContainer } from './styles'

const selectStyle = {
    input: (styles) => ({
        ...styles,
        color: '#fff'
    }),
    control: (styles, { isDisabled }) => ({ 
        ...styles, 
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: 0,
        boxShadow: 'none',
        height: 44,
        cursor: 'pointer',
        opacity: isDisabled ? 0.6 : 1,
        ':focus-within': {
            border: 'dashed 1px rgba(255, 255, 255, 0.4)'
        }
    }),
    singleValue: (styles) => ({
        ...styles,
        color: '#fff'
    }),
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: '#FFF',
        borderRadius: 15,
        color: '#424242',
        padding: '4px 0'
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }),
    clearIndicator: () => ({
        display: 'none'
    }),
    placeholder: (styles) => ({
        ...styles,
        color: 'rgba(255, 255, 255, 0.6)'
    })
}

export default function ({
    withoutForm, label, options, style, isDisabled, containerClass, className, ...props
}) {
    let field
    let meta

    if(!withoutForm) {
        [field, meta] = useField(props)
    }

    if(withoutForm) {
        return (
            <Select 
                options={options} 
                className={`select ${className || ''}`}
                noOptionsMessage="Nenhum item disponível"
                menuPortalTarget={document.querySelector('body')}
                components={{
                    Option, 
                    IndicatorSeparator: null, 
                    DropdownIndicator: Chevron 
                }}
                styles={selectStyle}
                style={style}
                placeholder={label || 'Selecione...'}
                isDisabled={isDisabled || !options?.length}
                {...field} 
                {...props} 
            />
        )
    }

    return (
        <SelectContainer className={containerClass}>
            <Select 
                options={options} 
                className={`select ${className || ''}`}
                menuPortalTarget={document.querySelector('body')}
                components={{
                    Option, 
                    IndicatorSeparator: null, 
                    DropdownIndicator: Chevron 
                }}
                styles={selectStyle}
                style={style}
                placeholder={label || 'Selecione...'}
                isDisabled={isDisabled || !options?.length}
                {...field} 
                {...props} 
            />

            {meta.touched && meta.error ? (
                <span className="error">{meta.error}</span>
            ) : null}
        </SelectContainer>
    )
}
