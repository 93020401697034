import React, { useState, useEffect, forwardRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

import api from '~/services/api'

import authHeaders from '~/util/authHeaders'

import { Container } from './styles'

function File({
    getPreloadImage,
    placeholderText = 'Selecione um arquivo',
    onSuccess = () => toast.success('Upload concluído.'), 
    onError = () => toast.error('Ocorreu um erro no upload do arquivo.'), 
    accept = ['image/*'], 
    maxSize = 2000000,
    areaSize = 140,
    format = 'circle'
}, ref) {
    const [preview, setPreview] = useState('unset')

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept,
        maxSize, 
        multiple: false,
        onDropRejected: onError,
        onDrop
    })

    useEffect(() => {
        async function loadPreviewImage() {
            const imageUrl = await getPreloadImage()

            if(imageUrl) {
                setPreview(`url(${process.env.REACT_APP_BACKEND_URL}/files/${imageUrl})`)
            }
        }

        if(getPreloadImage) {
            loadPreviewImage()
        }
    }, [])

    async function onDrop(acceptedFiles) {
        const acceptedFile = acceptedFiles[0]

        if(!acceptedFile) return

        const reader = new FileReader()

        reader.readAsDataURL(acceptedFile)

        const data = new FormData()

        data.append('file', acceptedFile, acceptedFile.name)

        const uploadedFile = await api.post('files', data, authHeaders)

        onSuccess(uploadedFile.id)

        setPreview(`url(${URL.createObjectURL(acceptedFile)})`)

        URL.revokeObjectURL(preview)
    }

    return (
        <Container areaSize={areaSize} format={format} ref={ref}>

            <div {...getRootProps({ 
                className: 'file-select-area', 
                style: { 
                    backgroundImage: preview
                }
            })}
            >
                <input {...getInputProps()} />
                <p className={preview !== 'unset' ? 'with-preview' : ''}>{placeholderText}</p>
            </div>

        </Container>
    )
}

export default forwardRef(File)
