import styled from 'styled-components'

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	section {
		background: #1D3B3B;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		border-radius: 2px;
		padding: 35px;
		display: flex;
		align-items: center;
		flex-direction: column;
		width: calc(100% - 100px);
		margin: 30px 50px 40px;

		h1 {
			width: 100%;
			font-size: 22px !important;
			margin-bottom: 18px;
		}
	}
`
