import styled from 'styled-components'

const messageColors = {
    default: '#999',
    error: '#f44336',
    success: '#4caf50'
}

export const Container = styled.div`
	background: #eee;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 16px;
	color: #bbb;
	font-weight: 500;
	border: dashed 1px #ddd;
	border-radius: 6px;
	cursor: pointer;
	transition: all 0.3s;
	padding: 20px;

	&:hover {
		background: rgba(0, 0, 0, 0.03);
		padding: 40px 20px;
	}
`

export const UploadMessage = styled.div`
    display: flex;
    color: ${props => messageColors[props.type || 'default']};
    justify-content: center;
    align-items: center;
	flex-direction: column;
    padding: 15px 0;

	p:nth-child(n+2) {
		font-size: 12px;
	}
`
