import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Modal from '~/components/Modal'
import ProductGrid from '~/components/ProductGrid'

import { Navbar, MenuContainer } from './styles'

import logo from '~/assets/img/logo-extend.png'

export default function ({ history }) {
    const [modalOpen, setModalOpen] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleModal = useCallback(() => {
        if(modalOpen) {
            document.querySelector('.ReactModal__Content').classList.remove('fadeInUp')
            document.querySelector('.ReactModal__Content').classList.add('fadeOutDown')

            setTimeout(() => {
                const overlay = document.querySelector('.ReactModal__Overlay')
                
                if(overlay) {
                    overlay.classList.add('animated', 'fast', 'fadeOut')
                }
            }, 200)

            setTimeout(() => setModalOpen(!modalOpen), 600)
        } else {
            setModalOpen(!modalOpen)
        }
    }, [modalOpen])

    const toggleMenu = useCallback(() => {
        const menu = document.querySelector('.menu')
        const items = [...menu.querySelectorAll('aside > *')]

        document.body.style.overflow = menuOpen ? 'initial' : 'hidden'

        menu.classList.toggle('on', !menuOpen)
        menu.classList.toggle('slideInLeft', !menuOpen)
            
        items.forEach((option, i) => {
            option.classList.add('animated', 'fadeInLeft', 'faster', `delay-${(i + 1) * 200}ms`)
        })

        setMenuOpen(!menuOpen)
    }, [menuOpen])

    useEffect(() => {
        if(menuOpen) {
            const options = document.querySelector('.menu aside')
            options.addEventListener('click', toggleMenu)
        }
    }, [menuOpen])

    return (
        <>
            <Navbar>
                <img src={logo} alt="Home" />

                <MenuContainer className="menu animated faster">
                    <div className="menu-toggle" onClick={toggleMenu} role="button" tabIndex={0}>
                        <div className="one" />
                        <div className="two" />
                        <div className="three" />
                    </div>

                    <aside>
                        <Link to="/">
                            <span>Home</span>
                        </Link>

                        <button onClick={toggleModal}>
                            <span>Produtos</span>
                        </button>

                        <Link to="/magazine">
                            <span>Revista digital</span>
                        </Link>

                        <Link to="/signin">
                            <span>Área do cliente</span>
                        </Link>
                    </aside>
                </MenuContainer>
            </Navbar>

            <Modal isOpen={modalOpen} toggleModal={toggleModal}>
                <h1>Conheça nossos produtos</h1>
                
                <ProductGrid history={history} toggleModal={toggleModal} />
            </Modal>
        </>
    )
}
