import React, {
    useState, useCallback, useRef, useEffect 
} from 'react'
import { Formik, Form } from 'formik'
import {
    FaPlus, FaUser, FaPhone, FaEnvelope, FaRegSave 
} from 'react-icons/fa'
import { toast } from 'react-toastify'

import api from '~/services/api'
import yup from '~/services/yup'

import masks from '~/util/masks'
import authHeaders from '~/util/authHeaders'

import Spinner from '~/components/Spinner'
import Table from '~/components/Table'
import { Textbox, Button, Select } from '~/components/Form'
// import Accordion from '~/components/Accordion'

import { PeopleManager, PeopleFormActions, TablePlaceholder } from './styles'

const validation = yup.object({
    name: yup.string().required('Campo obrigatório'),
    phone: yup.string().required('Campo obrigatório.').mobile(),
    email: yup.string().email('E-mail inválido.').required('Campo obrigatório.'),
    type: yup.object({
        value: yup.string(),
        label: yup.string()
    }).required('Especifique o tipo de pessoa (física ou jurídica).').nullable()
})

const initialValues = {
    name: '', phone: '', email: '', type: null, group: null 
}

export default function ({ user, style, className }) {
    const [formAddPersonVisible, setFormAddPersonVisible] = useState(false)
    const [people, setPeople] = useState(null)
    // const [peopleGroups, setPeopleGroups] = useState([])
	
    const formAddPersonRef = useRef()
    
    useEffect(() => {
        async function loadPeople() {
            const data = await api.get('people', authHeaders)
    
            setPeople(data)
        }

        // async function loadPeopleGroups() {
        //     const data = await api.get('groups', authHeaders)
    
        //     const groups = data.map(group => ({ value: group.id, label: group.name }))

        //     setPeopleGroups(groups)
        // }

        loadPeople()
        // loadPeopleGroups()
    }, [])
	
    const handleStorePerson = useCallback(async (values, { resetForm }) => {
        const { value: type } = values.type
        const groups = values.group ? values.group.map(group => group.value) : []

        try {
            values.owners = [user.id]

            const person = await api.post('people', { ...values, type, groups }, authHeaders)

            setPeople(prev => [...prev, person])

            resetForm()

            toast.success(`${person.name} cadastrado(a) com sucesso!`)
        } catch(e) {
            toast.error(e.msg || 'Ocorreu um erro ao cadastrar a pessoa.')
        }
    }, [])
    
    const handleDeletePerson = useCallback(async (id) => {
        try {
            const response = await api.delete(`people/${id}`, authHeaders)

            setPeople(prev => prev.filter(person => person.id !== id))

            return response
        } catch(e) {
            return false
        }
    }, [])

    return (
        <PeopleManager 
            style={style} 
            className={`animated fadeIn ${className}`} 
            formVisible={formAddPersonVisible}
        >
            <h1>Gerenciamento de pessoas</h1>

            <Button 
                className="transparent" 
                style={{ display: formAddPersonVisible ? 'none' : 'block' }} 
                onClick={() => setFormAddPersonVisible(true)}
            >
                <span>Novo cadastro</span>
                <FaPlus size={14} />
            </Button>

            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleStorePerson}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form 
                        ref={formAddPersonRef} 
                        className="animated zoomIn faster"
                    >
                        <Textbox label="Nome" name="name" icon={{ Icon: FaUser, props: { size: 14 } }} />

                        <Textbox label="Número para contato" name="phone" mask={masks.mobile} icon={{ Icon: FaPhone, props: { size: 14 } }} />

                        <Textbox label="E-mail" name="email" icon={{ Icon: FaEnvelope, props: { size: 14 } }} />

                        <Select 
                            name="type"
                            label="PF ou PJ?"
                            onChange={selected => setFieldValue('type', selected)}
                            options={[
                                { value: 'PF', label: 'PF' },
                                { value: 'PJ', label: 'PJ' }
                            ]}
                        />

                        {/* <Select 
                            name="group"
                            label="Selecione um grupo..."
                            isMulti
                            onChange={selected => setFieldValue('group', selected)}
                            options={peopleGroups}
                        /> */}

                        <PeopleFormActions>
                            <Button onClick={() => setFormAddPersonVisible(false)} className="transparent" type="button">Fechar</Button>

                            <Button type="submit" className="green" disabled={isSubmitting}>
                                <span>Salvar</span>
                                <FaRegSave size={14} />
                            </Button>
                        </PeopleFormActions>
                    </Form>
                )}
            </Formik>

            {!people ? (
                <TablePlaceholder>
                    <Spinner visible />
                </TablePlaceholder>
            ) : (
                <Table 
                    headers={[
                        { name: 'name', value: 'Nome' }, 
                        { name: 'email', value: 'E-mail' },
                        { name: 'phone', value: 'Fone' }
                    ]}
                    data={people}
                    limit={5}
                    filterable
                    copiable
                    handleDelete={handleDeletePerson}
                    className="animated fadeIn"
                />
            )}
            

            {/* <Accordion 
                tabs={[
                    {
                        title: 'Visão individual',
                        content: !people ? (
                            <TablePlaceholder>
                                <Spinner visible />
                            </TablePlaceholder>
                        ) : (
                            <Table 
                                headers={[
                                    { name: 'name', value: 'Nome' }, 
                                    { name: 'email', value: 'E-mail' },
                                    { name: 'phone', value: 'Fone' }
                                ]}
                                showId
                                data={people}
                                limit={5}
                                filterable
                                copiable
                                handleDelete={handleDeletePerson}
                                className="animated fadeIn"
                            />
                        )
                    },
                    {
                        title: 'Visão em grupo',
                        component: (<h1>GRUPOS</h1>)
                    }
                ]}
            /> */}
		
        </PeopleManager>
    )
}
