import styled, { keyframes } from 'styled-components'

const flipRight = keyframes`
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(-180deg);
	}
`

const flipLeft = keyframes`
	from {
		transform: rotateY(-180deg);
	}
	to {
		transform: rotateY(0deg);
	}
`

export const Container = styled.article`
	
`

export const EditionLabel = styled.div`
	margin-bottom: 0;
	
	.edition-label,
	.edition-date {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.edition-label {
		font-size: 21px;
		font-weight: 400;
	}

	.edition-date {
		font-size: 11px;
		font-weight: 200;
	}

	@media(max-width: 1100px) {
		margin-bottom: 20px;
	}
`

export const MagazineContainer = styled.div`
	display: flex;
	justify-content: space-between;

	> div:first-child,
	> div:last-child {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 56vh;
		padding: 30px 0;

		button {
			margin: 0;
		}
	}

	.scene {
		height: 90vh;
		width: calc(90vh * 6 / 4.3);
		margin: 40px 0 10px;
		perspective: 1100px;
	}

	.page {
		margin-left: calc((90vh * 6 / 4.3) / 2);
		width: 50%;
		height: 100%;
		transform-style: preserve-3d;
		cursor: pointer;
		position: absolute;
		transform-origin: left 0;
		animation-fill-mode: forwards;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	}

	.page__face {
		position: absolute;
		width: 100%;
		height: 100%;
		line-height: 260px;
		color: white;
		text-align: center;
		font-weight: bold;
		font-size: 40px;
		backface-visibility: hidden;
	}

	.page__face--front {
		background-size: 100% 100%;
		background-position: 50% 50%;
		transform: rotateY(0deg);
	}

	.page__face--back {
		background-size: 100% 100%;
		background-position: 50% 50%;
		transform: rotateY(180deg);
	}

	.page.is-flipped {
		animation: ${flipRight} 2s ease-out;
		animation-fill-mode: forwards;
	}

	.page.is-unflipped {
		animation: ${flipLeft} 2s ease-out;
	}

	button {
		border-radius: 50% !important;
		height: 50px !important;
		width: 50px !important;

		svg {
			margin: 0 !important;
		}

		&.exit {
			height: 70px !important;
			width: 70px !important;
		}
	}

	@media(max-width: 1100px) {
		display: none;
	}
`

export const SmallScreenPlaceholder = styled.div`
	width: 100%;
	justify-content: center;
	display: none;

	a {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px !important;
		height: fit-content !important;

		svg {
			margin: 0 !important;
		}
	}

	@media(max-width: 1100px) {
		display: flex;
	}
`
