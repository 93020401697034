import React, { useCallback, useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaBookOpen } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Form'

import {
    Container, MagazineContainer, SmallScreenPlaceholder, EditionLabel 
} from './styles'

import cover from './img/capa.png'
import pag1 from './img/pag1.png'
import pag2 from './img/pag2.png'
import pag3 from './img/pag3.png'
import pag4 from './img/pag4.png'
import pag5 from './img/pag5.png'
import pag6 from './img/pag6.png'
import pag7 from './img/pag7.png'
import pag8 from './img/pag8.png'

export default function () {
    const [fullScreen, setFullScreen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pages, setPages] = useState([
        { front: cover, back: pag1, zIndex: 5 }, 
        { front: pag2, back: pag3, zIndex: 4 },
        { front: pag4, back: pag5, zIndex: 3 },
        { front: pag6, back: pag7, zIndex: 2 },
        { front: pag8, back: cover, zIndex: 1 }
    ])

    const toggleFullScreen = useCallback(() => {
        const doc = document
        const docEl = doc.documentElement

        const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
        const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

        if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            requestFullScreen.call(docEl)
            window.scrollTo(0, 280)
            setFullScreen(true)
        } else {
            cancelFullScreen.call(doc)
            setFullScreen(false)
        }
    }, [])

    function flip() {
        pages.forEach((page, i) => { page.zIndex = (i + 1) < currentPage ? 0 : pages.length - i })
        setPages(pages)

        const page = document.querySelector(`.page:nth-child(${currentPage})`)
        page.classList.remove('is-unflipped')
        page.classList.add('is-flipped')

        setCurrentPage(currentPage + 1)
		
        if(!fullScreen) {
            toggleFullScreen()
        }
    }
	
    function unflip() {
        pages.forEach((page, i) => { page.zIndex = (i + 1) < currentPage - 1 ? 0 : pages.length - i })
        setPages(pages)

        const page = document.querySelector(`.page:nth-child(${currentPage - 1})`)
        page.classList.remove('is-flipped')
        page.classList.add('is-unflipped')

        setTimeout(() => {
            setCurrentPage(currentPage - 1)
        }, 720)
		
        if(!fullScreen) {
            toggleFullScreen()
        }
    }

    return (
        <Container>

            <EditionLabel>
                <span className="edition-label">1ª edição</span>
                <span className="edition-date">01/03/2020</span>
            </EditionLabel>

            <SmallScreenPlaceholder>
                <a href="https://backend.robot.rio.br/files/a6dd0e41cab3405b7c5340a443056ad5.pdf" target="_blank" rel="noopener noreferrer" className="button transparent">
                    <FaBookOpen size={50} />
                    <span>Fazer download</span>
                </a>
            </SmallScreenPlaceholder>
            
            <MagazineContainer>
                <div>
                    <div />

                    <Button onClick={unflip} className="transparent animated bounceInLeft" disabled={currentPage === 1}>
                        <FaChevronLeft size={20} />
                    </Button>
                </div>

                <div className="scene scene--page">
                    {pages.map(page => (
                        <div key={page.front} className="page animated slideInRight" style={{ zIndex: page.zIndex }}>
                            <div className="page__face page__face--front" style={{ backgroundImage: `url(${page.front})` }} />
                            <div className="page__face page__face--back" style={{ backgroundImage: `url(${page.back})` }} />
                        </div>
                    ))}
                </div>

                <div>
                    <div style={{ display: !fullScreen ? 'block' : 'none' }} />
                    <Button onClick={toggleFullScreen} className="transparent animated bounceIn exit" style={{ display: fullScreen ? 'block' : 'none' }}>
                        <FiX size={40} />
                    </Button>
					
                    <Button onClick={flip} className="transparent animated bounceInRight" disabled={currentPage === pages.length + 1}>
                        <FaChevronRight size={20} />
                    </Button>
                </div>
            </MagazineContainer>

        </Container>
    )
}
