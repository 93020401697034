export default {
    toTop: async () => {
        let scrolled

        do {
            try {
                await new Promise((resolve) => {
                    setTimeout(() => resolve(), 200)
                })
                    .then(() => {
                        document.querySelector('#root').scrollIntoView({ behavior: 'smooth' })
                    })
                
                scrolled = true
            } catch(e) { 
                scrolled = false
            }
        } while(!scrolled)
    },
	
    toElement: (selector) => {
        document.querySelector(selector).scrollIntoView({ behavior: 'smooth' })
    }
}
