import React from 'react'

import { Container, Bar, Progress } from './styles'

export default function ({
    progress = 0, backgroundColor = '#1D3B3B', foregroundColor = '#388D3C', labelColor = '#fff', borderColor = '#fff' 
}) {
    return (
        <Container>
            <Bar backgroundColor={backgroundColor} borderColor={borderColor}>
                <Progress style={{ width: `${progress}%` }} foregroundColor={foregroundColor} labelColor={labelColor} />
                <span className="progress-label">
                    {`${progress}%`}
                </span>
            </Bar>
        </Container>
    )
}
