import React, { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { FaCoins, FaTag } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { Chips } from 'primereact/chips'

import api from '~/services/api'
import yup from '~/services/yup'

import authHeaders from '~/util/authHeaders'
import scroll from '~/util/scroll'

import Table from '~/components/Table'
import Spinner from '~/components/Spinner'
import NavOptions from '~/components/NavOptions'

import {
    Textbox, Textarea, Button 
} from '~/components/Form'

import { Container, Replacement, FormActions } from './styles'

const validation = yup.object({
    name: yup.string().required('Campo obrigatório'),
    query: yup.string().required('Campo obrigatório.')
})

export default function Query() {
    const [queries, setQueries] = useState([])
    const [replacements, setReplacements] = useState([])
    const [updating, setUpdating] = useState(false)
    const [initialValues, setInitialValues] = useState({
        name: '', 
        query: ''
    })
	
    const loadQueries = useCallback(async () => {
        setQueries(false)

        const response = await api.get('queries', authHeaders)
		
        setQueries(response.map(query => ({ ...query, replacements: query.replacements ? query.replacements.join(', ') : query.replacements })))
		
        setReplacements([])
    }, [])

    const extractReplacements = useCallback(query => {
        const matches = query.matchAll(/ :[a-z0-9_]+( )?/g)
        const replacementItems = [...matches].map(([replacement]) => replacement)
		
        setReplacements(replacementItems)
    }, [])

    useEffect(() => {
        loadQueries()
    }, [])

    const handleStore = useCallback(async (values, { resetForm, setSubmitting }) => {
        try {
            await api.post('queries', Object.assign(values, { replacements }), authHeaders)
			
            resetForm()
            setSubmitting(false)
		
            toast.success('Consulta cadastrada com sucesso.')
			
            setSubmitting(false)
			
            loadQueries()
        } catch(e) {
            toast.error(e.msg)
        }
    }, [replacements])
    
    const handleNameChange = useCallback((value, setFieldValue) => {
        const replaced = value.replace(/ /, '_')

        setFieldValue('name', replaced)
    }, [])

    const handleQueryChange = useCallback((value, setFieldValue) => {
        setFieldValue('query', value)

        extractReplacements(value)
    }, [])
	
    const handleDeleteQuery = useCallback(async id => {
        try {
            const response = await api.delete(`queries/${id}`, authHeaders)

            setQueries(prev => prev.filter(query => query.id !== id))
			
            return response
        } catch(e) {
            return false
        }
    }, [])
	
    const handlePrepareEdit = useCallback(async id => {
        setUpdating(id)

        const { name, query } = await api.get(`queries/${id}`, authHeaders)

        extractReplacements(query)

        setInitialValues({ name, query })

        scroll.toTop()
    }, [])

    const handleUpdate = useCallback(async (values, { resetForm, setSubmitting }) => {
        try {
            await api.put(`queries/${updating}`, Object.assign(values, { replacements }), authHeaders)

            loadQueries()

            resetForm()
            setSubmitting(false)
            setUpdating(false)

            setInitialValues({
                name: '', query: ''
            })

            toast.success('Consulta atualizada.')
        } catch(e) {
            toast.error(e.msg)
        }
    }, [updating, replacements])
	
    const getReplacementLayout = useCallback(replacement => (
        <Replacement>
            {replacement}
        </Replacement>
    ), [])

    return (
        <Container>

            <NavOptions type="admin" />

            <section>
                <h1>Administração de consultas</h1>

                <Formik 
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={updating ? handleUpdate : handleStore}
                    enableReinitialize
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <Textbox 
                                label="Nome da consulta" 
                                name="name" 
                                onChange={e => handleNameChange(e.target.value, setFieldValue)}
                                disabled={isSubmitting}
                                icon={{ Icon: FaTag }} 
                            />

                            <Textarea 
                                label="Consulta SQL" 
                                name="query" 
                                onChange={e => handleQueryChange(e.target.value, setFieldValue)}
                                disabled={isSubmitting}
                                icon={{ Icon: FaCoins }}
                            />

                            {!!replacements.length && (
                                <Chips 
                                    value={replacements} 
                                    disabled
                                    allowDuplicate={false}
                                    itemTemplate={getReplacementLayout}
                                />
                            )}

                            <FormActions>
                                {updating && <span>{`Editando a consulta ${updating}`}</span>}

                                <Button type="submit" disabled={isSubmitting} className="transparent">
                                    {updating ? 'Atualizar' : 'Cadastrar'}
                                </Button>
                            </FormActions>
                        </Form>
                    )}
                </Formik>

                {!queries ? (
                    <Spinner />
                ) : (
                    <Table 
                        headers={[
                            { name: 'name', value: 'Identificador' }, 
                            { name: 'query', value: 'Consulta' },
                            { name: 'replacements', value: 'Parâmetros' }
                        ]}
                        showId
                        hideOffset={600}
                        hideOnSmallHeaders={['query', 'replacements']}
                        data={queries}
                        limit={15}
                        filterable
                        copiable
                        className="animated fadeIn"
                        handleDelete={handleDeleteQuery}
                        handlePrepareEdit={handlePrepareEdit}
                    />
                )}

            </section>
        </Container>
    )
}
