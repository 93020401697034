import styled from 'styled-components'

export const Container = styled.section`
	min-height: 70vh;
	padding: 50px 10%;

	@media (max-width: 600px) {
		padding: 50px 5%;
	}

	.p-accordion-header {
		a[role=tab] {
			background: transparent;
			border: 0;
			padding: 20px;
			transition: all 0.3s;
			font-size: 18px;
			font-weight: 400;
			color: #fff;

			.pi-caret-right {
				color: rgba(255, 255, 255, 0.5);
			}

			.pi-caret-down,
			.pi-caret-right {
				font-size: 14px;
			}

			span:first-child {
				margin-right: 12px;
			}

			&:hover {
				background: rgba(43, 62, 77, 0.3) !important;
				border: dashed 1px #fff !important;
				border-radius: 6px;
			}

			&:focus {
				border: dashed 1px #fff;
				box-shadow: none !important;
			}
		}

		&.p-highlight a {
			background: rgba(43, 62, 77, 0.5) !important;
			border-radius: 6px;
			padding: 20px;
			border: 0 !important;

			span:first-child {
				margin-right: 16px;
			}

			&:hover {
				background: rgba(43, 62, 77, 0.6) !important;
				border: none !important;
			}
		}

		&.p-disabled {
			a[role=tab] {
				color: rgba(255, 255, 255, 0.7);
				font-weight: 100;
			}
		}
	}

	.p-accordion-content {
		padding: 24px !important;
		border-radius: 0 0 4px 4px !important;
		background: rgba(44, 44, 44, 0.5) !important;
		border: none !important;
		color: #fff !important;
		display: flex;
		flex-direction: column;
		align-items: center;

		form {
			width: 100%;
			max-width: 800px;
			display: flex;
			align-items: center;
			flex-direction: column;

			> div {
				width: 100%;
				max-width: 350px;
				display: flex;
				justify-content: center;
			}

			.empty-question-list {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 30px;
				color: rgba(255, 255, 255, 0.4);

				p {
					font-style: italic;
					text-align: center;
				}
			}

			p {
				margin-top: 24px;
				width: 100%;
			}			
		}

		p {
			width: 100%;
		}

		iframe {
			width: 100%;
			height: 400px;
			border: 0;
			margin: 14px 0;
			box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

			& + div {
				display: flex;
				justify-content: center;
				
				button {
					width: 300px;
				}
			}
		}

		a.terms-download {
			display: none;
			margin-top: 20px;
			flex-direction: column;
			height: 120px;
			padding: 14px;
			width: 160px;

			svg {
				margin: 0 0 10px;
			}
		}

		.checkbox-container {
			padding: 20px 0;
			display: flex;
			align-items: center;

			.p-checkbox-box {
				.p-highlight {
					background-color: #fff;
				}

				&.p-focus {
					box-shadow: 0 0 0 1px #fff;
				}
			}

			.p-checkbox + span {
				margin-left: 8px;
			}
		}

		@media(max-width: 800px) {
			a.terms-download {
				display: flex;
			}

			iframe {
				display: none;
			}
		}

		/* #conclusion-button {
			margin-top: 40px !important;
		} */

		#conclusion-button:disabled {
			cursor: not-allowed !important;
		}

		#require-rotation {
			display: none;
			align-items: center;
			padding: 20px;
			flex-direction: column;

			span {
				margin-top: 20px;
			}
		}

		#form-questions {
			max-width: 800px;

			p { 
				margin-bottom: 20px;
				padding: 0 12px;
			}
		}

		@media (max-width: 600px) {
			#form-questions {
				display: none;
			}

			#require-rotation {
				display: flex;
			}
		}

		.question-container {
			display: flex;
			justify-content: space-around;
			align-items: center;
			width: 94%;

			> div {
				margin: 0 10px;
			}

			@media (max-width: 1196px) {
				width: 100%;
			}

			button {
				width: 40px;
				border-radius: 50%;

				&:hover {
					background: rgba(255, 0, 0, 0.4);

					svg {
						color: #fff !important;
					}
				}

				svg {
					margin-left: 0;
				}
			}
		}

		button {
			margin: 0 !important;
			float: right;
			width: fit-content;
		}

		> div > button {
			float: right;
			margin-top: 10px !important;
		}
	}

	div.g-recaptcha iframe {
		height: 76px;
		box-shadow: none;
	}
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 30px;

	h1 {
		font-size: 20px;
		padding: 0 20px;
		font-weight: 400;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;

		span {
			margin-right: 15px;
		}
	}
`

export const AccordionPlaceholder = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40vh;
`
