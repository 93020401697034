import React from 'react'
import Loader from 'react-loader-spinner'

export default function ({
    type, color, size, visible 
}) {
    // More types in: https://mhnpd.github.io/react-loader-spinner/?path=/story/loader--bars

    return (
        <Loader
            type={type || 'Bars'}
            color={color || '#6b9c9b'}
            height={size || 30}
            width={size || 30}
            visible={visible}
            className="spinner"
        />
    )
}
