import React from 'react'

import Carousel from '~/components/Carousel'

import chat from '~/assets/img/dashboard/chat.png'
import conversation from '~/assets/img/dashboard/conversation.png'
import learn from '~/assets/img/dashboard/learn.png'
import question from '~/assets/img/dashboard/question.png'

import { Container } from './styles'

const medias = [
    {
        type: 'image', title: 'Converse naturalmente.', url: conversation 
    },
    {
        type: 'image', title: 'Pergunte sobre seu negócio.', url: question 
    },
    {
        type: 'image', title: 'Ajude-o a aprender.', url: learn 
    },
    {
        type: 'image', title: 'Interaja com seu assistente.', url: chat 
    }
]

export default function ({ className }) {
    return (
        <Container className={className}>

            <h1>CONHECENDO SEU CHATBOT</h1>

            <Carousel items={medias} numVisible={1} timeout={2000} titleStyle={{ color: '#fff', fontSize: 16 }} />

        </Container>
    )
}
