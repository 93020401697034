import styled from 'styled-components'

export const Textarea = styled.textarea`
	background: rgba(255, 255, 255, 0.1);
	border: 0;
	border-radius: 4px;
	height: 44px;
	padding: 0 15px;
	color: #fff;
	margin: 0 0 10px;
	resize: vertical;
	min-height: 100px;
	padding: 15px;
	width: 100%;

	&::placeholder {
		color: rgba(255, 255, 255, 0.7);
	}

	&:focus {
		border: dashed 1px rgba(255, 255, 255, 0.4);
	}

	&:disabled {
		opacity: 0.4;
	}
`

export const TextareaContainer = styled.div`
	width: 100%;

	textarea:focus + svg {
		opacity: 0.4;
	}

	textarea:disabled {
		opacity: 0.4;
	}

	svg {
		position: absolute;
		width: 18px;
		opacity: 0.7;
		margin: 12px 0 0 -30px;
	}
`
