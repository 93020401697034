import React, { useMemo } from 'react'

import NavOptions from '~/components/NavOptions'
import MessageTemplatesRules from '~/components/MessageTemplatesRules'
import PeopleManager from '~/components/PeopleManager'
import SMSBox from '~/components/SMSBox'

import { Container } from './styles'

export default function () {
    const user = useMemo(() => JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER)), [])

    return (
        <Container>
			
            <NavOptions type="admin" />

            <div className="col-1">
                <MessageTemplatesRules user={user} />
            </div>

            <div className="col-2">
                <PeopleManager user={user} limit={30} />
                
                <SMSBox className="delay-300ms" />
            </div>
			
        </Container>
    )
}
