import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Content } from './styles'

import Header from '~/components/AuthHeader'
import Footer from '~/components/Footer'

// import useScript from '~/hooks/useScript'

export default function AuthLayout({ children, history }) {
    // useScript(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_CHAT_URL_DEV : process.env.REACT_APP_CHAT_URL}/assistantCustomizedFile?userId=1`)

    return (
        <Wrapper>
            <Content>
                <Header history={history} />

                {children}

                <Footer />
            </Content>
        </Wrapper>
    )
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired
}
