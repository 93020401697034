export default {
    months: [
        { value: 1, label: 'janeiro' },
        { value: 2, label: 'fevereiro' },
        { value: 3, label: 'março' },
        { value: 4, label: 'abril' },
        { value: 5, label: 'maio' },
        { value: 6, label: 'junho' },
        { value: 7, label: 'julho' },
        { value: 8, label: 'agosto' },
        { value: 9, label: 'setembro' },
        { value: 10, label: 'outubro' },
        { value: 11, label: 'novembro' },
        { value: 12, label: 'dezembro' }
    ],

    getDateFromString: dateString => {
        try {
            if(!dateString) {
                throw new Error()
            }
            
            const [day, month, year] = dateString.split('/')
            
            return new Date(`${month}/${day}/${year}`)
        } catch(e) {
            return ''
        }
    }
}
