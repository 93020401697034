import styled from 'styled-components'

export const Container = styled.div`
	margin-top: calc(50vh - 210px);
	margin-bottom: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
`

export const Content = styled.div`
	width: 315px;
	padding: 0 15px;
	text-align: center;

	img {
		width: 100px;
		min-height: 100px;
	}

	form {
		display: flex;
		flex-direction: column;
		margin-top: 30px;

		input {
			width: 100%;
		}

		button {
			margin: 0 !important;
		}

		p {
			color: #aaa;
			padding: 15px;
		}

		a {
			color: #fff;
			font-size: 14px;
			opacity: 0.8;

			&:hover {
				opacity: 1;
			}
		}
	}
`
