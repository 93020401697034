import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Button } from '~/components/Form'

import api from '~/services/api'

import masks from '~/util/masks'
import scroll from '~/util/scroll'

import {
    Features, Feature, Extra, DemoContainer, TextContainer, ImageContainer, PriceContainer, PriceGrid, PriceCard, CardHeader, CardContentContainer 
} from './styles'

import atrair from '~/assets/img/products/chatbot/atrair.png'
import estatisticas from '~/assets/img/products/chatbot/estatisticas.png'
import tempo from '~/assets/img/products/chatbot/time.png'
import atendimento24h from '~/assets/img/products/chatbot/24h.jpg'
import smartphone from '~/assets/img/products/chatbot/celular3d.png'

const categoryData = {
    features: [
        {
            image: atendimento24h,
            title: 'Atenda 24h',
            description: 'Esteja sempre disponível para seu cliente. Faça parte desta pequena parcela de negócios que "não dormem". Dê mais comodidade ao atendimento da sua empresa.'
        },
        {
            image: tempo,
            title: 'Ganhe Tempo',
            description: 'Seu tempo vale ouro! Trabalhamos para que você possa aproveitá-lo da melhor forma.'
        },
        {
            image: estatisticas,
            title: 'Gere Dados',
            description: 'Acompanhe os interesses dos seus clientes, ranqueie suas vendas, melhore seu atendimento. Com nossa ferramenta você enxergará onde seu atendimento precisa melhorar.'
        },
        {
            image: atrair,
            title: 'Fidelize',
            description: 'Satisfaça seu cliente com uma relação diferenciada e o conquiste definitivamente. Seu atendimento virtual pode ter a cara do seu negócio.'
        }
    ]
}

export default function ({ category, history }) {
    const [products, setProducts] = useState([])

    useEffect(() => {
        async function loadProducts() {
            if(category?.id) {
                const chatbots = await api.get(`products?category_id=${category?.id}`)

                for(let i = 0; i < chatbots.length; i++) {
                    const chatbot = chatbots[i]

                    const features = []

                    for(let j = 1; j <= 5; j++) {
                        const titleRegex = new RegExp(`${j} > .+\r`, 'g')
                        const itemRegex = new RegExp(`${j} - .+\r`, 'g')

                        const matchedTitle = chatbot.full_description.match(titleRegex).map(item => item.replace(/\d+ > /g, ''))[0]
                        const matchedItems = chatbot.full_description.match(itemRegex).map(item => item.replace(/\d+ - /g, ''))

                        features.push({
                            title: matchedTitle,
                            items: matchedItems
                        })
                    }

                    chatbots[i] = {
                        ...chatbot,
                        title: chatbot.name.split('(')[0].trim(),
                        subtitle: chatbot.name.match(/\(\w+\)/g)[0].match(/[A-Za-z0-9]+/g),
                        features
                    }
                }

                setProducts(chatbots)
            }
        }

        loadProducts()
    }, [category])

    const handleClick = useCallback(() => {
        scroll.toElement('.price-cards')
    }, [category])

    const handleLoginRedirect = useCallback(product => {
        sessionStorage.setItem('robot-product-interest-id', product.id)

        toast.info('Identifique-se para fazer a contratação.')

        history.push('/signin')
    }, [])

    return (
        <>
            <Features>
                {categoryData?.features?.map(feature => (
                    <Feature key={feature.title}>
                        <img src={feature.image} alt="" />
                        <div>
                            <h2>{feature.title}</h2>
                            <p>{feature.description}</p>
                        </div>
                    </Feature>
                ))}
            </Features>

            <Extra>
                <div className="vector-cut" />

                <div>
                    <DemoContainer>
                        <ImageContainer>
                            <img src={smartphone} alt="" className="animated fadeInLeft slow" />
                        </ImageContainer>
                    </DemoContainer>

                    <TextContainer>
                        <p>
                            A Robot cria um robô especializado em atendimento e vendas utilizando o DNA da Inteligência Artificial, com linguagem humana e natural capaz de conversar com seus clientes e aprender com eles. O chatbot é uma ferramenta de atendimento capaz de atualizar os mecanismos de suporte aumentando exponencialmente as vendas.
                        </p>
                    
                        <div className="button-container">
                            <Button onClick={handleClick} className="green">{category?.button_text}</Button>
                        </div>

                    </TextContainer>
                </div>

            </Extra>

            <PriceContainer>
                <div className="vector-cut-2" />

                <PriceGrid className="price-cards">

                    {products?.length > 0 && products.map((product, i) => (
                        <PriceCard key={product.id}>
                            <CardHeader color={i === 0 ? 'green' : 'yellow'} level={1}>
                                <h1>{product.title}</h1>
                                <span>{product.subtitle}</span>
                            </CardHeader>

                            <CardHeader color={i === 0 ? 'green' : 'yellow'} level={2}>
                                <h2>{masks.maskApply.currency(product.price)}</h2>
                            </CardHeader>

                            <CardHeader color={i === 0 ? 'green' : 'yellow'} level={3}>
                                <span>{`Ou em ${product.payment_times}x de ${masks.maskApply.currency(product.parcel_price)}`}</span>
                            </CardHeader>

                            <CardContentContainer color={i === 0 ? 'green' : 'yellow'}>
                                <ul>
                                    {product.features && product.features.map(feature => (
                                        <li key={feature.title}>
                                            {feature.title}
                                            <ul>
                                                {feature.items && feature.items.map(item => (
                                                    <li key={item}>{item}</li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>

                                <Button onClick={() => handleLoginRedirect(product)} className="transparent">
                                    FAÇA O SEU
                                </Button>
                            </CardContentContainer>

                        </PriceCard>
                    ))}
                    
                </PriceGrid>

            </PriceContainer>
        </>
    )
}
