import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '~/components/Form'

import { Container, Content, Profile } from './styles'

import logoRobot from '~/assets/img/header/logo-extend.png'

export default function ({ history, user }) {
    const handleLogout = useCallback(() => {
        const userData = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER))

        sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN)
        sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_USER)
        
        history.push({
            pathname: '/signin',
            state: { reload: userData.profile.id !== 499 }
        })
    }, [history])

    return (
        <Container>
            <Content>

                <div>
                    <Link to="/">
                        <img src={logoRobot} alt="Home" />
                    </Link>
                </div>

                <aside>
                    <Profile>
                        <div>
                            <strong>{user?.alias}</strong>

                            <Link to="/profile">
                                Meu perfil
                            </Link>

                            <Button onClick={handleLogout}>
                                Sair
                            </Button>
                        </div>

                        <Link to="/profile">
                            <img 
                                src={user?.avatar?.path ? `${process.env.REACT_APP_BACKEND_URL}/files/${user?.avatar?.path}` : `https://api.adorable.io/avatars/70/${Math.floor(Math.random() * 100)}.png`} 
                                alt={user?.alias}
                            />
                        </Link>

                    </Profile>
                </aside>

            </Content>
        </Container>
    )
}
