export default {
    extractDomain: url => {
        const matched = url.match(/^(http(s)?:\/\/)?\w+[a-z0-9:.-_]+\//i)

        if(matched.length) {
            return matched[0].replace(/\/$/, '')
        }

        return ''
    },
    
    moneyFormat: value => {
        if(!value) {
            return ''
        }

        switch(typeof value) {
            case 'string':
                return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`
            case 'number':
                return `R$ ${value.toFixed(2).replace('.', ',')}`
            default: throw new Error('Invalid value passed to function moneyFormat (in utils/string.js).')
        }
    }
}
