import React from 'react'

import CategoryGallery from './CategoryGallery'

import { Container } from './styles'

export default function Catalog({ history }) {
    return (
        <Container>
            <h1>Catálogo de produtos Robot</h1>

            <CategoryGallery history={history} />
        </Container>
    )
}
