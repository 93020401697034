import styled from 'styled-components'

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;

	button {
		width: 31.4%;
		margin: 0 !important;
	}
`

export const AvatarContainer = styled.div.attrs({
    className: 'animated zoomIn faster'
})`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 24px;
`

export const Container = styled.article`
	display: flex;
	justify-content: center;
	padding: 35px;

	section {
		width: 80%;
		background: #1D3B3B;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		border-radius: 2px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 35px;
		margin-bottom: 15px;
		min-height: 80vh;

		h1 {
			width: 100%;
			font-size: 22px;
			margin-bottom: 24px;
		}

		form {
			width: 100%;
			display: flex;
			flex-direction: column;
			
			h2 {
				grid-column: 1 / 4;
				font-size: 16px;
				min-height: 60px;
				display: flex;
				align-items: center;
			}

			div.field-container {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: auto auto auto;
				column-gap: 20px;
				justify-items: center;

				@media(max-width: 600px) {
					display: block;
				}
			}

			div.field-container:first-child {
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: auto auto auto auto;
			}

			${AvatarContainer} {
				grid-column: 3 / 4;
				grid-row: 1 / 5;
			}

			${ButtonContainer} {
				grid-column: 1 / 4;

				@media(max-width: 600px) {
					button {
						width: 100%;
					}
				}
			}

			@media(max-width: 800px) {
				div.field-container:first-child {
					grid-template-columns: 1fr 1fr;
					grid-template-rows: auto auto auto auto auto;
				}

				div.field-container {
					grid-template-columns: 1fr 1fr;
					grid-template-rows: auto auto auto auto;
				}

				${AvatarContainer} {
					grid-column: 1 / 3;
					grid-row: 1 / 2;

					.file-select-area {
						width: 160px;
						height: 160px;
					}
				}
			}

			@media(max-width: 500px) {
				${AvatarContainer} {
					.file-select-area {
						width: 130px;
						height: 130px;
					}
				}
			}
		}

		@media(max-width: 900px) {
			width: 100%;
		}
	}

	@media(max-width: 500px) {
		padding: 20px;
	}
`

export const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`
