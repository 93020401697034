import styled from 'styled-components'
import { darken } from 'polished'

export const Container = styled.div`
	grid-column: 1/7;
	background: #1D3B3B;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	height: 46px;
	margin-bottom: 15px;
	display: flex;
	width: 100%;
	padding: 0 35px;

	a {
		height: 100%;
		padding: 0 15px;
		transition: all 0.2s;
		color: #FFF;
		display: flex;
		align-items: center;

		svg {
			margin-right: 8px;
		}

		&:hover {
			background: ${darken(0.03, '#1D3B3B')};
			color: #FFF;
		}
	}

	@media(max-width: 915px) {
		padding: 0;
	}

	@media(max-width: 800px) {
		span {
			display: none;
		}

		svg {
			margin: 0 8px;
		}
	}
`
