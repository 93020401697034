import styled from 'styled-components'

import MaskedInput from 'react-text-mask'

export const InputContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;

	input:focus + svg {
		opacity: 0.4;
	}

	input:disabled {
		opacity: 0.4;
	}

	svg {
		position: absolute;
		right: 14px;
		top: 15px;
		width: 18px;
		opacity: 0.7;
	}

	input + span.error {
		width: 100%;
	}
`

export const Textbox = styled.input`
	background: rgba(255, 255, 255, 0.1);
	border: 0;
	border-radius: 4px;
	height: 44px;
	padding: 0 15px;
	color: #fff;
	margin-bottom: 10px;
	width: 100%;

	&::placeholder {
		color: rgba(255, 255, 255, 0.7);
	}

	&:focus {
		border: dashed 1px rgba(255, 255, 255, 0.4);
	}
`

export const MaskedTextbox = styled(MaskedInput)`
	background: rgba(255, 255, 255, 0.1);
	border: 0;
	border-radius: 4px;
	height: 44px;
	padding: 0 15px;
	color: #fff;
	margin-bottom: 10px;
	width: 100%;

	&::placeholder {
		color: rgba(255, 255, 255, 0.7);
	}

	&:focus {
		border: dashed 1px rgba(255, 255, 255, 0.4);
	}
`
