import React, { useEffect, useState, useCallback } from 'react'

import { Button } from '~/components/Form'

import api from '~/services/api'

import {
    Container, Header, TipContainer
} from './styles'

import ChatbotProfile from '~/components/Products/ChatbotProfile'
import MensageriaProfile from '~/components/Products/MensageriaProfile'
import IntegrareProfile from '~/components/Products/IntegrareProfile'

export default function Category({ match, history }) {
    const categoryId = parseInt(match.params.id)
	
    const [category, setCategory] = useState(null)
    const [showMessage, setShowMessage] = useState(false)
	
    useEffect(() => {
        async function loadCategories() {
            const response = await api.get(`categories/${categoryId}`)

            if(!response) {
                history.push('/')
            }
    
            setCategory(response)
        }

        loadCategories()
    }, [history, categoryId])

    const renderExtraInfo = useCallback(() => {
        switch(categoryId) {
            case 1:
                return <ChatbotProfile category={category} history={history} />
            case 2:
                return <MensageriaProfile category={category} history={history} />
            case 4:
                return <IntegrareProfile category={category} history={history} />
            default:
                return null
        }
    }, [category, categoryId, history])

    const handleChatbotButtonClick = useCallback(() => {
        document.querySelector('.price-cards').scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (
        <Container>
            <Header background={`${category?.image?.path && process.env.REACT_APP_BACKEND_URL}/files/${category?.image?.path}`}>
                <div className="animated fadeInRight">
                    <h1>
                        <span>{category?.title}</span>
                        <span>{category?.subtitle}</span>
                    </h1>
                    
                    <p>{category?.description}</p>

                    <div className="button-container">

                        {/* If Chatbot */}
                        {categoryId === 1 ? (
                            // <Link to="/signin" className="button transparent animated fadeIn delay-500ms">{category?.button_text}</Link>
                            <Button onClick={handleChatbotButtonClick} className="button transparent animated fadeIn delay-500ms">{category?.button_text}</Button>
                        ) : categoryId === 2 ? (
                            // If Mensageria
                            <TipContainer>
                                {!showMessage && (
                                    <Button onClick={() => setShowMessage(true)} className="transparent">{category?.button_text}</Button>
                                )}

                                <p className="tip animated bounceIn" style={{ display: showMessage ? 'block' : 'none' }}>
                                    Para contratar, envie um e-mail para nós: 
                                    {' '}
                                    <a href="mailto:contato@robot.rio.br">contato@robot.rio.br</a>
                                    . Teremos prazer em atendê-lo.
                                </p>
                            </TipContainer>
                        ) : (
                            // If Integrare
                            <TipContainer>
                                {!showMessage && (
                                    <Button onClick={() => setShowMessage(true)} className="transparent">{category?.button_text}</Button>
                                )}

                                <p className="tip animated bounceIn" style={{ display: showMessage ? 'block' : 'none' }}>
                                    Para contratar, envie um e-mail para nós: 
                                    {' '}
                                    <a href="mailto:contato@robot.rio.br">contato@robot.rio.br</a>
                                    . Teremos prazer em atendê-lo.
                                </p>
                            </TipContainer>
                        )}
                    </div>
                </div>
            </Header>
			
            {renderExtraInfo()}

        </Container>
    )
}
