Array.prototype.last = function () {
    return this[this.length - 1]
}

Array.prototype.first = function () {
    return this.length ? this[0] : undefined
}

function comparisonFunction(attrName, sortDirection = 'asc') {
    return (a, b) => {
        if(a[attrName] > b[attrName]) {
            return sortDirection === 'asc' ? 1 : -1
        }

        if(a[attrName] < b[attrName]) {
            return sortDirection === 'asc' ? -1 : 1
        }
            
        return 0
    }
}

export default {
    sort: {
        comparisonFunction
    },

    get: {
        getLast: (array, key) => {
            if(!array?.length) {
                return null
            }

            const sorted = array.sort(comparisonFunction(key, 'desc'))
            return sorted[0]
        }
    },

    merge: {
        mergeWithoutDuplicates: (original, newArray) => {
            const merged = [...original, ...newArray]
            return Array.from(new Set([...merged]))
        }
    }
}
