import React, {
    useState, useEffect 
} from 'react'
import { uniqueId } from 'lodash'
import { toast } from 'react-toastify'

import { Container, Content } from './styles'

import UploadZone from '~/components/UploadZone'
import FileList from '~/components/FileList'
import NavOptions from '~/components/NavOptions'

import api from '~/services/api'

import authHeaders from '~/util/authHeaders'

function Files() {
    const [uploadedFiles, setUploadedFiles] = useState([])
	
    useEffect(() => {
        async function loadFiles() {
            const response = await api.get('files', authHeaders)
    
            setUploadedFiles(response.map(file => ({
                id: file.id,
                name: file.name,
                preview: `${process.env.REACT_APP_BACKEND_URL}/files/${file.path}`,
                uploaded: true,
                url: file.path
            })))
        }

        loadFiles()

        return () => {
            uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview))
        }
    }, [])
	
    function updateFile(id, data) {
        setUploadedFiles(uploadedFiles.map(uploadedFile => id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile))
    }

    function processUpload(uploadedFile) {
        const data = new FormData()

        data.append('file', uploadedFile.file, uploadedFile.name)

        api.post('files', data, {
            ...authHeaders,
            onUploadProgress: e => {
                updateFile(uploadedFile.id, {
                    progress: parseInt(Math.round((e.loaded * 100) / e.total))
                })
            }
        })
            .then((response) => {
                updateFile(uploadedFile.id, {
                    uploaded: true,
                    id: response.id,
                    url: response.path
                })
            })
            .catch(() => {
                updateFile(uploadedFile.id, {
                    error: true
                })

                toast.error('Ocorreram erros no upload.')
            })
    }

    function handleUpload(files) {
        const uploadeds = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null
        }))

        setUploadedFiles(uploadedFiles.concat(uploadeds))

        uploadeds.forEach(processUpload)

        toast.success('Upload concluído!')
    }

    async function handleDelete(id) {
        try {
            await api.delete(`files/${id}`, authHeaders)

            toast.success('Arquivo deletado.')

            setUploadedFiles(uploadedFiles.filter(file => file.id !== id))
        } catch(e) {
            toast.error(e.msg)
        }
    }

    return (
        <Container>
            <NavOptions type="admin" />

            <Content>
                <UploadZone handleUpload={handleUpload} />
                
                { !!uploadedFiles.length && (
                    <FileList files={uploadedFiles} onDelete={handleDelete} />
                )}
            </Content>
        </Container>
    )
}

export default Files
