import React, { useState, useEffect, useCallback } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import {
    FaSortDown, FaSortUp, FaChevronDown, FaSearch, FaEyeSlash, FaTrash, FaEdit 
} from 'react-icons/fa'
import { toast } from 'react-toastify'

import { Textbox } from '~/components/Form'

import arrayUtils from '~/util/array'

import { TableContainer, Table, EmptyContainer } from './styles'

export default function (props) {
    const {
        headers, 
        showId, 
        data, 
        limit, 
        filterable, 
        copiable, 
        truncateLength, 
        hideOnSmallHeaders, 
        hideOffset, 
        className, 
        style, 
        handleDelete, 
        handlePrepareEdit,
        actions
    } = props

    let showReadMore = false

    const [visibleItems, setVisibleItems] = useState(data)
    const [sortDirection, setSortDirection] = useState('asc')
    const [limitData, setLimitData] = useState(limit)
    const [search, setSearch] = useState('')

    const sortData = useCallback(columnName => {
        setVisibleItems(data.sort(arrayUtils.sort.comparisonFunction(columnName, sortDirection)))

        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    }, [data, sortDirection])

    const expandData = useCallback(() => {
        setLimitData(data.length)
    }, [data.length])

    useEffect(() => {
        if(handleDelete && data.length && !data[0].id) {
            console.error('The table\'s data must have the id attribute.')
        }
    }, [])

    useEffect(() => {
        if(!search) {
            setVisibleItems(data)
        } else {
            let matchedItems = []
            const headerNames = headers.map(header => header.name)

            headerNames.forEach(headerName => {
                const filtered = data.filter(item => item[headerName].toString().toLowerCase().includes(search.toLowerCase()))
                matchedItems = arrayUtils.merge.mergeWithoutDuplicates(matchedItems, filtered)
            })
    
            setVisibleItems(matchedItems)
        }
    }, [search, headers, data])

    const handleDeleteItem = useCallback(async (id) => {
        const deleted = await handleDelete(id)

        if(typeof deleted !== 'boolean') {
            throw new Error('handleDelete function must return a boolean.')
        }

        if(deleted) {
            setVisibleItems(visibleItems.filter(item => item.id !== id))
            toast.success('O item foi excluído.')
        } else {
            toast.info('O item não foi excluído.')
        }
    }, [visibleItems])

    const handleEditItem = useCallback(async (id) => {
        await handlePrepareEdit(id)
    }, [visibleItems])

    return (
        <TableContainer className={className} style={style || {}}>
            {filterable && (
                <Textbox 
                    withoutForm 
                    label="Buscar..." 
                    icon={{ Icon: FaSearch }} 
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                />
            )}

            {visibleItems?.length ? (
                <Table cellSpacing="0" hideOffset={hideOffset}>
                    <thead>
                        <tr>
                            {showId && (
                                <th 
                                    onClick={() => sortData('id')}
                                    className={hideOnSmallHeaders?.includes('id') ? 'hide-on-small' : ''}
                                    style={{ width: 50 }}
                                >
                                    ID
                                    {sortDirection === 'asc' ? (
                                        <FaSortUp size={14} />
                                    ) : (
                                        <FaSortDown size={14} />
                                    )}
                                </th>
                            )}

                            {headers && headers.map((header, _, arr) => (
                                <th 
                                    key={Math.ceil(Math.random() * 10 ** 10)} 
                                    onClick={() => sortData(header.name)} 
                                    style={header.style}
                                    className={hideOnSmallHeaders?.includes(header.name) ? 'hide-on-small' : ''}
                                >
                                    {header.value}

                                    {sortDirection === 'asc' ? (
                                        <FaSortUp size={14} />
                                    ) : (
                                        <FaSortDown size={14} />
                                    )}
                                </th>
                            ))}

                            {handleDelete && (<th className="action-icon">{' '}</th>)}
                            
                            {handlePrepareEdit && (<th className="action-icon">{' '}</th>)}

                            {actions && actions.map(action => (
                                <th key={action.name} className="action-icon">{' '}</th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {visibleItems.map((item, index) => {
                            if(index >= limitData) {
                                showReadMore = true
                                return false
                            }

                            return (
                                <tr key={item.id} className={item.highlight ? 'animated flash' : ''}>
                                    {showId && <td>{item.id}</td>}

                                    {Object.entries(item).map(([name, value]) => {
                                        if(headers.map(header => header.name).includes(name)) {
                                            return (
                                                <td 
                                                    key={Math.ceil(Math.random() * 10 ** 10)} 
                                                    className={hideOnSmallHeaders?.includes(name) ? 'hide-on-small' : ''}
                                                    style={{ 
                                                        textAlign: typeof value === 'number' ? 'center' : 'start',
                                                        cursor: copiable ? 'pointer' : 'default'
                                                    }}
                                                >
                                                    {copiable ? (
                                                        <CopyToClipboard 
                                                            text={value}
                                                            onCopy={() => toast.info('Copiado!', { autoClose: 1100 })}
                                                        >
                                                            <span>
                                                                {
                                                                    !truncateLength 
                                                                        ? value 
                                                                        : truncateLength < value.length 
                                                                            ? `${value.substr(0, truncateLength)}...`
                                                                            : value
                                                                } 
                                                            </span>
                                                        </CopyToClipboard>
                                                    ) : (
                                                        <span>
                                                            {value} 
                                                        </span>
                                                    )}
                                                </td>
                                            )
                                        }

                                        return null
                                    })}

                                    {handleDelete && (
                                        <td className="delete-icon">
                                            <FaTrash size={12} onClick={() => handleDeleteItem(item.id)} />
                                        </td>
                                    )}

                                    {handlePrepareEdit && (
                                        <td className="edit-icon">
                                            <FaEdit size={12} onClick={() => handleEditItem(item.id)} />
                                        </td>
                                    )}

                                    {actions && actions.map(action => {
                                        if(!action.action) {
                                            throw new Error('The actions property from Table component should have the "action" attribute.')
                                        }
                                        
                                        return (
                                            <td key={action.name} className={`action-icon ${action.cellClasses || ''}`}>

                                                {React.createElement(action.icon, { 
                                                    size: action.iconSize || 16, 
                                                    onClick: !action.checkDisabled?.(item) && action.action ? () => action.action(item.id, action.name) : () => {},
                                                    style: { opacity: action.checkDisabled?.(item) ? 0.2 : 1 },
                                                    className: action.checkDisabled?.(item) ? 'disabled' : '',
                                                    'data-tip': action.name,
                                                    'data-for': !action.checkDisabled?.(item) ? 'table-tooltip' : ''
                                                }, null)}

                                            </td>
                                        )
                                    })}

                                </tr>
                            )
                        })}

                        {showReadMore && (
                            <tr className="read-more-line">
                                <td colSpan={Object.keys(data[0]).length} onClick={expandData}>
                                    <FaChevronDown size={18} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            ) : (
                <EmptyContainer>
                    <FaEyeSlash size={36} />
                    <span>Nenhum item encontrado</span>
                </EmptyContainer>
            )}

            <ReactTooltip
                id="table-tooltip"
                place="top"
                effect="solid"
                backgroundColor="#fff"
                arrowColor="#fff"
                textColor="#424242"
                className="tooltip-default"
            />

        </TableContainer>
    )
}
