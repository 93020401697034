import React from 'react'

import Magazine from '~/components/Magazine'

import { Container, Header } from './styles'

export default function () {
    return (
        <Container>
            <Header>
                <div>
                    <h1>Revista Digital</h1>
                    <h2>Conheça nossa revista digital, trazendo os melhores produtos para você.</h2>
                </div>
            </Header>

            <Magazine />
            
        </Container>
    )
}
