import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
    FaUser, FaEnvelope, FaLock, FaPhone 
} from 'react-icons/fa'

import { toast } from 'react-toastify'

import { Textbox, Button, Select } from '~/components/Form'

import {
    Container, Content, LoginLinkContainer, LogoContainer 
} from './styles'

import regex from '~/util/regex'
import masks from '~/util/masks'
import scroll from '~/util/scroll'

import api from '~/services/api'

import logo from '~/assets/img/logo.png'

const validation = Yup.object({
    name: Yup.string()
        .required('Nome obrigatório.'),
    email: Yup.string()
        .email('E-mail inválido.')
        .required('E-mail obrigatório.'),
    phone: Yup.string()
        .matches(regex.mobile, 'Celular inválido.')
        .notRequired(),
    password: Yup.string()
        .required('Senha obrigatória.')
        .min(6, 'A senha deve conter pelo menos 6 caracteres.'),
    'password-confirm': Yup.string()
        .required('Confirme a senha digitada.')
        .oneOf([Yup.ref('password')], 'As senhas não coincidem.'),
    type: Yup.object({
        value: Yup.string(),
        label: Yup.string()
    }).required('Especifique o tipo de pessoa (física ou jurídica).').nullable()
})

const initialValues = {
    name: '', 
    email: '', 
    phone: '', 
    password: '', 
    'password-confirm': '',
    type: null
}

export default function SignUp({ history }) {
    scroll.toTop()

    const [type, setType] = useState(null)

    async function handleSubmit(values, { setSubmitting, resetForm }) {
        try {
            await api.post('users', { ...values, type })

            resetForm()
            setSubmitting(false)

            toast.success('Usuário cadastrado!')

            history.push('/signin')
        } catch(e) {
            toast.error(e.msg)
        }
    }

    const onChangeType = useCallback((selected, setFieldValue) => {
        setFieldValue('type', selected)

        const { value } = selected
        
        setType(value)
    }, [])

    return (
        <Container>
            <Content className="animated fadeIn slow">

                <LogoContainer>
                    <img src={logo} alt="Robot Genesis" className="animated fadeIn" />
                </LogoContainer>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <Select 
                                name="type"
                                onChange={selected => onChangeType(selected, setFieldValue)}
                                options={[
                                    { value: 'PF', label: 'Pessoa Física' },
                                    { value: 'PJ', label: 'Pessoa Jurídica' }
                                ]}
                            />

                            {type === 'PJ' && <Textbox name="name" label="Nome fantasia" icon={{ Icon: FaUser }} />}
                                
                            {type === 'PF' && <Textbox name="name" label="Seu nome" icon={{ Icon: FaUser }} />}

                            <Textbox name="email" label="Seu e-mail" icon={{ Icon: FaEnvelope }} />

                            <Textbox name="phone" label="Seu celular" mask={masks.mobile} icon={{ Icon: FaPhone }} />

                            <Textbox name="password" type="password" label="Senha" icon={{ Icon: FaLock }} />

                            <Textbox name="password-confirm" type="password" label="Repetir senha" icon={{ Icon: FaLock }} />

                            <Button type="submit" disabled={isSubmitting} className="green">Criar conta</Button>
                        </Form>
                    )}
                </Formik>

                <LoginLinkContainer>
                    <Link to="/signin">Já tenho login</Link>
                </LoginLinkContainer>

            </Content>
        </Container>
    )
}
