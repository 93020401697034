import styled from 'styled-components'

export const Tip = styled.p`
	background: rgba(0, 0, 0, 0.1);
	padding: 8px;
	display: flex;
	align-items: center;
	font-size: 12px;

	svg {
		margin-right: 8px;
	}

	@media(min-width: 600px) {
		display: none;
	}
`

export const ChartPlaceholder = styled.div`
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	svg {
		margin-bottom: 15px;
	}

	svg, span {
		opacity: 0.5;
	}

	span {
		font-style: italic;
	}
`
