import React from 'react'
import { FaTimes } from 'react-icons/fa'

import {
    Modal, Header, Content, Footer 
} from './styles'

Modal.defaultStyles.overlay.backgroundColor = 'rgba(43, 62, 77, 0.8)'
Modal.setAppElement('#root')

export default function ({
    children, isOpen, toggleModal, title 
}) {
    return (
        <Modal
            isOpen={isOpen}
            className="animated fadeInUp faster"
        >
            <Header>
                <span>{title || ''}</span>
                <FaTimes size={18} className="modal-close" onClick={toggleModal} />
            </Header>

            <Content>
                {children}
            </Content>

            <Footer />
        </Modal>
    )
}
