import styled, { css } from 'styled-components'
import ReactSelect from 'react-select'

const maxWidth = css`
	max-width: ${props => props.style?.maxWidth}px;
`

export const Select = styled(ReactSelect)`
	width: 100%;
	margin-bottom: 10px;

	${props => props.style?.maxWidth && maxWidth}
`

export const SelectContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
`
