import React, {
    useState, useEffect, useCallback 
} from 'react'
import { Formik, Form, FieldArray } from 'formik'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import {
    FaTrash, FaPlus, FaInbox, FaArrowDown, FaCheck, FaFile 
} from 'react-icons/fa'
import { MdScreenRotation } from 'react-icons/md'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Checkbox } from 'primereact/checkbox'

import {
    Textarea, Button
} from '~/components/Form'
import Payment from '~/components/Payment'

import api from '~/services/api'

import robotParameters from '~/config/RobotParameters'

import authHeaders from '~/util/authHeaders'

import { Container, Header, AccordionPlaceholder } from './styles'

import termsFile from '~/assets/docs/aceite.pdf'
import Spinner from '~/components/Spinner'

const formConfig = {
    question: {
        index: 0,
        initialValues: {
            questions: []
        },
        validations: yup.object().shape({}),
        enabled: true
    },

    payment: {
        index: 1,
        enabled: false
    },

    conclusion: {
        index: 2,
        enabled: false
    }
}

export default function ChatbotOrder({ location, history }) {
    const { productId } = location.state

    const [user, setUser] = useState(null)
    const [product, setProduct] = useState(null)
    const [step, setStep] = useState(0)
    const [paid, setPaid] = useState(false)
    const [accepted, setAccepted] = useState(false)
    const [restoredData, setRestoredData] = useState(null)
    const [confirmationData, setConfirmationData] = useState(null)

    // Load user and product data
    useEffect(() => {
        async function loadUserData() {
            const response = await api.get('users', authHeaders)

            const { name, email, phone } = response.person

            localStorage.setItem('robot-chatbot-order', JSON.stringify({
                productId,
                userId: response.id
            }))

            setUser(response)

            setConfirmationData({
                name,
                email,
                phone
            })

            document.querySelector('nav').scrollIntoView({ behavior: 'smooth' })
        }

        async function loadProduct() {
            const response = await api.get(`products/${productId}`)
            setProduct(response)
        }

        loadUserData()
        loadProduct()
    }, [])

    const enableNextStep = useCallback((notify = true, quantity = 1) => {
        const lastStepIndex = Object.keys(formConfig).length - 1

        if(step <= lastStepIndex - 1) {
            const nextStep = step + quantity

            for(let i = 1; i <= quantity; i++) {
                Object.values(formConfig).find(config => config.index === step + i).enabled = true
            }

            setStep(nextStep)
            window.scrollTo({ behavior: 'smooth', top: 40 })

            if(notify) {
                toast.info('Dados salvos com sucesso! Continue o preenchimento.')
            }
        }
    }, [step])

    // Restore form data
    useEffect(() => {
        async function restoreData() {
            const localStoredOrderData = JSON.parse(localStorage.getItem('robot-chatbot-order'))

            if(!!user && !!product && localStoredOrderData?.productId === product.id && localStoredOrderData?.userId === user.id) {
                const response = await api.get('chatbot/order', authHeaders)

                if(response) {
                    const restoredQuestions = response.questions

                    if(restoredQuestions.length) {
                        setRestoredData({ questions: restoredQuestions })
                        enableNextStep(false)

                        toast.info('Você tem um formulário inacabado. Seus dados foram carregados automaticamente.')
                    }

                    if(response.payment) {
                        setPaid(true)
                        enableNextStep(false, 2)
                    }
                }
            }
        }

        restoreData()
    }, [product, user])

    const sendSMS = useCallback(async (values) => {
        const { number, message } = values

        try {
            await api.post('smss', {
                recipients: [number],
                content: message
            }, authHeaders)
        } catch(e) {
            toast.error('Ocorreu um erro ao enviar SMS.')
        }
    }, [])

    const sendEmail = useCallback(async (params, notify = false) => {
        const { email, context, template } = params

        try {
            const data = {
                from: robotParameters.contact_email,
                to: email,
                subject: `Contato Robot - Contratação ${product?.name}`,
                template,
                context
            }
			
            const response = await api.post('emails', data, authHeaders)
            
            if(notify && response?.messageId) {
                toast.success(`Enviamos um comprovante para o e-mail ${email}.`, { autoClose: 6000 })
            }
        } catch(e) {
            toast.error('Ocorreu um erro ao enviar um e-mail de confirmação.')
        }
    }, [product])
	
    const storeQuestions = useCallback(async questions => {
        const data = {
            product_id: productId,
            questions: questions
                ? questions.filter(question => question.question && question.answer)
                    .map(({ question, answer }) => ({ question, answer })) : []
        }

        await api.post('questions', data, authHeaders)
    }, [productId])
	
    const handleAddQuestion = useCallback(arrayHelpers => {
        const nextId = arrayHelpers.form.values.questions?.length ? Math.max(...arrayHelpers.form.values.questions.map(q => q.id)) + 1 : 1
		
        const question = { id: nextId, question: '', answer: '' }

        if(arrayHelpers.form.values.questions) {
            storeQuestions([...arrayHelpers.form.values.questions, question])
        } else {
            storeQuestions([question])
        }
		
        arrayHelpers.push(question)
    }, [storeQuestions])

    const handleRemoveQuestion = useCallback((arrayHelpers, id, i) => {
        arrayHelpers.remove(i)

        const { questions } = arrayHelpers.form.values

        switch(i) {
            case 0:
                storeQuestions(questions.slice(1))
                break
            case questions.length:
                storeQuestions(questions.slice(0, questions.length - 1))
                break
            default:
                storeQuestions([...questions.slice(0, i), ...questions.slice(i + 1)])
                break
        }
    }, [storeQuestions])

    const finishOrder = useCallback(async () => {
        await api.post('contracts', {
            product_id: product.id
        }, authHeaders)

        const {
            name, phone, email 
        } = confirmationData

        // Confirmation SMS
        const message = `Você acaba de contratar o produto ${product.name}. A Robot agradece sua confiança!`
        sendSMS({ number: phone, message })

        // Confirmation e-mails
        const contextClient = {
            name, product: product.name 
        }
        sendEmail({
            email, 
            context: contextClient, 
            template: 'contract-confirmation-client' 
        }, true)

        const contextRobot = {
            name, product: product.name, email 
        }
        sendEmail({ 
            email: robotParameters.contact_email, 
            context: contextRobot, 
            template: 'contract-confirmation-robot' 
        })

        history.push('/dashboard')

        // Disable steps
        Object.values(formConfig).slice(1).forEach(config => { config.enabled = false })

        localStorage.removeItem('robot-chatbot-order')
    }, [confirmationData, product])

    return (
        <Container>
            <Header>
                <h1>
                    <span>Produto: </span>
                    {product ? (
                        product.name
                    ) : (
                        <Spinner color="#fff" />
                    )}
                </h1>
            </Header>
            
            {!product ? (
                <AccordionPlaceholder>
                    <Spinner color="#fff" size={70} />
                </AccordionPlaceholder>
            ) : (
                <Accordion activeIndex={step} onTabChange={e => setStep(e.index)} className="animated fadeIn slow">
                    <AccordionTab header="1 - Perguntas e respostas" disabled={!formConfig.question.enabled}>
                        <div id="require-rotation">
                            <MdScreenRotation size={34} />
                            <span>Gire seu dispositivo para utilizar este recurso.</span>
                        </div>
                        
                        <Formik 
                            initialValues={restoredData || formConfig.question.initialValues} 
                            validationSchema={formConfig.question.validations}
                            enableReinitialize 
                        >
                            {() => (
                                <Form id="form-questions">
                                    <p>Indique algumas perguntas e respostas para termos uma base na construção do seu chatbot.</p>

                                    <FieldArray 
                                        name="questions"
                                        render={arrayHelpers => (
                                            <>
                                                {arrayHelpers.form.values.questions?.length ? arrayHelpers.form.values.questions.map((question, i) => (
                                                    <section key={question.id} className="animated question-container" id={`question-${question.id}`}>
                                                        <Textarea 
                                                            label="Pergunta"
                                                            name={`questions[${i}].question`}
                                                        />

                                                        <Textarea 
                                                            label="Resposta"
                                                            name={`questions[${i}].answer`}
                                                        />

                                                        <Button className="transparent" onClick={() => handleRemoveQuestion(arrayHelpers, question.id, i)}>
                                                            <FaTrash 
                                                                className="white-text"
                                                                size={13}
                                                            />
                                                        </Button>
                                                    </section>
                                                )) : (
                                                    <div className="empty-question-list">
                                                        <FaInbox size={60} className="animated bounceIn" />
                                                        <p>Nenhuma pergunta adicionada</p>
                                                    </div>
                                                )}

                                                {arrayHelpers.form.values.questions?.length ? (
                                                    <Button className="green" style={{ float: 'right' }} onClick={() => handleAddQuestion(arrayHelpers)}>
                                                        Salvar e adicionar outra
                                                        <FaPlus size={14} color="#FFF" />
                                                    </Button>
                                                ) : (
                                                    <Button className="transparent" style={{ float: 'right' }} onClick={() => handleAddQuestion(arrayHelpers)}>
                                                        Adicionar uma pergunta
                                                        <FaPlus size={14} color="#FFF" />
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    />
                                </Form>
                            )}
                        </Formik>

                        <div>
                            <Button className="transparent" onClick={enableNextStep}>
                                <span>Próxima</span>
                                <FaArrowDown size={14} />
                            </Button>
                        </div>
                    </AccordionTab>

                    <AccordionTab header="2 - Pagamento" disabled={!formConfig.payment.enabled}>

                        <Payment onPaid={setPaid} product={product} isPaid={paid} />

                        <div style={{ marginTop: -30 }}>
                            <Button className="transparent" disabled={!paid} onClick={enableNextStep}>
                                <span>Próxima</span>
                                <FaArrowDown size={14} />
                            </Button>
                        </div>
                    </AccordionTab>

                    <AccordionTab header="3 - Conclusão" disabled={!formConfig.conclusion.enabled}>

                        <p>Leia atentamente o contrato a seguir e marque o aceite para conluir.</p>

                        <a href={termsFile} target="_blank" rel="noreferrer noopener" className="terms-download button transparent">
                            <FaFile size={70} />
                            Termo de licença
                        </a>

                        <iframe src={termsFile} title="terms" />

                        <div className="checkbox-container">
                            <Checkbox onChange={e => setAccepted(e.checked)} checked={accepted} />
                            <span>Li e concordo com os termos.</span>
                        </div>

                        {/* <Formik onSubmit={finishOrder}>
                            {() => (
                                <Form>
                                    <div className="g-recaptcha" data-sitekey="6LcWeuUUAAAAACAxJafLFIfvim4kRvzoPl-6_qtS" />

                                    <Button type="submit" className="green" id="conclusion-button" disabled={!accepted}>
                                        <span>Concluir</span>
                                        <FaCheck size={14} />
                                    </Button>
                                </Form>
                            )}
                        </Formik> */}

                        <Button onClick={finishOrder} className="green" id="conclusion-button" disabled={!accepted}>
                            <span>Concluir</span>
                            <FaCheck size={14} />
                        </Button>
                                
                    </AccordionTab>
                </Accordion>
            )}
        </Container>
    )
}
