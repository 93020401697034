import React, { useCallback, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { FaUser, FaEnvelope, FaPhone } from 'react-icons/fa'

import masks from '~/util/masks'
import regex from '~/util/regex'

import api from '~/services/api'

import { Container } from './styles'

import { Textbox, Button } from '~/components/Form'

const validation = Yup.object({
    name: Yup.string().required('Insira seu nome.'),
    email: Yup.string()
        .email('E-mail inválido.')
        .required('E-mail obrigatório.'),
    phone: Yup.string().required('O telefone é obrigatório.').matches(regex.mobile, 'Número de telefone incorreto.')
})

export default function Newsletter({ location }) {
    const params = new URLSearchParams(location.search)
    const email = params.get('email') || ''
    let phone = params.get('phone') || ''
    phone = phone.length === 11 ? masks.maskApply.mobile(phone) : ''

    const [initialValues, setInitialValues] = useState({ name: '', email, phone })

    const handleSubmit = useCallback(async (values, { resetForm }) => {
        try {
            await api.post('people_newsletter', values)
			
            toast.success('Cadastro realizado com sucesso!')
            toast.info('Aguarde as novidades!', { autoClose: 5000 })
			
            setInitialValues({ name: '', email: '', phone: '' })
            resetForm()
        } catch(e) {
            toast.error(e.msg || 'Ocorreu um erro ao tentar salvar os dados.')
        }
    }, [])

    return (
        <Container>
            <section className="animated fadeIn slow">
                <h1>Newsletter Robot</h1>
                <p>Cadastre-se a seguir e receba nossas novidades em primeira mão.</p>

                <div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Textbox name="name" label="Seu nome" icon={{ Icon: FaUser }} />

                                <Textbox name="email" label="Seu e-mail" icon={{ Icon: FaEnvelope }} />

                                <Textbox name="phone" label="Seu celular" mask={masks.mobile} icon={{ Icon: FaPhone }} />

                                <Button type="submit" disabled={isSubmitting} className="green">Cadastrar</Button>
                            </Form>
                        )}
                    </Formik>
                </div>
				
            </section>
        </Container>
    )
}
