import styled from 'styled-components'

export const Container = styled.article`
	display: flex;
	flex-direction: column; 
	justify-content: center;
	align-items: center;
	width: 25%;
	padding: 12px 0;

	img {
		width: 130px;
		height: 130px;
		background: #fff;
		border-radius: 50%;
	}

	h2 {
		font-size: 17px;
		font-weight: 400;
		text-align: center;
		width: 80%;
		padding: 8px 0 4px;
	}

	p {
		font-size: 12px;
		text-align: center;
	}
	
	@media (max-width: 850px) {
		& {
			width: 50%;
		}
	}

	@media (max-width: 600px) {
		& {
			width: 100%;
		}
	}
`
