import styled from 'styled-components'

import ReactModal from 'react-modal'

export const Modal = styled(ReactModal)`
    position: absolute;
	background: #fff;
	width: 90%;
    height: 90%;
    top: 5%;
	left: 5%;
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 5px 8px 0 rgba(0,0,0,0.2);

	h1, h2, h3, h4, h5, h6, p, span {
		color: #2c2c2c;
	}

	h1 {
		font-size: 24px;
		font-weight: 400;
		padding-bottom: 14px;
	}

	h2 {
		font-size: 21px;
		font-weight: 400;
		padding-bottom: 8px;
	}

	button {
		margin: 12px 0 !important;
	}
`

export const Header = styled.div`
	height: 44px;
	background-image: linear-gradient(90deg, #427C56, #2B3E4D);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;

	span {
		color: #fff;
		font-weight: 400;
		font-size: 16px;
	}

	svg:hover {
		cursor: pointer;
		opacity: 0.7;
	}
`

export const Footer = styled.div`
	height: 20px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	bottom: 0;
	position: absolute;
	width: 100%;
`

export const Content = styled.div`
	padding: 30px;
	height: calc(100% - 45px);
	overflow-y: scroll;

	@media (max-width: 500px) {
		padding: 20px;
	}
`
