import styled from 'styled-components'

export const Container = styled.div`
	background: rgba(0, 0, 0, 0.5);
	padding: 0 30px;
	border-bottom: solid 3px rgba(0, 0, 0, 0.3);

	@media (max-width: 600px) {
		padding: 0 10px;
	}
`

export const Content = styled.div`
	height: 64px;
	max-width: 1300px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			height: 58px;
			width: 100px;
			padding: 10px;
		}

		a {
			transition: background 0.3s;

			&:hover {
				background: rgba(0, 0, 0, 0.1);
			}
		}
	}

	aside {
		display: flex;
		align-items: center;
	}
`

export const Profile = styled.div`
	display: flex;
	margin-left: 20px;
	padding-left: 20px;
	border-left: solid 1px rgba(255, 255, 255, 0.3);
	align-items: center;

	div {
		text-align: right;
		margin-right: 10px;

		strong {
			display: block;
			color: #fff;
		}

		a {
			display: block;
			margin-top: 2px;
			font-size: 12px;
			color: #ddd;
			transition: opacity 0.3s;

			&:hover {
				opacity: 0.7;
			}
		}

		button {
			height: 16px !important;
			font-size: 10px !important;
			padding: 0 4px !important;
			background: #f44336 !important;
			opacity: 0.8 !important;
			margin: 5px 0 0 calc(100% - 24px) !important;

			&:hover {
				background: #f44336 !important;
				opacity: 1 !important;
			}
		}
	}

	img {
		height: 44px;
		width: 44px;
		border-radius: 50%;
		transition: filter 0.3s;

		&:hover {
			filter: brightness(150%);
		}
	}
`
