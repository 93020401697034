import styled from 'styled-components'
import { lighten } from 'polished'

import vectorCut from '~/assets/img/corte.png'
import vectorCut2 from '~/assets/img/corte2.png'

function getHeaderColor(color, level) {
    let percentage

    switch(level) {
        case 2: percentage = 0.05
            break
        case 3: percentage = 0.1
            break
        default: percentage = 0
    }

    switch(color) {
        case 'green': return lighten(percentage, '#009342')
        case 'yellow': return lighten(percentage, '#FF9138')
        default: return '#424242'
    }
}

export const Features = styled.section`
	background: #FFF;
	padding: 24px 30px;
	display: flex;
	flex-wrap: wrap;
	margin-top: -2px;

	& > div {
		font-size: 16px;
	}
`

export const Feature = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	margin: 20px;

	img {
		width: auto;
		height: 100px;
		margin-right: 15px;
		border-radius: 50%;
	}

	div {
		max-width: 220px;
		width: 100%;

		@media (max-width: 800px) {
			& {
				max-width: 560px;
			}
		}

		h2 {
			font-size: 18px;
			font-weight: 600;
			padding-bottom: 10px;
			color: #2c2c2c;
		}

		p {
			font-size: 14px;
			line-height: 20px;
			color: rgba(0, 0, 0, 0.7);
		}
	}

	@media (max-width: 500px) {
		img {
			display: none;
		}	
	}
`

export const Extra = styled.section`
	background: #FFF;
	display: flex;
	flex-direction: column;
	margin-top: -2px;

	div.vector-cut {
		background: url(${vectorCut});
		background-size: 100% 100%;
		height: 70px;
	}

	> div:last-child {
		display: flex;
		justify-content: space-between;
		background: #F4F4F4;
		margin-top: -2px;
	}

	@media (max-width: 500px) {
		> div:last-child {
			display: block;
		}
	}
`

export const DemoContainer = styled.div`
	width: 50%;
	padding-bottom: 30px;
	display: flex;
	justify-content: center;
	margin-top: -10px;

	img {
		width: 450px;
	}

	@media (max-width: 500px) {
		width: 100%;

		img {
			width: 339px;
		}
	}
`

export const ImageContainer = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 450px;
		height: 340px;
	}

	@media (max-width: 900px) {
		width: 100%;

		img {
			width: 339px;
		}
	}
`

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 20px 40px;
	margin-top: -20px;

	p {
		color: #2c2c2c;
		font-size: 16px;
		line-height: 26px;
		margin-top: 36px;
	}

	.button-container {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 50px 0;

		button {
			width: 100%;
			height: 60px;
			max-width: 200px;
			background: #4caf50;
			font-weight: 800;
			border-radius: 4px;
			transition: all 0.3s;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 16px;

			&:hover {
				background: #43a047;
			}
		}
	}

	@media (max-width: 500px) {
		width: 100%;
	}
`

export const PriceContainer = styled.section`
	background: #365a45;
	justify-content: center;

	.vector-cut-2 {
		background-color: #F4F4F4;
		background-image: url(${vectorCut2});
		background-size: 100% 100%;
		height: 70px;
	}
`

export const PriceGrid = styled.div`
	padding: 65px 100px;
	display: grid;
	grid-template-columns: repeat(${props => props.children.length}, 1fr);
	grid-template-rows: auto;
	grid-gap: 50px;
	width: 100%;

	div:not(.header) {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	@media(max-width: 900px) { 
		padding: 65px 30px;
		grid-gap: 25px;
	}

	@media(max-width: 600px) {
		display: block;
		
		> div {
			margin-bottom: 20px;
		}
	}
`

export const CardHeader = styled.div.attrs({
    className: 'header'
})`
	background: ${props => getHeaderColor(props.color, props.level)};
	width: 100%;
	padding: 14px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: flex;
	align-items: center;
	flex-direction: column;

	h1 {
		font-size: 24px;
		text-align: center;
		font-weight: 800;
		margin-bottom: 8px;
	}

	h2 {
		font-size: 20px;
		font-weight: 500;
	}

	span {
		font-size: 12px;
		font-weight: 500;
	}
`

export const CardContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 34px 50px 16px;
	color: #424242;

	ul {
		line-height: 30px;
		font-weight: 500;
		font-size: 20px;

		> li {
			padding: 4px 0;

			ul {
				list-style-type: circle;
				font-weight: 400;
				line-height: 24px;
				font-size: 14px;
				margin-left: 18px;
			}
		}
	}

	button {
		border: solid 1px #424242 !important;
		color: #424242 !important;
		height: 50px !important;
		width: 100% !important;
		margin: 15px 0 0 !important;
		font-weight: 500 !important;

		&:hover {
			background: ${props => getHeaderColor(props.color, 2)} !important;
			color: #fff !important;
			border: none !important;
			font-size: 16px !important;
		}
	}

	@media(max-width: 500px) {
		padding: 20px 24px 0;
	}
`

export const PriceCard = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 0 24px 0;
	background: #fff;
	border-radius: 10px;
	min-height: 200px;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	transition: all 0.3s;

	&, h1, h2 {
		color: #FFF;
	}

	${CardHeader}:nth-child(n + 2) {
		border-radius: 0;
	}

	&:hover {
		box-shadow: 0 8px 10px 2px rgba(0,0,0,0.14), 0 0px 2px -2px rgba(0,0,0,0.12), 0 -10px 17px 0 rgba(0,0,0,0.2);
		transform: scale(1.02);
	}
`
