import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
})

api.interceptors.response.use(
    response => response.data,
    err => new Promise((_, reject) => reject(err.response?.data))
)

export default api
