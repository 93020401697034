import styled from 'styled-components'

export const Container = styled.article`
	padding-top: 50px;

	@media (max-width: 500px) {
		padding-top: 20px;
	}
`

export const Header = styled.header`
	display: flex;
	padding: 0 120px 40px;
	min-height: 400px;
	background: url(${props => props.background});
	background-size: contain;
	background-repeat: no-repeat;
	background-position-y: bottom;

	> div {
		width: 40%;
		margin-left: 60%;
		display: flex;
		flex-direction: column;
		
		h1 {
			display: flex;
			flex-direction: column;
			padding: 15px 0;
			border-bottom: solid 1px #fff;

			span:first-child {
				font-size: 34px;
			}

			span:last-child {
				font-weight: 500;
				font-size: 44px;
			}
		}

		p {
			font-size: 16px;
			padding: 24px 0;
			line-height: 24px;
		}

		div.button-container {
			display: flex;
			justify-content: center;

			a.button, button {
				max-width: 300px;
				width: 100%;
				height: 60px;
				font-size: 18px;
				font-weight: 500;
				margin: 0 !important;
			}
		}
	}

	@media (max-width: 800px) {
		& {
			padding: 0;
			flex-wrap: wrap;
			min-height: 530px;

			> div {
				padding: 0 30px 30px;
				margin-left: 0;
				width: 100%;
			}
		}
	}
`

export const Features = styled.section`
	background: #FFF;
	padding: 24px 30px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

	& > div {
		font-size: 16px;
	}
`

export const Feature = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	margin: 20px;

	img {
		width: auto;
		height: 100px;
		margin-right: 15px;
		border-radius: 50%;
	}

	div {
		min-width: 200px;
		width: 100%;

		h2 {
			font-size: 20px;
			font-weight: 600;
			padding-bottom: 10px;
			color: #2c2c2c;
		}

		p {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.7);
		}
	}

	@media (max-width: 500px) {
		img {
			display: none;
		}	
	}
`

export const Extra = styled.section`
	background: #FFF;
	display: flex;
	flex-direction: column;

	> img {
		width: 100%;
		height: 50px;
	} 

	> div {
		display: flex;
		justify-content: space-between;
		background: #F4F4F4;
	}

	@media (max-width: 500px) {
		> div {
			display: block;
		}
	}
`

export const DemoContainer = styled.div`
	width: 50%;
	padding-bottom: 30px;
	display: flex;
	justify-content: center;
	margin-top: -10px;

	img {
		width: 450px;
	}

	video {
		position: relative;
		margin-left: -308px;
		margin-top: -28px;
		height: 364px;
		border-radius: 10px;
		transform: rotate3d(1.1, 0.7, 1.1, -66deg);
	}

	@media (max-width: 500px) {
		width: 100%;

		img {
			width: 339px;
		}

		video {
			height: 258px;
			position: absolute;
			margin-left: 6px;
			margin-top: -45px;
			transform: rotate3d(1.1, 0.7, 0.9, -76deg);
		}
	}
`

export const ListContainer = styled.ul`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 20px 40px;
	margin-top: -20px;

	ul {
		li {
			color: #2c2c2c;
			font-size: 20px;
			line-height: 40px;
			font-weight: 500;
		}
	}

	.button-container {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 50px 0;

		button {
			width: 100%;
			height: 60px;
			max-width: 200px;
			background: #4caf50;
			font-weight: 800;

			&:hover {
				background: #43a047;
			}
		}
	}

	@media (max-width: 500px) {
		width: 100%;
	}
`

export const TipContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 300px;
	
	.tip {
		background: rgba(0, 0, 0, 0.1);
		padding: 10px 16px;
		border-radius: 4px;

		a {
			font-weight: 600;
		}
	}

	button {
		width: 100%;
		margin: 0 !important;
	}
`
