import React from 'react'
import { FaLock } from 'react-icons/fa'

import { Container, Card } from './styles'

export default function CreditCard(props) {
    const {
        name, number, expiration, cvv, brand, style
    } = props

    return (
        <Container style={style}>
            <Card>
                <div className="brand">
                    {brand ? (
                        <img src={brand} alt="" className="animated bounceIn faster delay-400ms" />
                    ) : (
                        <div className="brand-placeholder" />
                    )}
                </div>
                <span className="name animated bounceIn faster delay-100ms">{name || 'SEU NOME'}</span>
                <span className="number animated bounceIn">{number || '0000 0000 0000 0000'}</span>
                <footer>
                    <span className="expiration bounceIn faster delay-200ms">
                        <span>Validade</span>
                        {expiration || '__/__'}
                    </span>

                    <span className="cvv animated bounceIn faster delay-300ms">
                        <FaLock size={14} className="grey-text text-darken-3" />
                        <div>
                            <span>cvv</span>
                            {cvv || '___'}
                        </div>
                    </span>
                </footer>
            </Card>
        </Container>
    )
}
