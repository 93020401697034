import styled from 'styled-components'

import { MessageCenter } from '~/components/SMSBox/styles'

export const Container = styled.article`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: 1fr;
	grid-gap: 15px;
	height: fit-content;
	
	section {
		display: flex;
		align-items: center;
		flex-direction: column;
		background: #1D3B3B;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		border-radius: 2px;
		padding: 35px;
		width: 100%;
		margin-bottom: 15px;

		h1 {
			width: 100%;
			font-size: 22px;
			font-weight: 300;
		}

		form {
			width: 100%;
		}
	}

	> div.col-1 {
		margin: 0 0 35px 35px;
		grid-column: 1/4;
		display: flex;
		flex-direction: column;

		@media(max-width: 915px) {
			margin: 0;
		}

		div.horizontal-container {
			display: grid;
			grid-gap: 15px;
			grid-template-columns: repeat(5, 1fr);

			@media(max-width: 1120px) {
				display: flex;
				flex-direction: column;	
			}
		}

		${MessageCenter} {
			width: 70%;
		}
	}

	> div.col-2 {
		margin: 0 35px 35px 0;
		grid-column: 4/7;
		display: flex;
		flex-direction: column;

		section {
			margin-bottom: 15px;
		}

		@media(max-width: 915px) {
			margin: 0;
		}
	}

	@media(max-width: 1120px) {
		> div.col-1 {
			grid-column: 1/4;

			> section {
				grid-column: 1/4 !important;
			}

			${MessageCenter} {
				width: 100%;
			}
		}

		> div.col-2 {
			grid-column: 4/7;
		}
	}

	@media(max-width: 914px) {
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;

		> div.col-1 {
			display: flex;
			flex-direction: column;
		}
	}
`
