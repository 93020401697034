import styled from 'styled-components'

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #1D3B3B;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	border-radius: 2px;
	padding: 35px 35px 54px;
	height: fit-content;
	min-height: 250px;
	width: 50%;

	@media(max-width: 950px) {
		width: 100%;
	}

	> div {
		padding-top: 40px;
	}
`

export const ProductList = styled.ul`
	width: 100%;
	max-width: 420px;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const EmptyList = styled.div`
	width: 100%;
	max-width: 300px;
	text-align: center;
	font-style: italic;
	background: rgba(0, 0, 0, 0.2);
	padding: 20px;
	color: #aaa;
`

export const Product = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: solid 1px rgba(255, 255, 255, 0.2);
	padding: 15px 8px;
	width: 100%;

	span {
		font-size: 16px;
		font-weight: 400;

		p {
			font-size: 11px;
			font-weight: 100;
			padding-top: 10px;
			color: rgba(255, 255, 255, 0.5);
		}
	}

	button {
		width: 84px;
	}

	div.processing-label {
		width: 84px;
		display: flex;
		align-items: center;
		flex-direction: column;

		span {
			font-style: italic;
			font-size: 12px;
		}
	}
`
