import React from 'react'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Routes from '~/routes'
import history from '~/services/history'

import '~/config/ReactotronConfig'

import GlobalStyle from '~/styles/global'
import '~/styles/global.css'

import '~/styles/toast.css'

export default function App() {
    return (
        <Router history={history}>
            <Routes />

            <GlobalStyle />

            <ToastContainer 
                position="bottom-right" 
                autoClose={4000}
                pauseOnHover
                pauseOnVisibilityChange
                hideProgressBar
                draggable
            />
        </Router>
    )
}
