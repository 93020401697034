import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Container } from './styles'

import adminOptions from './Admin'
import chatbotOptions from './Chatbot'
import mensageriaOptions from './Mensageria'

export default function ({ type }) {
    const navOptions = useMemo(() => {
        switch(type) {
            case 'admin': return adminOptions
            case 'chatbot': return chatbotOptions
            case 'mensageria': return mensageriaOptions
            default: throw new Error(`NavOptions type is invalid (${type}).`)
        }
    }, [])

    
    return (
        <Container>
            {navOptions && navOptions.map(option => (
                <Link key={option.to} to={option.to}>
                    {option.icon}
                    <span>{option.text}</span>
                </Link>
            ))}
        </Container>
    )
}
