import React, { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'

import api from '~/services/api'

import CategoryCard from './CategoryCard'

import { Container } from './styles'

export default function ({ history }) {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        async function loadCategories() {
            const response = await api.get('categories')

            setCategories(response)
        }

        loadCategories()
    }, [])

    const handleChatbotOrderClick = useCallback(() => {
        history.push('/chatbots')
    }, [])

    const handleMensageriaClick = useCallback(() => {
        toast.info('Por favor, envie um e-mail para contato@robot.rio.br informando que gostaria de contratar o Sistema de Mensageria.', { autoClose: 10000 })
    }, [])

    return (
        <Container>
            {categories && categories.map(category => (
                <CategoryCard 
                    key={category.id} 
                    category={category} 
                    onClick={category.id === 1 ? handleChatbotOrderClick : handleMensageriaClick}
                />
            ))}
        </Container>
    )
}
