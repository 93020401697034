import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Content } from './styles'

export default function EmptyLayout({ children }) {
    return (
        <Wrapper>
            <Content>

                {children}

            </Content>
        </Wrapper>
    )
}

EmptyLayout.propTypes = {
    children: PropTypes.element.isRequired
}
