import React, {
    useState, useCallback, useEffect
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import {
    FaRegSave, FaPlus, FaTelegramPlane, FaRegFileAlt, FaCoins, FaEnvelope 
} from 'react-icons/fa'
import { MdDirections } from 'react-icons/md'

import Spinner from '~/components/Spinner'
import Table from '~/components/Table'
import ProgressBar from '~/components/ProgressBar'

import yup from '~/services/yup'
import api from '~/services/api'

import robotParameters from '~/config/RobotParameters'

import regex from '~/util/regex'
import authHeaders from '~/util/authHeaders'

import {
    Button, Textarea, Textbox, Select 
} from '~/components/Form'

import { Container, TablePlaceholder } from './styles'

const templateInitialValues = { name: '', content: '' }
const ruleInitialValues = { name: '', query: '', template_id: '' }
const execRuleInitialValues = {
    rule_id: '', channel: '', layout_id: '', subject: '' 
}

const templateValidation = yup.object({
    name: yup.string().required('Campo obrigatório.'),
    content: yup.string().required('Campo obrigatório.')
})

const ruleValidation = yup.object({
    name: yup.string().required('Campo obrigatório.'),
    query: yup.string().required('Campo obrigatório.'),
    template_id: yup.object({
        value: yup.number().required(),
        label: yup.string().required()
    }).required('Selecione um template.')
})

const execRuleValidation = yup.object({
    rule_id: yup.object({
        value: yup.number().required(),
        label: yup.string().required()
    }).required('Selecione uma regra.'),
    layout_id: yup.object({
        value: yup.string().required(),
        label: yup.string().required()
    }).required('Selecione o layout.'),
    channel: yup.string().required('Selecione o canal de envio.'),
    subject: yup.string()
})

const emailValidation = yup.string().email().required()
const phoneValidation = yup.string().matches(regex.mobile).required()

export default function ({ user, contract }) {
    const params = contract?.parameters || robotParameters
    const groupSizeSMS = 100
    const emailGroupLimit = 1000

    const [formNewTemplateVisible, setFormNewTemplateVisible] = useState(false)
    const [formNewRuleVisible, setFormNewRuleVisible] = useState(false)
    const [templates, setTemplates] = useState(null)
    const [rules, setRules] = useState(null)
    const [layouts, setLayouts] = useState(null)
    const [sendMessagesCount, setSendMessagesCount] = useState(null)
    const [validRecipientsSms, setValidRecipientsSms] = useState([])
    const [validRecipientsEmail, setValidRecipientsEmail] = useState([])
    const [processingMessagesCount, setProcessingMessagesCount] = useState(false)
    const [sentPercentage, setSentPercentage] = useState(0)
    const [emailOnlyFieldsVisibility, setEmailOnlyFieldsVisibility] = useState(false)

    const loadTemplates = useCallback(async () => {
        const response = await api.get('message_templates', authHeaders)

        setTemplates(response)
    }, [])

    const loadRules = useCallback(async () => {
        const response = await api.get('message_rules', authHeaders)

        const data = response.map(r => ({ ...r, template_name: r.template.name }))

        setRules(data)
    }, [])

    const loadLayouts = useCallback(async () => {
        if(contract?.parameters) {
            const response = await api.get('email_layouts', authHeaders)

            const data = response.map(layout => ({
                id: layout.layout_id,
                name: layout.layout_name
            }))

            setLayouts(data)
        } else {
            const data = [
                {
                    id: params.email_template_id,
                    name: 'Robot Padrão'
                }
            ]

            setLayouts(data)
        }
    }, [])
	
    // Load templates and rules
    useEffect(() => {
        loadTemplates()

        loadRules()

        loadLayouts()
    }, [])
	
    const handleAddTemplate = useCallback(async (data, { setSubmitting, resetForm }) => {
        data.user_id = user.id
        
        try {
            const template = await api.post('message_templates', data, authHeaders)

            toast.success('Template criado.')
            resetForm()

            setTemplates(prev => [...prev, template])
        } catch(e) {
            toast.error(e.msg || 'Ocorreu um erro ao salvar o template.')
        } finally {
            setSubmitting(false)
        }
    }, [])

    const handleAddRule = useCallback(async (data, { setSubmitting, resetForm }) => {
        try {
            data.user_id = user.id
            data.template_id = data.template_id.value

            const rule = await api.post('message_rules', data, authHeaders)

            toast.success('Regra criada.')
            resetForm()

            setRules(prev => [...prev, { ...rule, template_name: rule.template.name }])
        } catch(e) {
            toast.error(e.msg || 'Ocorreu um erro ao salvar a regra.')
        } finally {
            setSubmitting(false)
        }
    }, [])

    const fillTemplate = useCallback((message, values, asHtml = false) => {
        Object.entries(values).forEach(([key, value]) => {
            message = message.replace(new RegExp(`#${key}#`, 'g'), value)
        })
        return asHtml ? message.replace(/\n/g, '<br/>') : message
    }, [])

    async function sendSMS(recipients, message, template_id) {
        if(!recipients?.length || !message) return

        const data = recipients.map(recipient => ({
            to: recipient.recipient_phone,
            text: fillTemplate(message, recipient),
            template_id
        }))

        const restCount = data.length % groupSizeSMS

        const lastGroupInitialIndex = data.length - restCount

        const lastGroup = data.slice(lastGroupInitialIndex, data.length)

        try {
            for(let i = 0; i < lastGroupInitialIndex; i += groupSizeSMS) {
                const slice = data.slice(i, i + groupSizeSMS)
                
                await api.post('smss', slice, authHeaders)

                setSentPercentage(Math.floor(((i + groupSizeSMS) * 100) / data.length))
            }

            if(lastGroup.length) {
                await api.post('smss', lastGroup, authHeaders)
            }

            setSentPercentage(100)
            setTimeout(() => setSentPercentage(0), 3000)

            toast.success('Todos os SMSs foram enviados.')
        } catch(e) {
            toast.error(e.msg || 'Houve um erro ao enviar os SMSs.')
        }
    }

    function getTotalMessageSize(sendObject) {
        const messageSize = sendObject.recipients.reduce((total, current) => {
            total += current.to.length + current.text.length
            return total
        }, 0)

        const body = messageSize + sendObject.from + sendObject.reply_to + sendObject.subject + sendObject.template_id + sendObject.sg_template_id
        const headerSize = 400

        return body.length + headerSize
    }

    async function sendEmail(recipients, message, subject, template_id, layout_id) {
        if(!recipients.length) return

        const dataTemplate = recipients.map(recipient => ({
            to: recipient.recipient_email,
            text: fillTemplate(message, recipient, true)
        }))

        const groupSizeEmail = message.length > emailGroupLimit ? 10 : 100

        console.log(`Slice size: ${groupSizeEmail}`)

        const restCount = dataTemplate.length % groupSizeEmail

        const lastGroupInitialIndex = dataTemplate.length - restCount

        const lastGroup = dataTemplate.slice(lastGroupInitialIndex, dataTemplate.length)

        try {
            for(let i = 0; i < lastGroupInitialIndex; i += groupSizeEmail) {
                const slice = dataTemplate.slice(i, i + groupSizeEmail)

                const sendObject = { 
                    recipients: slice, 
                    from: params.from_email,
                    reply_to: params.reply_email,
                    template_id,
                    sg_template_id: layout_id,
                    subject
                }

                const totalMessageSize = getTotalMessageSize(sendObject)

                console.log(`totalMessageSize: ${totalMessageSize}`)

                // O limite máximo de tamanho da requisição é de 20MB
                // https://sendgrid.com/docs/for-developers/sending-email/api-getting-started/
                const maxRequestSize = 20 * 1024 * 1024

                if(totalMessageSize > maxRequestSize) {
                    console.log(`Passou do limite máximo de mensagem (iteração: ${i})`)

                    const partes = [
                        slice.slice(0, slice.length / 2),
                        slice.slice(slice.length / 2)
                    ]

                    for(let j = 0; j < partes.length; j++) {
                        await api.post('emails', { 
                            recipients: partes[j], 
                            from: params.from_email,
                            reply_to: params.reply_email,
                            template_id,
                            sg_template_id: layout_id,
                            subject
                        }, authHeaders)
                    }
                } else {
                    await api.post('emails', sendObject, authHeaders)
                }

                setSentPercentage(Math.floor(((i + groupSizeEmail) * 100) / dataTemplate.length))
            }

            if(lastGroup.length) {
                await api.post('emails', {
                    recipients: lastGroup,
                    from: params.from_email,
                    reply_to: params.reply_email,
                    template_id,
                    sg_template_id: layout_id, // params.email_template_id,
                    subject
                }, authHeaders)
            }

            setSentPercentage(100)
            setTimeout(() => setSentPercentage(0), 3000)

            toast.success('Todos os e-mails foram enviados.')
        } catch(e) {
            toast.error(e.msg || 'Houve um erro ao enviar os e-mails.')
        }
    }

    const handleExecRule = useCallback(async (data, { setSubmitting, resetForm }) => {
        const channel = data.channel.value
        const rule_id = data.rule_id.value
        const layout_id = data.layout_id.value
        const { subject } = data

        if(channel !== 'sms') {
            if(!subject) {
                toast.warn('Insira o assunto do e-mail.')
                return
            }
        }

        const ruleResult = await api.get(`message_rules_result/${rule_id}`, authHeaders)

        const { id: templateId, content: message } = ruleResult.rule.template
        
        switch(channel) {
            case 'sms':
                await sendSMS(validRecipientsSms, message, templateId)
                break
            case 'email':
                await sendEmail(validRecipientsEmail, message, subject, templateId, layout_id)
                break
            default:
                await sendSMS(validRecipientsSms, message, templateId)
                await sendEmail(validRecipientsEmail, message, subject, templateId, layout_id)
                break
        }

        setSubmitting(false)
        setSendMessagesCount(null)
        setEmailOnlyFieldsVisibility(false)
        resetForm()
    }, [validRecipientsSms, validRecipientsEmail])

    const checkValidRecipients = useCallback(async (recipients, channel) => {
        let validCount = 0
        const validEmails = []
        const validSMSs = []

        for(let i = 0; i < recipients.length; i++) {
            const recipient = recipients[i]

            const validPhone = await phoneValidation.isValid(recipient.recipient_phone)
            const validEmail = await emailValidation.isValid(recipient.recipient_email)

            switch(channel) {
                case 'sms': 
                    if(validPhone) {
                        validCount++
                        validSMSs.push(recipient)
                    }
                    break
                case 'email': 
                    if(validEmail) {
                        validCount++
                        validEmails.push(recipient)
                    }
                    break
                default: 
                    if(validPhone || validEmail) {
                        validCount++
                    }

                    if(validPhone) {
                        validSMSs.push(recipient)
                    }

                    if(validEmail) {
                        validEmails.push(recipient)
                    }

                    break
            }
        }

        setSendMessagesCount(validCount)
        setValidRecipientsSms(validSMSs)
        setValidRecipientsEmail(validEmails)
    }, [])

    async function onChangeRule(selected, setFieldValue, values) {
        const ruleId = selected.value
        const { value: channel } = values.channel

        setFieldValue('rule_id', selected)
        setProcessingMessagesCount(true)

        if(ruleId) {
            const data = await api.get(`message_rules_result/${ruleId}`, authHeaders)

            await checkValidRecipients(data.result, channel)
        } else {
            setSendMessagesCount(null)
        }

        setProcessingMessagesCount(false)
    }

    async function onChangeChannel(selected, setFieldValue, values) {
        const channel = selected.value
        const { value: rule_id } = values.rule_id

        setFieldValue('channel', selected)
        setEmailOnlyFieldsVisibility(channel === 'email' || channel === 'both')

        if(rule_id) {
            const data = await api.get(`message_rules_result/${rule_id}`, authHeaders)

            await checkValidRecipients(data.result, channel)
        } else {
            setSendMessagesCount(null)
        }
    }

    const handleDeleteTemplate = useCallback(async (id) => {
        const relatedRules = rules.filter(rule => rule.template.id === id)

        if(relatedRules.length && !(window.confirm(`Existem ${relatedRules.length} regras que fazem uso deste template. Excluí-lo removerá estas regras.`))) {
            return false
        }

        try {
            await api.delete(`message_templates/${id}`, authHeaders)

            loadTemplates()
            loadRules()

            return true
        } catch(e) {
            return false
        }
    }, [rules])

    const handleDeleteRule = useCallback(async (id) => {
        try {
            await api.delete(`message_rules/${id}`, authHeaders)

            loadRules()

            return true
        } catch(e) {
            return false
        }
    }, [])

    return (
        <>
            <Container className="animated fadeIn delay-200ms template-manager">
                <h1>Templates de mensagem</h1>

                <Button 
                    onClick={() => setFormNewTemplateVisible(true)} 
                    style={{ display: !formNewTemplateVisible ? 'flex' : 'none' }}
                    className="transparent"
                >
                    <span>Criar template</span>
                    <FaPlus size={14} />
                </Button>

                <Formik 
                    initialValues={templateInitialValues}
                    validationSchema={templateValidation}
                    onSubmit={handleAddTemplate}
                >
                    {({ isSubmitting }) => (
                        <Form style={{ display: formNewTemplateVisible ? 'block' : 'none' }} className="animated zoomIn faster">
                            <Textbox 
                                label="Nome do template" 
                                name="name"
                                icon={{ Icon: FaRegFileAlt, props: { size: 14 } }}
                            />

                            <Textarea 
                                label="Sua mensagem..." 
                                name="content"
                                icon={{ Icon: FaEnvelope, props: { size: 14 } }}
                            />

                            <p className="tip">
                                Defina parâmetros usando a sintaxe:
                                {' '}
                                <span>#nome_parametro#</span>
                            </p>

                            <div className="actions">
                                <Button className="transparent" onClick={() => setFormNewTemplateVisible(false)}>
                                    Fechar
                                </Button>

                                <Button type="submit" className="green" disabled={isSubmitting}>
                                    <span>Salvar</span>
                                    <FaRegSave size={16} />
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

                {!templates ? (
                    <TablePlaceholder>
                        <Spinner visible />
                    </TablePlaceholder>
                ) : (
                    <Table 
                        headers={[
                            { name: 'name', value: 'Template' }, 
                            { name: 'content', value: 'Texto' }
                        ]}
                        data={templates}
                        limit={5}
                        filterable
                        copiable
                        truncateLength={60}
                        handleDelete={handleDeleteTemplate}
                    />
                )}
            </Container>
        
            <Container className="animated fadeIn delay-400ms send-rules">
                <h1>Regras de envio</h1>

                <Button 
                    onClick={() => setFormNewRuleVisible(true)} 
                    style={{ display: !formNewRuleVisible ? 'flex' : 'none' }}
                    className="transparent"
                >
                    <span>Criar regra de envio</span>
                    <FaPlus size={14} />
                </Button>

                <Formik 
                    initialValues={ruleInitialValues}
                    validationSchema={ruleValidation}
                    onSubmit={handleAddRule}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form style={{ display: formNewRuleVisible ? 'block' : 'none' }} className="animated zoomIn faster">
                            <Textbox 
                                label="Nome da regra" 
                                name="name" 
                                icon={{ Icon: MdDirections, props: { size: 18 } }}
                            />

                            <Select 
                                name="template_id" 
                                label="Selecione um template"
                                onChange={(selected) => setFieldValue('template_id', selected)}
                                options={templates ? templates.map(template => ({ value: template.id, label: template.name })) : []} 
                            />

                            <Textarea 
                                label="Consulta SQL..." 
                                name="query" 
                                icon={{ Icon: FaCoins, props: { size: 16 } }}
                            />

                            <div className="actions">
                                <Button className="transparent" onClick={() => setFormNewRuleVisible(false)}>
                                    Fechar
                                </Button>

                                <Button type="submit" className="green" disabled={isSubmitting}>
                                    <span>Salvar</span>
                                    <FaRegSave size={16} />
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

                {!rules ? (
                    <TablePlaceholder>
                        <Spinner visible />
                    </TablePlaceholder>
                ) : (
                    <Table 
                        headers={[
                            { name: 'name', value: 'Regra' },
                            { name: 'query', value: 'Consulta' },
                            { name: 'template_name', value: 'Template' }
                        ]}
                        hideOnSmallHeaders={['query']}
                        data={rules}
                        limit={5}
                        filterable
                        copiable
                        truncateLength={40}
                        handleDelete={handleDeleteRule}
                    />
                )}
            </Container>

            <Container className="animated fadeIn delay-500ms exec-rules">
                <h1>Execução de Regra</h1>

                <Formik 
                    initialValues={execRuleInitialValues}
                    validationSchema={execRuleValidation}
                    onSubmit={handleExecRule}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form className="exec-rule-form">
                            <Select 
                                name="channel"
                                label="Selecione o canal de envio"
                                onChange={selected => onChangeChannel(selected, setFieldValue, values)}
                                options={[
                                    { value: 'sms', label: 'SMS' },
                                    { value: 'email', label: 'E-mail' },
                                    { value: 'both', label: 'SMS e E-mail' }
                                ]}
                            />

                            {values.channel.value && values.channel.value !== 'sms' && params?.from_email && (
                                <p className="tip">{`Remetente: ${params?.from_email}`}</p>
                            )}

                            <Select 
                                name="rule_id" 
                                label="Selecione uma regra" 
                                onChange={selected => onChangeRule(selected, setFieldValue, values)}
                                options={rules && rules.map(rule => ({ value: rule.id, label: rule.name }))}
                            />

                            {emailOnlyFieldsVisibility && (
                                <>
                                    <Select 
                                        name="layout_id" 
                                        label="Selecione um layout" 
                                        onChange={(selected, field) => setFieldValue(field.name, selected)}
                                        options={layouts && layouts.map(layout => ({ value: layout.id, label: layout.name }))}
                                        className="animated zoomIn faster"
                                    />

                                    <Textbox 
                                        label="Assunto do e-mail" 
                                        name="subject"
                                        className="animated zoomIn faster"
                                    />        
                                </>
                            )}

                            {sendMessagesCount !== null ? (
                                <p className="send-message-count">
                                    <span>Esta regra enviará a mensagem para </span>
                                    <b>{sendMessagesCount}</b>
                                    <span> 
                                        {` destinatário${sendMessagesCount > 1 ? 's' : ''}`}
                                    </span>
                                </p>
                            ) : (
                                <div className="spinner-container">
                                    <Spinner visible={processingMessagesCount} />
                                </div>
                            )}

                            <div className="actions">
                                {sentPercentage > 0 ? (
                                    <ProgressBar progress={sentPercentage} />
                                ) : (
                                    <span />
                                )}
                                
                                <Button type="submit" className="transparent" disabled={isSubmitting || !sendMessagesCount}>
                                    <span>Enviar</span>
                                    <FaTelegramPlane size={16} />
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}
