import React, { useState, useEffect, useCallback } from 'react'
import { FaSmileWink } from 'react-icons/fa'

import { Button } from '~/components/Form'

import api from '~/services/api'

import masks from '~/util/masks'

import { Container } from './styles'

export default function (props) {
    const {
        id, history, ...otherProps
    } = props

    window.scrollTo({ behavior: 'smooth', top: 40 })

    const [product, setProduct] = useState({})
    const [resources, setResources] = useState([])

    useEffect(() => {
        async function loadProduct() {
            const response = await api.get(`products/${id}`)

            // TEMPORÁRIO
            response.image.path = `${process.env.REACT_APP_BACKEND_URL}/files/${response.image.path}`
			
            const [integer, decimal] = response.price.split('.')
            const [parcelInteger, parcelDecimal] = response.parcel_price.split('.')
            response.price = `${integer}${parseInt(decimal) > 0 ? `,${decimal}` : ''}`
            response.parcel_price = `${parcelInteger}${parseInt(parcelDecimal) > 0 ? `,${parcelDecimal}` : ''}`

            const sections = response.full_description.match(/\d+ (>|\*>) .+/g)
            const features = response.full_description.match(/\d+ - .+/g)

            const resourceList = []
			
            if(sections) {
                for(const section of sections) {
                    const [i, text] = section.split('>')
                    const hided = section.indexOf('*>') >= 0
                    const index = parseInt(i)
	
                    resourceList.push({
                        id: index,
                        hided,
                        title: text.trim(),
                        resources: features.filter(r => r.indexOf(`${index} - `) === 0).map(r => r.split(' - ')[1])
                    })
                }
				
                setResources(resourceList)
            }

            const firstSectionIndex = response.succint_description.indexOf(' >')

            response.succint_description = firstSectionIndex >= 0 
                ? response.succint_description.slice(0, firstSectionIndex - 1)
                : response.succint_description

            setProduct(response)
        }
        
        loadProduct()
    }, [id])
	
    const handleClick = useCallback(() => {
        history.push({
            pathname: '/chatbots/order',
            state: { productId: id }
        })
    }, [history, id])

    return (
        <Container {...otherProps}>
            {!!product.name && (
                <>
                    <div className="card-content">
                        <div className="avatar-container">
                            <img src={product.image && product.image.path} alt="" />
                        </div>

                        <h1>{product.name}</h1>

                        <h2>
                            <span className="price">
                                {masks.maskApply.currency(product.price)}
                            </span>
                            R$ 
                            <span className="parcel-price">{product.parcel_price}</span>
                            {` / ${product.payment_period}`}
                            <span className="payment-times">{product.payment_times && product.payment_times === 1 ? '(à vista)' : `(${product.payment_times}x)`}</span>
                        </h2>

                        <p>{product.succint_description}</p>

                        <br />
                        
                        {resources && resources.map(resource => (
                            <div key={resource.id}>
                                {!resource.hided ? (
                                    <>
                                        <p className="resource-title">
                                            {resource.title}
                                        </p>

                                        <ul>
                                            {resource && resource.resources.map(r => (
                                                <li key={r}>{r}</li>
                                            ))}
                                        </ul>
                                    </>
                                ) : (
                                    <span className="view-more">
                                        {resource.title}
                                    </span>
                                )}
                            </div>
                        ))}

                    </div>

                    <div className="card-action center">
                        <Button onClick={handleClick} className="transparent" style={{ width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span>Contrate aqui</span>
                                <FaSmileWink size={24} style={{ marginLeft: 8 }} />
                            </div>
                        </Button> 
                    </div>
                </>
            )}

        </Container>
    )
}
