import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import AuthLayout from '~/pages/_layouts/Auth'
import DefaultLayout from '~/pages/_layouts/Default'
import EmptyLayout from '~/pages/_layouts/Empty'

export default function RouteWrapper({ 
    component: Component,
    isPrivate,
    withoutNavBar,
    ...rest
}) {
    const signed = sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN) 
        && sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER)

    if(!signed && isPrivate) {
        sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_TOKEN)
        sessionStorage.removeItem(process.env.REACT_APP_SESSION_STORAGE_USER)
        
        return <Redirect to="/signin" />
    }

    if(signed && !isPrivate) {
        return <Redirect to="/dashboard" />
    }

    const Layout = withoutNavBar ? EmptyLayout : signed ? DefaultLayout : AuthLayout

    return (
        <Route
            {...rest}
            component={props => (
                <Layout history={props.history} user={JSON.parse(signed)}>
                    <Component {...props} />
                </Layout>
            )}
        />
    )
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}

RouteWrapper.defaultProps = {
    isPrivate: false,
    component: () => <Redirect to="/" />
}
