import React, {
    useState, useCallback, useEffect 
} from 'react'
import { Link } from 'react-router-dom'
import {
    FaFolder, FaCoins 
} from 'react-icons/fa'
import { toast } from 'react-toastify'
import Spinner from '~/components/Spinner'
import PeopleManager from '~/components/PeopleManager'
import { Select } from '~/components/Form'

import api from '~/services/api'

import authHeaders from '~/util/authHeaders'

import SMSBox from '~/components/SMSBox'
import MessageTemplatesRules from '~/components/MessageTemplatesRules'

import {
    Container, FileManager, ChatbotTester, NavOptions
} from './styles'

export default function ({ user }) {
    const [contracts, setContracts] = useState([])
    const [selectedContract, setSelectedContract] = useState(null)
    const [loadingChatbotTester, setLoadingChatbotTester] = useState(false)

    useEffect(() => {
        async function loadContracts() {
            const data = await api.get('contracts?productId=1', authHeaders)

            if(!data) {
                toast.error('Erro ao obter a lista de assistentes.')
            }

            setContracts(data || [])
        }

        loadContracts()
    }, [])

    const handleAssistantSelect = useCallback(async selected => {
        const contractId = selected.value

        if(!contractId) {
            setSelectedContract(null)
            return
        }

        setSelectedContract(null)
        setLoadingChatbotTester(true)

        const contract = contracts.find(con => con.id === contractId)

        setLoadingChatbotTester(false)
        setSelectedContract(contract)
    }, [contracts])

    return (
        <Container>

            <NavOptions>
                <Link to="/query">
                    <FaCoins size={20} />
                    <span>Administrar consultas</span>
                </Link>

                <Link to="/files">
                    <FaFolder size={20} />
                    <span>Administrar arquivos</span>
                </Link>
            </NavOptions>
			
            <div className="col-1">
                <PeopleManager user={user} />

                <div className="horizontal-container">
                    <FileManager className="animated fadeIn delay-100ms">
                        <h1>Gerenciador de arquivos</h1>

                        <Link to="/files">
                            <FaFolder size={70} />
                            <span>Ir para o Gerenciador de Arquivos</span>
                        </Link>
                    </FileManager>

                    <ChatbotTester className="animated fadeIn delay-200ms">
                        <h1>Teste de Assistentes</h1>

                        <p>Selecione o assistente para carregá-lo.</p>

                        <Select 
                            name="assistant" 
                            label="Selecione o assistente" 
                            withoutForm 
                            onChange={handleAssistantSelect}
                            options={contracts.filter(contract => contract.product.category.id === 1).map(contract => ({ value: contract.id, label: contract.user.person.name }))}
                        />

                        <div className="button-container">
                            {selectedContract && (
                                <a 
                                    href={`assistantTester/${selectedContract.user.id}/${selectedContract.id}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="button transparent"
                                >
                                    Carregar assistente
                                </a>
                            )}

                            {loadingChatbotTester && (
                                <div className="spinner-container">
                                    <Spinner />
                                </div>
                            )}
                        </div>
                    </ChatbotTester>
                </div>

                <SMSBox className="delay-300ms" />
            </div>

            <div className="col-2">
                <MessageTemplatesRules user={user} />
            </div>

        </Container>
    )
}
