import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './Route'

import Main from '~/pages/Main'
import SignIn from '~/pages/SignIn'
import SignUp from '~/pages/SignUp'
import Newsletter from '~/pages/Newsletter'
import Magazine from '~/pages/Magazine'
import Product from '~/pages/Product'
import Catalog from '~/pages/Product/Catalog'
import Chatbots from '~/pages/Product/Chatbots'
import ChatbotOrder from '~/pages/Product/Chatbots/Order'

import Files from '~/pages/AdminViews/Files'
import Payment from '~/pages/AdminViews/Payment'
import Query from '~/pages/AdminViews/Query'
import AssistantTester from '~/pages/AdminViews/AssistantTester'
import Mensageria from '~/pages/AdminViews/Mensageria'
import People from '~/pages/AdminViews/People'

import Dashboard from '~/pages/UserViews/Dashboard'
import DashboardChatbot from '~/pages/UserViews/Chatbot/Dashboard'
import UserProfile from '~/pages/UserViews/UserProfile'
import ProductManager from '~/pages/UserViews/ProductManager'
import QuestionManager from '~/pages/UserViews/Chatbot/QuestionManager'
import DashboardMensageria from '~/pages/UserViews/Mensageria/Dashboard'
import MensageriaConverter from '~/pages/UserViews/Mensageria/Converter'


export default function Routes() {
    return (
        <Switch>
            <Route path="/" component={Main} exact />

            <Route path="/signin" component={SignIn} />
            <Route path="/signup" component={SignUp} />

            <Route path="/magazine" component={Magazine} />
            <Route path="/product/:id" component={Product} />

            <Route path="/chatbots" exact component={Chatbots} isPrivate />
            <Route path="/chatbots/order" component={ChatbotOrder} isPrivate />
            
            <Route path="/newsletter" component={Newsletter} />
            
            <Route path="/dashboard" component={Dashboard} isPrivate />

            <Route path="/assistantTester/:userId/:contractId" component={AssistantTester} isPrivate />

            <Route path="/mensageria" component={Mensageria} isPrivate />

            <Route path="/people" component={People} isPrivate />

            <Route path="/profile" component={UserProfile} isPrivate />

            <Route path="/user/chatbot" exact component={DashboardChatbot} isPrivate />
            <Route path="/user/mensageria" component={DashboardMensageria} isPrivate />
            <Route path="/user/mensageria_converter" component={MensageriaConverter} isPrivate />
            <Route path="/user/gestao-produtos" component={ProductManager} isPrivate />

            <Route path="/user/chatbot/question_manager" component={QuestionManager} isPrivate />

            <Route path="/files" component={Files} isPrivate />

            <Route path="/payment" component={Payment} withoutNavBar isPrivate />

            <Route path="/catalog" component={Catalog} isPrivate />

            <Route path="/query" component={Query} isPrivate />

            <Route path="*" />
        </Switch>
    )
}
