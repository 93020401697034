import React, {
    useEffect, useState, useCallback 
} from 'react'
import { Formik, Form } from 'formik'
import { format } from 'date-fns'
import cep from 'cep-promise'
import { toast } from 'react-toastify'
import {
    FaSave, FaUser, FaEnvelope, FaPhone, FaLock, FaCalendar, FaAddressCard 
} from 'react-icons/fa'
import { GiClick } from 'react-icons/gi'

import api from '~/services/api'
import yup from '~/services/yup'

import authHeaders from '~/util/authHeaders'
import masks from '~/util/masks'
import regex from '~/util/regex'
import ufs from '~/util/states'
import dateUtils from '~/util/date'

import Spinner from '~/components/Spinner'
import {
    Textbox, Textarea, Select, Button, File 
} from '~/components/Form'

import {
    Container, SpinnerContainer, ButtonContainer, AvatarContainer 
} from './styles'

const globalValidation = yup.object({
    name: yup.string().required('O nome é obrigatório'),
    alias: yup.string().required('Insira um apelido'),
    email: yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
    phone: yup.string().mobileOrPhone('Fone inválido').required('O celular é obrigatório'),
    identifier: yup.string().notRequired(),
    
    zipcode: yup.string().cep('CEP inválido').notRequired(),
    country: yup.string().notRequired(),
    state: yup.string().uf('UF inválida.').notRequired(),
    city: yup.string().notRequired(),
    neighborhood: yup.string().notRequired(),
    street: yup.string().notRequired(),
    number: yup.string().notRequired(),
    complement: yup.string().notRequired(),

    password: yup.string().notRequired(),
    'new-password': yup.string()
        .when('password', {
            is: ref => !!ref,
            then: yup.string().required('Digite uma nova senha').min(6, 'A senha deve conter pelo menos 6 caracteres'),
            otherwise: yup.string().notRequired()
        }),
    'new-password-confirm': yup.string()
        .when('new-password', {
            is: ref => !!ref,
            then: yup.string().required('Confirme sua nova senha').oneOf([yup.ref('new-password')], 'As senhas não coincidem'),
            otherwise: yup.string().notRequired()
        })
})

const onlyPJValidation = yup.object({
    site: yup.string().site('Endereço inválido').required('O nome é obrigatório'),
    mission: yup.string().notRequired(),
    vision: yup.string().notRequired()
})

const onlyPFValidation = yup.object({
    birthdate: yup.string().date('Data inválida').notRequired()
})

export default function UserProfile({ history }) {
    const [user, setUser] = useState(null)
    const [personType, setPersonType] = useState(null)
    const [validation, setValidation] = useState(globalValidation)
    const [initialValues, setInitialValues] = useState({
        name: '',
        alias: '',
        email: '',
        phone: '',
        identifier: '',
        site: '',
        mission: '',
        vision: '',
        zipcode: '',
        country: '',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
        password: '',
        'new-password': '',
        'new-password-confirm': ''
    })

    // Zipcode event
    useEffect(() => {
        setTimeout(() => {
            const zipcodeField = document.querySelector('input[name=zipcode]')

            try {
                zipcodeField.addEventListener('keyup', async (e) => {
                    const { value } = e.target
    
                    if(new RegExp(regex.cep).test(value)) {
                        try {
                            const addressData = await cep(value)
    
                            const {
                                state, city, neighborhood, street 
                            } = addressData
    
                            setInitialValues({
                                ...initialValues,
                                zipcode: value,
                                country: 'Brasil',
                                state: { value: state, label: state },
                                city,
                                neighborhood,
                                street
                            })
                        } catch(err) { }
                    }
                }) 
            } catch(e) { }
        }, 2000)
    }, [initialValues])
    
    // Load user
    useEffect(() => {
        async function loadUser() {
            const response = await api.get('users', authHeaders)

            const {
                alias, 
                person: {
                    type = 'PF', name, email, phone, identifier, birthdate, site, mission, vision, zipcode, country, state, city, neighborhood, street, number, complement 
                }
            } = response

            setUser(response)
            setPersonType(type)

            setInitialValues({
                name, 
                alias,
                email, 
                phone,
                identifier: identifier || '',
                birthdate: birthdate ? format(new Date(birthdate), 'dd/MM/yyyy') : '',
                site: site || '',
                mission: mission || '',
                vision: vision || '',
                zipcode: zipcode || '',
                country: country || '',
                state: { value: state, label: state },
                city: city || '',
                neighborhood: neighborhood || '',
                street: street || '',
                number: number || '',
                complement: complement || '',
                password: '', 
                'new-password': '',
                'new-password-confirm': ''
            })
        }

        loadUser()
    }, [])

    useEffect(() => {
        setValidation(globalValidation.concat(personType === 'PF' ? onlyPFValidation : onlyPJValidation))
    }, [personType])

    const handleSubmit = useCallback(async (values, { setSubmitting }) => {
        const data = { 
            ...values, 
            state: values.state.value,
            birthdate: dateUtils.getDateFromString(values.birthdate)
        }

        try {
            await api.put('users', data, authHeaders)

            setSubmitting(false)

            toast.success('Os dados foram atualizados')

            history.push('/')
        } catch(e) {
            toast.error(e.msg || 'Ocorreu um erro ao tentar salvar os dados.')
        }
    }, [])

    const getPreloadImage = useCallback(async () => {
        const { avatar: image } = await api.get('users', authHeaders)

        return image?.path
    }, [])

    const updateAvatar = useCallback(async fileId => {
        await api.put('users', { avatar_id: fileId }, authHeaders)

        toast.success('Avatar atualizado.')
    }, [])

    return (
        <Container>
            <section className="animated zoomIn faster">
                <h1>Perfil de usuário</h1>

                {user ? (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ isSubmitting, setFieldValue }) => (
                            <Form>
                                {personType === 'PF' ? (
                                    <h2>Dados pessoais</h2>
                                ) : (
                                    <h2>Dados da empresa</h2>
                                )}

                                <div className="field-container">
                                    <AvatarContainer>
                                        <File 
                                            getPreloadImage={getPreloadImage}
                                            onSuccess={updateAvatar}
                                            accept={['image/*']}
                                            placeholderText="Selecione um avatar"
                                        />
                                    </AvatarContainer>

                                    <Textbox name="name" label="Nome" icon={{ Icon: FaUser }} />

                                    <Textbox name="alias" label="Apelido" icon={{ Icon: FaUser }} />

                                    <Textbox name="email" label="E-mail" icon={{ Icon: FaEnvelope }} />

                                    <Textbox name="phone" label="Fone" mask={masks.mobile} icon={{ Icon: FaPhone }} />

                                    <Textbox 
                                        name="identifier" 
                                        label={personType === 'PF' ? 'CPF' : 'CNPJ'} 
                                        mask={personType === 'PF' ? masks.cpf : masks.cnpj}
                                        containerClass="field"
                                        icon={{ Icon: FaAddressCard }}
                                    />

                                    {personType === 'PF' ? (
                                        <Textbox 
                                            name="birthdate" 
                                            label="Data de nascimento"
                                            mask={masks.date} 
                                            icon={{ Icon: FaCalendar }}
                                            containerClass="field"
                                        />
                                    ) : (
                                        <>
                                            <Textbox name="site" label="Site" icon={{ Icon: GiClick }} />

                                            <Textarea name="mission" label="Missão" />

                                            <Textarea name="vision" label="Visão" />
                                        </>
                                    )}
                                </div>

                                <h2>Endereço do seu negócio</h2>

                                <div className="field-container">
                                    <Textbox name="zipcode" label="CEP" mask={masks.zipcode} />

                                    <Textbox name="country" label="País" />

                                    <Select 
                                        name="state" 
                                        label="UF"
                                        onChange={selected => setFieldValue('state', selected)}
                                        options={ufs}
                                        containerClass="field"
                                    />
                                    
                                    <Textbox name="city" label="Cidade" />

                                    <Textbox name="neighborhood" label="Bairro" />

                                    <Textbox name="street" label="Logradouro" />

                                    <Textbox name="number" label="Número" />

                                    <Textbox name="complement" label="Complemento" />
                                </div>

                                <h2>Trocar senha de acesso</h2>

                                <div className="field-container">
                                    <Textbox name="password" type="password" label="Senha atual" icon={{ Icon: FaLock }} />

                                    <Textbox name="new-password" type="password" label="Nova senha" icon={{ Icon: FaLock }} />

                                    <Textbox name="new-password-confirm" type="password" label="Confirme a nova senha" icon={{ Icon: FaLock }} />
                                </div>

                                <ButtonContainer>
                                    <Button type="submit" className="green" disabled={isSubmitting}>
                                        <span>Salvar</span>
                                        <FaSave size={20} />
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <SpinnerContainer>
                        <Spinner size={60} />
                    </SpinnerContainer>
                )}	
            </section>
        </Container>
    )
}
