import React, {
    useEffect, useRef, useState, useCallback, useMemo 
} from 'react'
import { Formik, Form } from 'formik'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { toast } from 'react-toastify'

import {
    Button, Textbox, Select 
} from '~/components/Form'
import CreditCard from '~/components/CreditCard'
import Spinner from '~/components/Spinner'

import api from '~/services/api'
import yup from '~/services/yup'

import useEventListener from '~/hooks/useEventListener'

import masks from '~/util/masks'
import regex from '~/util/regex'
import stringUtils from '~/util/string'

import {
    Container, FormContainer, PriceContainer, PaidLabel 
} from './styles'

import mastercard from '~/assets/img/payment/mastercard.png'
import visa from '~/assets/img/payment/visa.png'
import diners from '~/assets/img/payment/diners.png'
import elo from '~/assets/img/payment/elo.png'
import amex from '~/assets/img/payment/amex.png'
import authHeaders from '~/util/authHeaders'

const { Iugu } = window

const brandList = {
    mastercard,
    visa,
    diners,
    elo,
    amex
}

const validation = yup.object().shape({
    parcels: yup.string().required('Selecione a quantidade de parcelas.'),
    name: yup.string().required('Campo obrigatório'),
    cardNumber: yup.string().required('Campo obrigatório').creditCard(),
    date: yup.string().required('Campo obrigatório').shortDate('A data deve estar no formato MM/AA.').future(),
    code: yup.string().required('Campo obrigatório').matches(regex.cvv, 'O CVV tem 3 dígitos.'),
    email: yup.string().email('E-mail inválido').required('Campo obrigatório')
})

const initialValues = {
    name: '',
    cardNumber: '',
    date: '',
    code: '',
    parcels: { value: 1, label: 'à vista' },
    email: ''
}

export default function ({
    onPaid, product, isPaid = false
}) {
    const formRef = useRef()

    const [brand, setBrand] = useState(null)
    const [paid, setPaid] = useState(false)

    useEffect(() => Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID), [])

    useEffect(() => setPaid(isPaid), [isPaid])
    
    const defineBrand = useCallback(e => {
        const cardNumber = e.target?.value?.replace(/ /g, '')

        if(!cardNumber) return

        const brandName = Iugu.utils.getBrandByCreditCardNumber(cardNumber)

        setBrand(brandList[brandName])
    }, [])
	
    const handleSubmit = useCallback((data, { setSubmitting, resetForm }) => {
        const tokenResponseHandler = function (dataIugu) {
            if (dataIugu.errors) {
                for(let i = 0; i < dataIugu.errors.length; i++) {
                    if(dataIugu.errors[i] === 'last_name') { 
                        dataIugu.errors[i] = 'full_name' 
                    }

                    console.log(`Erro no campo ${dataIugu.errors[i]}`)
                }

                setSubmitting(false)
            } else {
                const paymentData = {
                    token: dataIugu.id,
                    parcels: parseInt(data.parcels.value),
                    email: data.email,
                    quantity: 1,
                    product_id: product?.id,
                    value: product?.price,
                    test_mode: false
                }

                if(!paymentData.value) {
                    toast.error('Erro ao definir valor do pagamento.')
                    throw new Error('Erro ao definir valor do pagamento.')
                }

                const pay = async function () {
                    const response = await api.post('payments', paymentData, authHeaders)

                    if(response.invoice) {
                        resetForm(initialValues)
                        setPaid(true)
                        onPaid(true)
                        toast.success(response.msg)
                    } else if(response.error) {
                        toast.error(response.msg)
                    }

                    setSubmitting(false)
                }

                pay()
            }
        }
		
        setTimeout(() => {
            try {
                Iugu.createPaymentToken(formRef.current, tokenResponseHandler)
            } catch(err) {
                setSubmitting(false)
                toast.error('Ocorreu um erro no pagamento.')
                toast.info('Verifique seu limite e a possibilidade de bloqueio do cartão.')
            }
        }, 700)
    }, [product, onPaid, isPaid])
    
    const parcelOptions = useMemo(() => {
        const options = []

        for(let i = 1; i <= product?.payment_times; i++) {
            options.push({ value: i, label: i === 1 ? 'à vista' : `${i}x` })
        }

        return options
    }, [product])

    useEventListener('keyup', defineBrand, document.getElementsByName('cardNumber')[0])
	
    return (
        <Container>
            <Formik 
                onSubmit={handleSubmit} 
                validationSchema={validation} 
                initialValues={initialValues} 
                enableReinitialize
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form ref={formRef} style={{ display: paid ? 'none' : 'flex' }}>
                        <CreditCard 
                            name={values.name}
                            number={values.cardNumber}
                            expiration={values.date}
                            cvv={values.code}
                            brand={brand}
                        />

                        <PriceContainer>
                            <div className="icon">
                                <FaFileInvoiceDollar size={54} />
                            </div>
                            <span>Valor da compra</span>
                            <span>Parcelamento</span>
                            <span className="price">{stringUtils.moneyFormat(product?.price)}</span>
                            <span className="parcels">{`Até ${product?.payment_times}x`}</span>
                        </PriceContainer>

                        <FormContainer>
                            <Textbox 
                                name="name" 
                                label="Nome como consta no cartão*"
                                style={{ textTransform: 'uppercase' }}
                                data-iugu="full_name"
                                disabled={paid}
                            />

                            <Textbox 
                                name="cardNumber" 
                                label="Número do cartão*"
                                mask={masks.cardNumber}
                                data-iugu="number"
                                disabled={paid}
                            />

                            <Textbox 
                                name="date" 
                                label="Vencimento*"
                                mask={masks.shortDate}
                                data-iugu="expiration"
                                disabled={paid}
                            />

                            <Textbox 
                                name="code" 
                                label="CVV*"
                                mask={masks.cvv}
                                data-iugu="verification_value"
                                disabled={paid}
                            />

                            <Select 
                                name="parcels" 
                                label="Selecione o parcelamento"
                                onChange={(selected) => setFieldValue('parcels', selected)}
                                options={parcelOptions} 
                                isDisabled={paid}
                            />
                           
                            <Textbox 
                                name="email" 
                                label="E-mail para recebimento da fatura*"
                                style={{ textTransform: 'lowercase' }}
                                disabled={paid}
                            />
                            

                            <Button type="submit" className="green" disabled={isSubmitting || paid}>
                                {isSubmitting ? (
                                    <Spinner
                                        visible={isSubmitting}
                                        color="#FFF"
                                    />
                                ) : (
                                    <span>Efetuar pagamento</span>
                                )}
                            </Button>

                        </FormContainer>
                    </Form>
                )}
            </Formik>

            {paid && (
                <PaidLabel>
                    <h1>PAGAMENTO JÁ EFETUADO!</h1>
                    <span>Obrigado!</span>
                </PaidLabel>
            )}

        </Container>
    )
}
