import React, { useState, useEffect } from 'react'
import { FaExchangeAlt } from 'react-icons/fa'

import api from '~/services/api'

import Chatbot from '~/components/Products/ChatbotProfile/Chatbot'

import {
    Container, SmallContainer, LargeContainer, NavigationTip 
} from './styles'

export default function Chatbots({ history }) {
    const [products, setProducts] = useState([])

    useEffect(() => {
        async function loadProducts() {
            const response = await api.get('products', {
                params: { category_id: 1 }
            })

            for(const product of response) {
                product.image.path = `${process.env.REACT_APP_BACKEND_URL}/files/${product.image.path}`
            }
            
            setProducts(response)

            document.querySelector('nav').scrollIntoView({ behavior: 'smooth' })
        }
        
        loadProducts()
    }, [])

    return (
        <Container>

            {products?.length > 0 && (
                <>
                    {products.length > 1 && (
                        <NavigationTip>
                            <p>Arraste para os lados e conheça nossos planos.</p>
                            <FaExchangeAlt size={24} />
                        </NavigationTip>
                    )}

                    <SmallContainer>
                        <div className="chatbots">
                            {products && products.map((product, i) => (
                                <div key={product.id}>
                                    <Chatbot 
                                        id={product.id} 
                                        className={`delay-${200 + 100 * i}ms`}
                                        history={history}
                                    />
                                </div>    
                            ))}
                        </div>
                    
                    </SmallContainer>

                    <LargeContainer>
                        {products && products.map((product, i) => (
                            <Chatbot 
                                key={product.id}
                                id={product.id} 
                                className={`delay-${200 + 100 * i}ms`}
                                history={history}
                            />
                        ))}
                    </LargeContainer>
                </>
            )}
        </Container>
    )
}
