import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import api from '~/services/api'

import authHeaders from '~/util/authHeaders'

import useScript from '~/hooks/useScript'

import { Container, AssistantInfoContainer } from './styles'

export default function AssistantTester() {
    const { userId, contractId } = useParams()

    const [assistant, setAssistant] = useState(null)

    useScript(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_CHAT_URL_DEV : process.env.REACT_APP_CHAT_URL}/assistantCustomizedFile?userId=${userId}&autoOpen=true&debug=true`)
	
    useEffect(() => {
        async function loadAssistantInfo() {
            const contract = await api.get(`contracts/${contractId}`, authHeaders)
			
            setAssistant(contract)
        }
		
        loadAssistantInfo()
    }, [contractId])

    return (
        <Container>

            <section>
                <h1>Teste de Assistente Virtual</h1>

                <AssistantInfoContainer>
                    <span>Cliente: </span>
                    <b>{assistant?.user.person.name}</b>
                </AssistantInfoContainer>

                <AssistantInfoContainer>
                    <span>Tipo de produto: </span>
                    <b>{assistant?.product.category.name}</b>
                </AssistantInfoContainer>

                <AssistantInfoContainer>
                    <span>Produto: </span>
                    <b>{assistant?.product.name}</b>
                </AssistantInfoContainer>

            </section>
            
        </Container>
    )
}
