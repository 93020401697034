import React from 'react'
import { MdError, MdLink } from 'react-icons/md'
import Spinner from '~/components/Spinner'

import {
    Container, FileInfo, FileActions, Preview 
} from './styles'

const FileList = ({ files, onDelete }) => (
    <Container>
        {files.map(uploadedFile => (
            <li key={uploadedFile.id}>
                
                <FileInfo>
                    <Preview src={uploadedFile.preview || ''} />

                    <div>
                        <strong>{uploadedFile.name}</strong> 
                        <span>
                            {!!uploadedFile.url && <button onClick={() => onDelete(uploadedFile.id)}>Excluir</button>}
                        </span>
                    </div>
                </FileInfo>

                <FileActions>
                    {!uploadedFile.uploaded && !uploadedFile.error && (
                        <Spinner />
                    )}

                    {uploadedFile.url && (
                        <a
                            href={`${process.env.REACT_APP_BACKEND_URL}/files/${uploadedFile.url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="file-link"
                        >
                            <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
                        </a>
                    )}

                    {uploadedFile.error && <MdError size={24} color="#e57878" />}
                    
                </FileActions>
            </li>
        ))}
    </Container>
)

export default FileList
