import React, {
    useState, useCallback, useRef 
} from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import {
    FaSave, FaTag, FaRegFileAlt, FaChair 
} from 'react-icons/fa'

import api from '~/services/api'
import yup from '~/services/yup'

import masks from '~/util/masks'
import inputUtils from '~/util/input'

import Spinner from '~/components/Spinner'

import {
    Textbox, Textarea, Button, File 
} from '~/components/Form'

import { Container, AvatarContainer } from './styles'
import authHeaders from '~/util/authHeaders'

const validation = yup.object({
    name: yup.string().required('Especifique o nome do produto'),
    description: yup.string().notRequired(),
    price: yup.string().required('Especifique o valor do produto').money('Valor inválido')
})

const initialValues = {
    name: '',
    description: '',
    price: ''
}

export default function ProductManager() {
    const [productImageId, setProductImageId] = useState(null)

    const fileRef = useRef()

    const onUploadImage = useCallback(fileId => {
        setProductImageId(fileId)
    }, [])
	
    const handleSubmit = useCallback(async (values, { resetForm }) => {
        const product = {
            ...values,
            image_id: productImageId
        }
		
        await api.post('client_products', product, authHeaders)
		
        toast.success('O produto foi cadastrado.')

        resetForm()
        setProductImageId(null)

        fileRef.current.querySelector('.file-select-area').style.backgroundImage = 'unset'
        fileRef.current.querySelector('.file-select-area p').classList.remove('with-preview')
    }, [productImageId])
   
    return (
        <Container>
            <section>
                <h1>Gerenciamento de Produtos</h1>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
							
                            <AvatarContainer>
                                <File 
                                    accept={['image/*']}
                                    placeholderText="Selecione uma imagem"
                                    format="square"
                                    onSuccess={onUploadImage}
                                    ref={fileRef}
                                />
                            </AvatarContainer>

                            <Textbox 
                                name="name" 
                                label="Nome do produto" 
                                icon={{ Icon: FaChair }}
                            />

                            <Textarea 
                                name="description" 
                                label="Detalhes do produto" 
                                icon={{ Icon: FaRegFileAlt }} 
                            />

                            <Textbox 
                                name="price" 
                                label="Preço do produto"
                                mask={masks.money} 
                                onBlur={e => setFieldValue(e.target.name, inputUtils.fillMoney(e))}
                                icon={{ Icon: FaTag }}
                            />

                            {isSubmitting ? (
                                <Spinner />
                            ) : (
                                <Button type="submit" className="green" disabled={isSubmitting}>
                                    <span>Cadastrar</span>
                                    <FaSave size={20} />
                                </Button>
                            )}
                        </Form>
                    )}
                </Formik>


            </section>
        </Container>
    )
}
