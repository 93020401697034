import styled from 'styled-components'
import { lighten } from 'polished'

export const Container = styled.article`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 15px;
	height: fit-content;
	padding: 35px;

	div.col-1 {
		grid-column: 1/2;
	}
	
	div.col-2 {
		grid-column: 2/3;
	}

	@media(max-width: 850px) {
		padding: 10px 0 25px;
		display: flex;
		flex-direction: column;
	}

	section {
		width: 100%;
		background: #1D3B3B;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		border-radius: 2px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 35px;
		margin-bottom: 15px;

		h1 {
			width: 100%;
			font-size: 22px;
			margin-bottom: 18px;
		}

		form {
			width: 100%;

			input, textarea {
				width: 100%;
			}

			div.actions {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}

			.tip {
				font-size: 11px;
			}

			.send-message-count {
				background: ${lighten(0.05, '#1D3B3B')};
				padding: 10px;
				margin-bottom: 4px;
				border-radius: 4px;

				b {
					font-size: 18px;
				}
			}
		}
	}
`

export const PlaceholderChart = styled.div`
	width: 100%;
	height: 70px;
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`
