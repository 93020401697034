import React, { useMemo } from 'react'

import useScript from '~/hooks/useScript'

import ChatbotCarousel from '~/components/ChatbotCarousel'
import NavOptions from '~/components/NavOptions'

import { Container } from './styles'

export default function DashboardChatbot({ location }) {
    const user = useMemo(() => location.state ? location.state.user : null, [])
	
    useScript(`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_CHAT_URL_DEV : process.env.REACT_APP_CHAT_URL}/assistantCustomizedFile?userId=${user?.id}&autoOpen=true`)
	
    return (
        <>
            <NavOptions type="chatbot" />

            <Container>
                <ChatbotCarousel className="animated fadeIn delay-200ms" />
            </Container>
        </>
    )
}
