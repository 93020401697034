import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
`

export const Card = styled.div`
	background: #ffd740;
	color: #424242;
	width: 300px;
	height: 178px;
	padding: 10px 18px;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	font-family: 'Halter', Arial, sans-serif;

	.name {
		font-size: 13px;
		margin: -8px 10px 15px 10px;
		text-transform: uppercase;
	}

	.number {
		font-size: 14px;
		background: rgba(255, 255, 255, 0.7);
		padding: 8px;
		display: flex;
		justify-content: center;
	}

	.brand {
		display: flex;
		justify-content: flex-end;
	}

	.brand > img,
	.brand-placeholder {
		width: 48px;
		height: 48px;
	}

	footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.expiration > span {
		font-size: 8px;
		display: flex;
		justify-content: flex-start;
	}

	.expiration,
	.cvv {
		font-size: 12px;
		margin: 15px 10px;
		display: flex;
		justify-content: flex-start;
	}

	.expiration {
		flex-direction: column;
	}

	.cvv {
		flex-direction: row;
		align-items: center;

		svg {
			flex-grow: 1;
		}

		div {
			flex-grow: 4;
			display: flex;
			flex-direction: column;

			span {
				font-size: 8px;
			}
		}
	}

	@media (max-width: 600px) 
	{
		.name {
			font-size: 10px;
			margin: 4px 10px 15px 4px;
		}

		.number
		{
			font-size: 12px;
			padding: 6px;
		}
		
		.brand > img,
		.brand-placeholder {
			width: 37px;
			height: 37px;
		}
	}
`
