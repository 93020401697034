import React from 'react'
import {
    FaLaptopCode, FaPhoneSquare, FaHandshake, FaWhatsapp, FaNewspaper 
} from 'react-icons/fa'
import { Link } from 'react-router-dom'

import {
    Footer, Content, Logo, List, FootBar 
} from './styles'

import logo from '~/assets/img/footer/logo-extend.png'
import amon from '~/assets/img/footer/amon-logo.png'

export default function () {
    return (
        <Footer>

            <Content>
                <Logo>
                    <img src={logo} alt="" />
                </Logo>
                
                <List>
                    <h2>
                        <FaLaptopCode size={20} />
                        Nosso time
                    </h2>

                    <ul>
                        <li><a href="https://www.linkedin.com/in/adozindoneto/">Adozindo Neto (CEO)</a></li>
                        <li><a href="https://www.linkedin.com/in/felipe-leite-a96374109/">Felipe Leite (developer)</a></li>
                        <li><a href="https://www.linkedin.com/in/manuely-guedes-7a413942/">Manuely Guedes (developer)</a></li>
                        <li><a href="https://portal.robot.rio.br">Marilene Rocha (chatbot teacher)</a></li>
                    </ul>
                </List>

                <List>
                    <h2>
                        <FaPhoneSquare size={20} />
                        Contatos
                    </h2>

                    <ul>
                        <li><a href="contato@robot.rio.br">contato@robot.rio.br</a></li>
                        <li>
                            (21) 98250-0361
                            <FaWhatsapp size={16} />
                        </li>
                    </ul>
                </List>
            </Content>

            <FootBar>
                <p dangerouslySetInnerHTML={{ __html: `&copy; ${new Date().getFullYear()} Robot Genesis, All rights reserved` }} />
            </FootBar>

        </Footer>
    )
}
