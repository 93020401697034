import styled from 'styled-components'
import { lighten } from 'polished'

export const Container = styled.section`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 35px;
	
	h1 {
		width: 100%;
		font-size: 22px;
		margin-bottom: 18px;
	}

	form {
		width: 100%;

		input, textarea {
			width: 100%;
		}

		div.actions {
			width: 100%;
			display: flex;
			justify-content: space-between !important;
			align-items: center;

			button {
				width: 95px;
			}
		}

		.tip {
			font-size: 11px;
			padding: 0 4px 10px;
		}

		.send-message-count {
			background: ${lighten(0.05, '#1D3B3B')};
			padding: 10px;
			margin-bottom: 4px;
			border-radius: 4px;

			b {
				font-size: 18px;
			}
		}

		.spinner-container {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}
`

export const TablePlaceholder = styled.div`
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70px;
	width: 100%;
`
