import styled from 'styled-components'

export const Container = styled.article`
	min-height: 80vh;
	padding: 34px 0 50px;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: auto 360px auto 360px auto;
	grid-template-rows: 1fr;
	justify-items: center;

	section:first-child {
		grid-column: 2/3;
		grid-row: 1/2;
	}

	section:last-child {
		grid-column: 4/5;
		grid-row: 1/2;
	}

	@media(max-width: 1000px) {
		grid-template-columns: auto 300px 300px auto;

		section:first-child {
			grid-column: 2/3;
		}

		section:last-child {
			grid-column: 3/4;
		}
	}

	@media(max-width: 750px) {
		padding: 34px 0 50px;
		grid-template-columns: auto 230px 230px auto;
	}

	@media(max-width: 600px)  {
		display: flex;
		flex-direction: column;
		
		section {
			margin: 5px 0;
		}
	}
`
