import React, { memo } from 'react'

import Header from '~/components/Header'
import Footer from '~/components/Footer'

const DefaultLayout = memo(({ children, history, user }) => (
    <>
        <Header history={history} user={user} />
            
        {children}

        <Footer />
    </>
))

export default DefaultLayout
