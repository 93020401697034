import styled from 'styled-components'

export const Navbar = styled.nav`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	background: #1F3A2A ;
	padding: 0 30px;
	border-bottom: solid 3px rgba(0, 0, 0, 0.3);

	@media (max-width: 550px) {
		padding: 0 10px;
	}

	img {
		margin-right: 20px;
		height: 54px;
		width: 94px;
		padding: 10px;

		@media (max-width: 550px) {
			height: 50px;
			width: 85px;
		}
	}
`

export const MenuContainer = styled.div`
	aside {
		display: flex;
		justify-content: space-between;

		button {
			background: transparent;
			border: none;
			padding: 0 12px;
			cursor: pointer;
			color: #ddd;
			font-weight: 400;

			&:hover {
				color: #eee;
				background: rgba(0, 0, 0, 0.1);
			}
		}

		a {
			font-weight: 400;
			color: #ddd;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			transition-duration: 0.3s;
			height: 54px;
			padding: 0 12px;

			&:hover {
				color: #eee;
				background: rgba(0, 0, 0, 0.1);
			}

			@media (max-width: 550px) {
				font-size: 10px;
				max-width: 50px;
			}
		}
	}

	@media (max-width: 550px) {
		aside {
			display: none;
		}

		.menu-toggle {
			display: block;
			width: 30px;
			margin-right: 10px;
			z-index: 1;

			.one, .two, .three {
				background: #fff;
				height: 3px;
				width: 100%;
				border-radius: 4px;
				margin: 6px auto;
				transition-duration: 0.3s;
			}
		}

		&.on {
			position: absolute;
			top: 0;
			left: 0;
			background: #162b1f;
			width: 100vw;
			height: 100vh;
			z-index: 10000;

			aside {
				height: 100vh;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				overflow-y: scroll;

				> a:first-child {
					margin-top: 100px;
				}

				a, button {
					display: block;
					align-items: center;
					justify-content: center;
					text-align: center;
					transition: all 0.3s;
					max-width: unset;
					width: 100%;
					height: unset;
					padding: 0 12px;
					text-align: center;
					font-size: 20px;
					line-height: 30px;
					padding: 24px;
					margin: 8px 0;
					color: #eee;
				}
			}

			.menu-toggle { 
				position: absolute;
				right: 15px;
				top: 18px;

				.one {
					transform: rotate(45deg) translate(4px, 6px);
				}

				.two {
					opacity: 0;
				}

				.three {
					transform: rotate(-45deg) translate(7px, -9px);
				}
			}
		}
	}
`
