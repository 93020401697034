import styled from 'styled-components'

import vectorCut from '~/assets/img/corte.png'

export const Features = styled.section`
	background: #FFF;
	padding: 24px 30px;
	display: flex;
	flex-wrap: wrap;
	margin-top: -2px;

	& > div {
		font-size: 16px;
	}
`

export const Feature = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	margin: 20px;

	img {
		width: auto;
		height: 100px;
		margin-right: 15px;
		border-radius: 50%;
	}

	div {
		max-width: 220px;
		width: 100%;

		@media (max-width: 800px) {
			& {
				max-width: 560px;
			}
		}

		h2 {
			font-size: 16px;
			font-weight: 600;
			padding-bottom: 10px;
			color: #2c2c2c;
		}

		p {
			font-size: 14px;
			line-height: 20px;
			color: rgba(0, 0, 0, 0.7);
		}
	}

	@media (max-width: 500px) {
		img {
			display: none;
		}	
	}
`

export const Extra = styled.section`
	background: #FFF;
	display: flex;
	flex-direction: column;
	margin-top: -2px;

	div.vector-cut {
		background: url(${vectorCut});
		background-size: 100% 100%;
		height: 70px;
	}
`

export const DemoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	background: #F4F4F4;
	margin-top: -2px;

	@media (max-width: 500px) {
		display: block;
	}
`

export const ImageContainer = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 450px;
		height: 340px;
	}

	/* video {
		position: relative;
		margin-left: -308px;
		margin-top: -28px;
		height: 364px;
		border-radius: 10px;
		transform: rotate3d(1.1, 0.7, 1.1, -66deg);
	} */

	@media (max-width: 900px) {
		width: 100%;

		img {
			width: 339px;
		}

		/* video {
			height: 258px;
			position: absolute;
			margin-left: 6px;
			margin-top: -45px;
			transform: rotate3d(1.1, 0.7, 0.9, -76deg);
		} */
	}
`

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 20px 40px;
	margin-top: -20px;

	p {
		color: #2c2c2c;
		font-size: 16px;
		line-height: 26px;
		margin-top: 36px;
	}

	.button-container {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: ${props => props.showMessage ? '15px 0' : '50px 0'};

		button {
			width: 100%;
			height: 60px;
			max-width: 200px;
			font-weight: 800;
			border-radius: 4px;
			transition: all 0.3s;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 16px;
			margin: 0 !important;
		}

		.tip {
			margin-top: 20px;
			background: rgba(0, 0, 0, 0.1);
			padding: 10px 16px;
			border-radius: 4px;

			a {
				font-weight: 700;
			}
		}

		> svg {
			color: #2196f3 ;
		}

		/* a {
			width: 100%;
			height: 60px;
			max-width: 200px;
			background: #4caf50;
			font-weight: 800;
			border-radius: 4px;
			transition: all 0.3s;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 16px;

			&:hover {
				background: #43a047;
			}
		} */
	}

	@media (max-width: 500px) {
		width: 100%;
	}

	@media (max-width: 900px) {
		width: 100%;
	}
`
