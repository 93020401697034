import React, { useCallback } from 'react'
import { FaPaperPlane, FaPhone, FaEnvelope } from 'react-icons/fa'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import yup from '~/services/yup'

import api from '~/services/api'

import {
    Textbox, Textarea, Button 
} from '~/components/Form'

import masks from '~/util/masks'
import authHeaders from '~/util/authHeaders'

import { MessageCenter, SMSFormActions } from './styles'

const SMSValidation = yup.object({
    number: yup.string().required('Campo obrigatório.').mobile(),
    message: yup.string().required('Campo obrigatório.')
})

const initialValues = { number: '', message: '' }

export default function ({ className, style }) {
    const sendSMS = useCallback(async (values, { resetForm }) => {
        const { message, number } = values

        await api.post('smss', [
            {
                to: number,
                text: message
            }
        ], authHeaders)

        resetForm()
        toast.success('SMS enviado com sucesso!')
    }, [])

    return (
        <MessageCenter className={`animated fadeIn ${className}`} style={style || {}}>
            <h1>Envio de mensagens SMS</h1>

            <Formik 
                initialValues={initialValues}
                validationSchema={SMSValidation}
                onSubmit={sendSMS}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Textbox 
                            label="Número" 
                            name="number" 
                            mask={masks.mobile} 
                            disabled={isSubmitting} 
                            icon={{ Icon: FaPhone, props: { size: 14 } }}
                        />

                        <Textarea 
                            label="Sua mensagem..." 
                            name="message" 
                            disabled={isSubmitting}
                            icon={{ Icon: FaEnvelope, props: { size: 14 } }}
                        />

                        <SMSFormActions>
                            <Button type="submit" disabled={isSubmitting} className="transparent">
                                <span>Enviar</span>
                                <FaPaperPlane size={14} />
                            </Button>
                        </SMSFormActions>
                    </Form>
                )}
            </Formik>

        </MessageCenter>
    )
}
