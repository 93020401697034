import React from 'react'
import { useField } from 'formik'

import { InputContainer, Switch } from './styles'

export default function ({
    label, withoutForm, id, ...props 
}) {
    let field
    let meta

    if(!id) {
        throw new Error('Switches must have the "id" attribute.')
    }

    if(!withoutForm) {
        [field, meta] = useField(props)
    }
    
    if(withoutForm) {
        return (
            <InputContainer>
                <Switch type="checkbox" id={id} {...field} {...props} />
                <label htmlFor={id}><span /></label>
                <span>{label}</span>
            </InputContainer>
        )
    }
    
    return (
        <div className="checkbox-container">
            <InputContainer>
                <Switch type="checkbox" id={id} {...field} {...props} />
                <label htmlFor={id}><span /></label>
                <span>{label}</span>
            </InputContainer>

            {meta.touched && meta.error 
                ? <span className="error">{meta.error}</span>
                : null}
        </div>
    )
}
