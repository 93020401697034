import styled from 'styled-components'

import { InputContainer } from '~/components/Form/Switch/styles'

export const Container = styled.article`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px 0;
	opacity: ${props => props.isPaid ? '0.4' : '1'};

	> ${InputContainer} {
		margin-bottom: 16px;
		padding-left: 0;
		width: 300px;
	}
`

export const PriceContainer = styled.div`
	display: grid !important;
	grid-template-columns: 1fr 4fr 3fr;
	grid-gap: 10px;
	width: 100%;
	max-width: 314px !important;
	padding: 0 0 20px;
	
	span.price {
		font-weight: 800;
		font-size: 20px;
	}

	span.parcels {
		font-weight: 600;
		font-size: 16px;
	}

	.icon {
		grid-column: 1;
		grid-row: 1 / 3;
	}
`

export const FormContainer = styled.section`
	max-width: 300px;
	width: 100%;
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;

	input {
		width: 100%;
	}

	button {
		margin: 0 !important;
		width: 100% !important;
	}
`

export const PaidLabel = styled.div.attrs({
    className: 'animated zoomIn faster'
})`
	text-align: center;
	margin-bottom: 35px;
	padding: 15px;
	background: #4caf50;
	border-radius: 10px;

	h1 {
		font-size: 18px;
		font-weight: 400;
		padding-bottom: 8px;
	}

	span {
		font-size: 14px;
		font-weight: 200;
	}
`
