import React, { useMemo } from 'react'

import scroll from '~/util/scroll'

import DashboardAdmin from '~/components/DashboardAdmin'
import DashboardUser from '~/components/DashboardUser'

export default function Dashboard({ history }) {
    scroll.toTop()

    const user = useMemo(() => JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER)), [])

    return (
        <>
            {user && (
                <>
                    {user.profile?.id === 499 && user.profile?.name === 'admin' ? (
                        <DashboardAdmin user={user} history={history} />
                    ) : (
                        <DashboardUser user={user} history={history} />
                    )}
                </>
            )}
        </>
    )
}
