import styled from 'styled-components'

export const Container = styled.article`
	min-height: 80vh;
	padding: 50px;
	display: flex;
	justify-content: center;

	section {
		background: #1D3B3B;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		border-radius: 2px;
		padding: 35px;
		width: 60%;
		height: fit-content;
		display: flex;
		flex-direction: column;

		h1 {
			font-size: 22px;
			font-weight: 400;
		}

		p {
			line-height: 30px;
			margin: 15px 0;
		}

		> div {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			form {
				width: 100%;
				max-width: 300px;
				display: flex;
				flex-direction: column;
				align-items: center;

				div {
					width: 100%;

					input {
						width: 100%;
					}
				}
			}
		}
	}

	@media (max-width: 600px) {
		padding: 20px;

		section {
			width: 100%;		
		}
	}
`
