import styled from 'styled-components'

export const Container = styled.article`
	min-height: 80vh;
	padding: 50px;
	display: flex;
	justify-content: center;

	section {
		background: #1D3B3B;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
		border-radius: 2px;
		padding: 35px;
		width: 60%;
		display: flex;
		flex-direction: column;

		h1 {
			font-size: 22px;
			font-weight: 400;
			grid-column: 1 / 3;
			grid-row: 1 / 2;
			margin-bottom: 30px;
		}

		@media (max-width: 530px) {
			display: block;
		}
	}

	@media (max-width: 900px) {
		padding: 30px;

		section {
			width: 100%;
		}
	}
`

export const AssistantInfoContainer = styled.div`
	display: flex;
	align-items: center;
	height: 44px;

	b {
		font-weight: 500;
		font-size: 16px;
		margin-left: 8px;
	}

	@media(max-width: 400px) {
		flex-direction: column;
		align-items: flex-start;

		b {
			margin-left: 0;
		}
	}
`
