import styled from 'styled-components'
import { lighten } from 'polished'
import { Carousel } from 'primereact/carousel'

export const CarouselStyled = styled(Carousel)`
	.p-carousel-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		iframe, .image-container {
			width: 94%;
			height: 295px;
		}

		.image-container {
			background-size: auto 100%;
			background-position: center;
			background-repeat: no-repeat;
		}

		> div:last-child { 
			text-align: center;
			width: 90%;

			h1 {
				font-size: ${props => props.titleStyle?.fontSize ? props.titleStyle.fontSize : 13}px;
				color: ${props => props.titleStyle?.color ? props.titleStyle.color : '#2c2c2c'};
				font-weight: ${props => props.titleStyle?.fontWeight ? props.titleStyle.fontWeight : 500};
				padding: 10px 0 8px;
				height: 50px;
			}

			h2 {
				font-size: 11px;
				color: #2c2c2c;
				height: 40px;
			}
		}

		video {
			background-color: #000;
			width: 94%;
		}
	}

	&.large-carousel {
		video {
			padding: 78px 0;
		}
	}

	&.small-carousel {
		display: none;

		h2 {
			font-size: 11px;
			color: #2c2c2c;
			font-weight: 400;
		}

		video {
			padding: 18px 0;
		}
	}

	.p-carousel-dot-icon.pi-circle-on {
		background-color: ${lighten(0.2, '#2B3E4D')} !important;
	}

	@media (max-width: 800px) {
		&.small-carousel {
			display: block;
		}

		&.large-carousel {
			display: none;
		}
	}
`
