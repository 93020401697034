import styled from 'styled-components'

export const Container = styled.article`
	main {
		padding: 35px 100px;

		section {
			display: flex;
			align-items: center;
			flex-direction: column;
			background: #1D3B3B;
			box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
			border-radius: 2px;
			padding: 35px;
			width: 100%;

			h1 {
				width: 100%;
				font-size: 22px;
				font-weight: 300;
				margin-bottom: 18px;
			}
		}

		@media(max-width: 800px) {
			padding: 30px 0;

			section {
				padding: 30px 20px !important;
			}
		}
	}
`
