import React from 'react'

import { Container } from './styles'

export default function Card({ className, children }) {
    return (
        <Container className={`card ${className}`}>
            {children}
        </Container>
    )
}
