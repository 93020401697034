import styled, { keyframes } from 'styled-components'

const scaleAnimation = keyframes`
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.04);
	}
	100% {
		transform: scale(1);
	}
`

export const Container = styled.article`
	overflow: hidden;
`

export const Section1 = styled.section`
	div {
		width: 100%;
		height: 86vh;
		display: block;
		position: relative;
		display: flex;
		justify-content: center;
		min-height: 400px;
	}

	div::after {
		content: "";
		background: url(${props => props.background});
		background-position: center;
		background-size: cover;
		animation: 8s ${scaleAnimation} linear infinite;
		opacity: 0.5;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;   
	}
`

export const SliderContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		width: 100px;
		height: 100px;
	}

	h1 {
		font-weight: 500;
		padding: 20px;
		text-align: center;
	}

	p {
		font-style: italic;
		font-size: 15px;
		text-align: center;
		max-width: 800px;
		padding: 0 20px;
		min-height: 110px
	}

	a {
		border: solid 1px rgba(255, 255, 255, 0.8);
		color: #fff;
		padding: 12px 18px;
		transition: all 0.4s;
		border-radius: 7px;
		font-weight: 600;
		text-align: center;
		width: 100%;
		max-width: 300px;
		margin: 0px 0 20px;

		&:hover {
			border: solid 1px #fff;
			background-color: #fff;
			color: #2c2c2c;
		}
	}
`

export const Section2 = styled.section`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	padding: 50px 0;

	> div:first-child {
		width: 40%;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 100%;
			max-width: 300px;
			padding: 0 30px;
		}
	}

	> div:last-child {
		width: 60%;
		padding-bottom: 20px;

		h1 {
			color: #2c2c2c;
			padding: 20px 0;
		}

		p {
			color: #2c2c2c;
			line-height: 26px;
			font-size: 16px;
			width: 80%;
		}
	}

	@media (max-width: 550px) {
		flex-direction: column;

		> div:first-child, 
		> div:last-child {
			width: 100%;
		}

		> div:first-child img {
			padding-bottom: 20px;
		}

		> div:last-child {
			padding: 0 30px;

			p {
				width: 100%;
			}
		}
	}
`

export const Section3 = styled.section`
	width: 100%;
	padding: 50px 0;
	display: flex;
	flex-direction: column;
	background: #427C56;
	
	h1 {
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 18px 0;

		@media (max-width: 851px) {
			width: 100%;
		}
	}

	> div {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0 30px;
	}
`

export const Section4 = styled.section`
	display: flex;
	background: #fff;
	flex-direction: column;
	justify-content: center;
	padding: 50px 100px;

	h1 {
		color: #2c2c2c;
		padding: 0 54px 25px;
	}

	@media (max-width: 800px) {
		padding: 50px 0;
	}
`
