import React from 'react'

import { Container } from './styles'

export default function ({
    category, style, className, onClick 
}) {
    return (
        <Container  
            onClick={onClick}
            style={style} 
            className={className} 
            image={`${process.env.REACT_APP_BACKEND_URL}/files/${category.image?.path || ''}`}
        >
            <h1>{category.name}</h1>

            <p className="reveal-content">
                {category.description}
            </p>
        </Container>
    )
}
