import React from 'react'
import { MdHome, MdHelpOutline } from 'react-icons/md'

export default [
    {
        to: '/user/chatbot',
        icon: <MdHome size={20} />,
        text: 'Início'
    },
    {
        to: '/user/chatbot/question_manager',
        icon: <MdHelpOutline size={20} />,
        text: 'Administrar perguntas'
    }
]
