import styled from 'styled-components'

export const Option = styled.div`
	padding: 10px;
	color: #2c2c2c;

	&:hover {
		background: rgba(0, 0, 0, 0.1);
		font-weight: 500;
		cursor: pointer;
	}
`
