import styled, { keyframes } from 'styled-components'

const reveal = keyframes`
	from {
		height: 300px;
	}
	to {
		height: 390px;
	}
`

const unreveal = keyframes`
	from {
		height: 390px;
	}
	to {
		height: 300px;
	}
`

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #1D3B3B;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	border-radius: 2px;
	padding: 25px 35px 130px;
	height: 300px;
	min-height: 300px;
	width: 100%;
	cursor: pointer;

	background-position: 0 100%;
	background-size: 150%;
	background-repeat: no-repeat;
	background-image: ${props => `url(${props.image})`};

	animation: ${unreveal} 0.5s ease-out;
	animation-fill-mode: forwards;

	&:hover {
		animation: ${reveal} 0.5s ease-out;
		animation-fill-mode: forwards;
		

		h1 {
			font-size: 22px;
			text-align: left;
		}

		.reveal-content {
			opacity: 1;
			transition-delay: 0.35s;
		}
	}

	h1 {
		width: 100%;
		padding: 20px 0;
		font-size: 30px;
		text-align: center;
		transition: all 0.3s;
	}

	.reveal-content {
		text-align: center;
		opacity: 0;
		transition: opacity 0.3s linear;
		transition-delay: 0s;
	}

	img {
		width: 100%;
		border-radius: 50%;
	}

	@media(max-width: 600px) {
		padding: 15px 35px 54px;
	}
`
