import styled from 'styled-components'

const sizeToResponsiveChange = 1120

export const Container = styled.article`
	display: flex;
	padding: 50px 0;
	justify-content: center;
	flex-direction: column;
	min-height: 60vh;
`

export const NavigationTip = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 16px;
	align-items: center;
	text-align: center;

	@media (min-width: 1085px) {
		display: none;
	}
`

export const SmallContainer = styled.section`
	display: none;
	width: 100%;
	flex-direction: column;
	padding: 0;

	.chatbots {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		overflow-x: auto;
		overflow-y: hidden;
		padding-bottom: 40px;

		.card {
			height: 100%;
			width: calc(${window.innerWidth - 16}px);
		}
	}

	@media (max-width: ${sizeToResponsiveChange}px) {
		display: flex;
	}
`

export const LargeContainer = styled.section`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: ${props => props.children.length > 1 ? 'space-between' : 'center'};
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	padding: 0 50px;

	@media (max-width: ${sizeToResponsiveChange}px) {
		display: none;
	}

	.card {
		width: ${props => props.children.length > 1 ? '100%' : '600px'};
	}
`
